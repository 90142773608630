<app-content-modal [buttons]="buttons" (closeModal)="close(false)">
  <h1>{{ 'EMPLOYEE_PORTAL.WE_NEED_TO_VERIFY_THAT_IT_IS_YOU' | translate }}</h1>
  <p class="description">
    {{ 'EMPLOYEE_PORTAL.SELECT_PHONE_TO_ADD_EDIT' | translate }}
  </p>
  <p class="description">
    {{ 'EMPLOYEE_PORTAL.WE_WILL_CALL_YOU_TO_VERIFY' | translate }}
  </p>
  <div class="phone-selection-list">
    <app-item
      *ngFor="let phone of availablePhones; trackBy: trackByItems"
      class="radio-input"
      [class.radio-disabled]="!phone.editable">
      <label (click)="setPhone(phone)">
        <input
          type="radio"
          class="phone-selection__radio-button"
          name="phone"
          [disabled]="!phone.editable"
          [value]="phone" />
        <ng-container *ngIf="phone.status && isPhoneStatusPending(phone); else current">
          <div class="status-container">
            <span> {{ phone.description }} {{ phone.status.value }} </span>
            <span class="status">
              {{ 'EMPLOYEE_PORTAL.PENDING' | translate }}
            </span>
          </div>
        </ng-container>
        <ng-template #current>
          <span> {{ phone.description }} {{ phone.phone }} </span>
        </ng-template>
      </label>
    </app-item>
  </div>

  <form [formGroup]="form" [class.hidden]="!selectedPhone">
    <app-input-wrapper
      [label]="'EMPLOYEE_PORTAL.NEW_PHONE'"
      [errorMsg]="'PROFILE.PERSONAL_DATA.PHONE_CONTACT.FORM_ERROR.PHONE_LENGTH'"
      [errorTranslateParam]="{ maxLengthReplace: PHONE_MAXLENGTH }"
      [hasError]="hasError"
      [showIcon]="false">
      <input class="form-input" formControlName="phone" name="phone" [maxlength]="PHONE_MAXLENGTH" />
    </app-input-wrapper>
  </form>
</app-content-modal>
