import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Address } from '../../models/personal-data/address.model';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(address: Address): string {
    if (!address) {
      return this.translate.instant('PROFILE.PERSONAL_DATA.ADDRESS.EMPTY');
    }

    const streetType = address.street_type ? address.street_type.name : '';

    let formattedAddress = `${streetType} ${address.street} ${address.number}`;

    const door = `${address.floor ? address.floor + ',' : ''} ${address.door ?? ''}`.trim();
    if (door) {
      formattedAddress += `, ${door}`;
    }

    formattedAddress += `, ${address.postal_code}`;

    const place = `${address.province.name}, ${address.city.name}`;
    formattedAddress += `\n${place}`;

    return formattedAddress;
  }
}
