import { AfterContentChecked, Component, EventEmitter, Injector, Input, Output } from '@angular/core';

import { InfoPopoverComponent } from '@app/components/info-popover/info-popover.component';
import { ITDayType } from '@app/mot/components/mot-day/mot-day.interface';
import { BaseComponentDirective } from '@app/shared/bases/base.component';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-caption-leyend',
  templateUrl: './caption-leyend.component.html',
  styleUrls: ['./caption-leyend.component.scss']
})
export class CaptionLeyendComponent extends BaseComponentDirective implements AfterContentChecked {
  @Input() caption!: ITDayType[];
  @Input() popover = true;
  @Output() showInfoPopover = new EventEmitter<Event>();

  captionContainer!: ITDayType[];
  screenWidth: number;
  // eslint-disable-next-line
  infoPopover!: HTMLIonPopoverElement;

  constructor(injector: Injector, private popoverCtrl: PopoverController) {
    super(injector);
    this.screenWidth = document.documentElement.clientWidth;
  }

  ngAfterContentChecked(): void {
    const notZoomDevice = this.screenWidth > 374;
    if (!this.captionContainer || notZoomDevice) {
      return;
    }
  }

  getCaptionStyle(caption: ITDayType): object {
    let style = {};

    if (caption.onlyBorder) {
      style = { border: `2px solid ${caption.colour}` };
    } else {
      style = { 'background-color': caption.colour };
    }

    return style;
  }

  async showPopover(description: string, event?: Event) {
    if (!this.popover) {
      return;
    }

    this.infoPopover = await this.popoverCtrl.create({
      component: InfoPopoverComponent,
      componentProps: { event, message: description },
      event,
      backdropDismiss: true,
      showBackdrop: false,
      cssClass: 'info-popover'
    });

    this.infoPopover.present();
  }

  trackByItems(item: ITDayType) {
    return item;
  }
}
