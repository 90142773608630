import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PhotoViewerComponent } from '@app/components/modals/photo-viewer/photo-viewer.component';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { ModalManager } from '../modal-manager/modal-manager';

@Injectable({ providedIn: 'root' })
export class PhotoViewerService {
  private isOpen = false;

  constructor(private modalManager: ModalManager, private router: Router) {
    this.router.events.subscribe(() => {
      if (this.isOpen) {
        this.modalManager.dismissModal(true);
        this.isOpen = false;
      }
    });
  }

  async create(opts: MSafeAny) {
    const photoViewer = await this.modalManager.createModal({
      component: PhotoViewerComponent,
      componentProps: {
        images: opts.images,
        initialSlideIndex: opts.initialSlideIndex,
        isWebview: opts.isWebview,
        multimediaCarousel: opts?.multimediaCarousel,
        auxUpdateCarrousel: opts?.auxUpdateCarrousel
      },
      backdropDismiss: false,
      cssClass: 'fullscreen'
    });
    await photoViewer?.present();
    this.isOpen = true;
    return photoViewer;
  }
}
