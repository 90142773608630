import { Component, Input } from '@angular/core';

import { StorageService, STORAGE_CONSTANTS } from '@app/services/storage';
import { Buttons } from '@app/shared/models/buttons/buttons';

@Component({
  selector: 'app-cookies-modal',
  templateUrl: './cookies-modal-comment.component.html',
  styleUrls: ['./cookies-modal-comment.component.scss']
})
export class CookiesModalCommentComponent {
  @Input() title!: string;
  @Input() example!: string;
  @Input() advise!: string;
  @Input() checkboxLabel!: string;
  @Input() buttons!: Buttons[];
  @Input() cookieReference = STORAGE_CONSTANTS.RESPONSE_AS_OTHER_USER;

  selected = false;

  constructor(private storageService: StorageService) {}

  async onChange() {
    const selected = !this.selected;
    await this.storageService.set(this.cookieReference, selected);
    this.selected = selected;
  }
}
