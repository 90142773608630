import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ToastService } from '@app/services';
import { ContentType } from '@app/shared/enums/multimedia/content-status-types.enum';
import { ExtensionsManager } from '@app/shared/extensions/extensions-manager';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';
import { DragulaService } from 'ng2-dragula';

import { MultimediaService } from '../../services/multimedia/multimedia.service';
import { ImageItem, MultimediaItem } from '../example-form/example-form.component';

/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-example-form-images',
  templateUrl: './example-form-images.component.html',
  styleUrls: ['./example-form-images.component.scss'],
  providers: [MultimediaService]
})
export class ExampleFormImagesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Input() maxFiles!: number;
  @Input() images: ImageItem[] = [];

  @Output() imagesChange = new EventEmitter<ImageItem[]>();

  selectedImage!: ImageItem;
  extensions!: string;

  readonly BAG_NAME = 'example-form-images__bag';

  private scrollable = true;
  private subscriptions = new Subscription();
  private extensionsManager = new ExtensionsManager<ContentType>();

  constructor(
    private dragulaService: DragulaService,
    private translate: TranslateService,
    private toastCtrl: ToastService,
    private multimediaService: MultimediaService
  ) {}

  ngOnInit() {
    this.extensionsManager.setExtensions(ContentType.IMAGE);
    this.extensions = this.extensionsManager.getParsedExtensions();
    this.selectedImage = this.images.find((img) => img.isThumbnail) as ImageItem;
  }

  ngAfterViewInit() {
    this.stopScrollOnDrag();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onRemove(image: ImageItem) {
    this.images = this.images.filter((img) => img !== image);
    if (this.images.length && image.isThumbnail) {
      this.images[0].isThumbnail = true;
      this.selectedImage = this.images[0];
    }
    this.imagesChange.emit(this.images);
  }

  addImages() {
    this.fileInput.nativeElement.click();
  }

  onChangeInputFile(selectedFiles: FileList) {
    const exceededFileNumber = this.images.length + selectedFiles.length > this.maxFiles;
    if (exceededFileNumber) {
      this.toastCtrl.showWarning(
        this.getMessage('CUSTOM_FILE_CHOOSER.IMAGES_LIMIT_TITLE'),
        this.getMessage('CUSTOM_FILE_CHOOSER.IMAGES_LIMIT_BODY')
      );
      return;
    }

    const multimedia = this.multimediaService.selectDesktopFiles(selectedFiles);
    this.fileInput.nativeElement.value = '';
    this.emitImages(multimedia as MultimediaItem[]);
  }

  setOrder(images: ImageItem[]) {
    this.images = images.map((img) => ({ ...img }));
    this.selectedImage = this.images.find((img) => img.isThumbnail) as ImageItem;
    this.imagesChange.emit(this.images);
  }

  setSelected(selected: ImageItem) {
    this.images = this.images.map((img) => ({
      ...img,
      isThumbnail: img === selected
    }));
    this.selectedImage = this.images.find((img) => img.isThumbnail) as ImageItem;
    this.imagesChange.emit(this.images);
  }

  trackByItems(item: MSafeAny) {
    return item;
  }

  private setImages(multimedia: MultimediaItem[]): ImageItem[] {
    return multimedia.map(({ file, preview }: MultimediaItem) => ({
      file,
      preview,
      isThumbnail: false
    }));
  }

  private emitImages(multimedia: MultimediaItem[]) {
    const someHaveInvalidFormat = multimedia.some(
      (item: MultimediaItem) => !this.extensionsManager.isValidExtension((item.file as File).name)
    );
    if (someHaveInvalidFormat) {
      this.toastCtrl.showWarning(
        this.getMessage('CUSTOM_FILE_CHOOSER.IMAGES_FORMAT_TITLE'),
        this.getMessage('CUSTOM_FILE_CHOOSER.IMAGES_FORMAT_BODY')
      );
      return;
    }

    const images = this.setImages(multimedia);
    this.images = [...this.images, ...images];
    this.imagesChange.emit(this.images);
  }

  private stopScrollOnDrag() {
    document.addEventListener(
      'touchmove',
      (e: TouchEvent) => {
        if (!this.scrollable) {
          e.preventDefault();
        }
      },
      { passive: false }
    );

    this.subscriptions.add(
      this.dragulaService.drag(this.BAG_NAME).subscribe(() => {
        this.scrollable = false;
        setTimeout(() => (this.scrollable = true), 3000);
      })
    );

    this.subscriptions.add(this.dragulaService.drop(this.BAG_NAME).subscribe(() => (this.scrollable = true)));
  }

  private getMessage(key: string) {
    return this.translate.instant(key);
  }
}
