/* eslint-disable no-undef */
import { Injectable, Injector, Renderer2, RendererFactory2 } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { ModalOptions } from '@ionic/core/dist/types/components/modal/modal-interface';

import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ModalManager {
  private modals: HTMLIonModalElement[] = [];
  private currentModal!: HTMLIonModalElement | null;
  private authService: AuthService;
  private renderer: Renderer2;
  private readonly cssClass = 'hidden';

  constructor(
    private modalCtrl: ModalController,
    private injector: Injector,
    private rendererFactory: RendererFactory2
  ) {
    this.authService = this.injector.get(AuthService);
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  dismissModal(success: MSafeAny = false) {
    if (this.currentModal) {
      this.modals.pop();
      this.currentModal.dismiss(success);
      this.setCurrentModal();
    }
  }

  async dismissAllModalIfNoSession() {
    const hasSession = await this.authService.hasActivo2Session();
    if (!hasSession) {
      this.dismissAllModal();
    }
  }

  dismissAllModal() {
    if (this.modals.length) {
      this.modals.forEach((m) => m.dismiss(false));
      this.modals = [];
    }
  }

  async createModal(modalOpts: ModalOptions): Promise<HTMLIonModalElement | null> {
    const modal = await this.addModal(modalOpts);
    return this.onDidDismissCurrentModal(modal);
  }

  async openModal(modalOpts: ModalOptions): Promise<MSafeAny> {
    const modal = await this.addModal(modalOpts);
    return this.dismissCurrentModal(modal);
  }

  updateCurrentModalView(hidden = false) {
    if (this.currentModal) {
      if (hidden) {
        this.renderer.addClass(this.currentModal, this.cssClass);
      } else {
        this.renderer.removeClass(this.currentModal, this.cssClass);
      }
    }
  }

  private setCurrentModal() {
    if (this.modals.length) {
      this.currentModal = this.modals[this.modals.length - 1];
      return;
    }

    this.currentModal = null;
  }

  private async addModal(modalOpts: ModalOptions): Promise<HTMLIonModalElement> {
    const modal = await this.modalCtrl.create(modalOpts);
    this.currentModal = modal;
    this.modals.push(this.currentModal);
    return modal;
  }

  // In case modal dismiss by clicking android back button or modal backdrop
  private removeCurrentModal(modal: HTMLIonModalElement) {
    if (this.currentModal === modal) {
      this.modals.pop();
      this.setCurrentModal();
    }
  }

  private async dismissCurrentModal(modal: HTMLIonModalElement): Promise<MSafeAny> {
    return new Promise((resolve) => {
      this.currentModal?.onDidDismiss().then((value: OverlayEventDetail<MSafeAny>) => {
        resolve(value.data);
        this.removeCurrentModal(modal);
      });

      this.currentModal?.present();
    });
  }

  private async onDidDismissCurrentModal(modal: HTMLIonModalElement) {
    this.currentModal?.onDidDismiss().then(() => this.removeCurrentModal(modal));
    return this.currentModal;
  }
}
