<app-input-wrapper [label]="title">
  <div class="radio-list">
    <app-item
      *ngFor="let option of options; trackBy: trackByItems"
      class="radio-input item-md"
      [class.radio-disabled]="option.disabled">
      <label (click)="handleModelChange(option.value)">
        <input type="radio" name="selector" [value]="option.value" [disabled]="option.disabled" />
        {{ option.label | translate }}
        <img
          *ngIf="option.extraInfo"
          src="assets/imgs/profile/information-circle-filled.svg"
          class="tooltip"
          (click)="showPopover($event)" />
      </label>
    </app-item>
  </div>
</app-input-wrapper>
