import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification-advice',
  templateUrl: './notification-advice.component.html',
  styleUrls: ['./notification-advice.component.scss']
})
export class NotificationAdviceComponent {
  @Input() unreadNotificationsNumber!: number;
}
