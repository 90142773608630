export enum ENVIRONMENT {
  DEV = 'dev',
  DEV_CNA = 'dev_cna',
  ITG = 'itg',
  ITG_CNA = 'itg_cna',
  LAB = 'lab',
  QA = 'qa',
  QA_CNA = 'qa_cna',
  LOCAL = 'local',
  LOCAL_BACK = 'local.back',
  LOCAL_MOCKS = 'local.mocks',
  LOCAL_ITG = 'local.itg',
  LOCAL_ITG_CNA = 'local.itg_cna',
  LOCAL_DEV = 'local.dev',
  LOCAL_DEV_CNA = 'local.dev_cna',
  LOCAL_PREPRODUCTION = 'local.preproduction',
  LOCAL_PREPRODUCTION_CNA = 'local.preproduction_cna',
  LOCAL_QA = 'local.qa',
  PREPRODUCTION = 'preproduction',
  PREPRODUCTION_CNA = 'preproduction_cna',
  PRODUCTION = 'production',
  PRODUCTION_CNA = 'production_cna'
}
