import { Component } from '@angular/core';

import { ModalManager } from '@app/services';

@Component({
  selector: 'app-ptrab-second-fa-contact',
  templateUrl: './second-fa-contact.component.html',
  styleUrls: ['./second-fa-contact.component.scss']
})
export class SecondFaContactComponent {
  buttons = [
    {
      text: 'EMPLOYEE_PORTAL.CONTACT_COORDINATOR_BUTTON',
      type: 'primary',
      enabled: true,
      onClick: () => this.modalManager.dismissModal()
    }
  ];

  constructor(private modalManager: ModalManager) {}
}
