<main class="content-container" [class.darkmode]="darkMode">
  <div #contentWrapper class="content-wrapper" [class.has-buttons]="buttons?.length">
    <i *ngIf="!hideClose" class="app-icon-close close-modal-icon" (click)="onCloseIconClick()"></i>
    <ng-content></ng-content>
  </div>
</main>

<footer *ngIf="buttons?.length">
  <div
    class="modal__buttons"
    [class.with-one-button]="buttons?.length === 1"
    [class.with-two-buttons]="buttons?.length > 1">
    <button
      *ngFor="let button of buttons; trackBy: trackByItems"
      class="c-button"
      [ngClass]="button?.type"
      [disabled]="!button?.enabled"
      (click)="button?.onClick()">
      {{ button?.text | translate }}
    </button>
  </div>
</footer>
