import { Component, Injector, Input, OnInit } from '@angular/core';

import { ModalManager, NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-ptrab-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.shared.scss']
})
export class SuccessModalComponent implements OnInit {
  @Input() titleKey!: string;
  @Input() infoMsgKey!: string;
  @Input() requestPhone!: boolean;
  @Input() descriptionKey!: string;
  @Input() emailQuestion!: string;
  @Input() personalDataLink!: string;
  @Input() imageUrl = 'assets/imgs/employee-portal/ok-paysheet.svg';
  @Input() ipadImageUrl = 'assets/imgs/employee-portal/ok-paysheet-reduced.svg';
  @Input() hasAction = true;

  imageSuccess!: string;
  readonly buttons: Buttons[] = [
    {
      text: 'EMPLOYEE_PORTAL.BACK',
      type: 'primary',
      enabled: true,
      onClick: () => this.modalManager.dismissModal()
    }
  ];

  private platform: Platform;
  modalManager: ModalManager;
  private navigationService: NavigationService;

  constructor(injector: Injector) {
    this.platform = injector.get(Platform);
    this.modalManager = injector.get(ModalManager);
    this.navigationService = injector.get(NavigationService);
  }

  ngOnInit() {
    if (this.requestPhone) {
      this.imageUrl = 'assets/imgs/employee-portal/group.svg';
      this.ipadImageUrl = 'assets/imgs/employee-portal/group.svg';
    }
    this.imageSuccess = this.platform.is('ipad') && this.ipadImageUrl ? this.ipadImageUrl : this.imageUrl;
  }

  openPage() {
    this.modalManager.dismissModal(true);

    const navEvent = new NavigationEvent(NavigationEvents.Push, {
      path: PAGES.PERSONAL_DATA
    });

    this.navigationService.navigate(navEvent);
  }
}
