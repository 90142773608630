import { Address, PortugueseAddress } from './address.model';
/* eslint-disable @typescript-eslint/naming-convention */
export interface ResultDescription {
  code: string;
  description?: string;
}
// TODO: david.peris -> Revise models with Back and remove useless
export interface PersonalInfo {
  personal_info: IPersonalData;
  info_messages: ResultDescription[];
}

export interface PortuguesePersonalInfo {
  personal_info: IPortuguesePersonalData;
  info_messages: ResultDescription[];
}

export interface IPersonalData {
  full_name: string;
  nif: string;
  address: Address;
  contact_options: ContactOptions;
  sustainability: Sustainability;
  phone_status: PhoneStatus;
}

export interface IPortuguesePersonalData {
  full_name: string;
  nif: string;
  address: PortugueseAddress;
  contact_options: PortugueseContactOptions;
}

export type PersonalDataRequest = Partial<IPersonalData>;
export type PortuguesePersonalDataRequest = Partial<IPortuguesePersonalData>;

export class PersonalData implements IPersonalData {
  full_name: string;
  nif: string;
  address: Address;
  contact_options: ContactOptions;
  sustainability: Sustainability;
  phone_status: PhoneStatus;

  constructor(data: IPersonalData) {
    this.full_name = data.full_name;
    this.nif = data.nif;
    this.address = data.address && new Address(data.address);
    this.contact_options = data.contact_options;
    this.sustainability = data.sustainability;
    this.phone_status = data.phone_status;
  }

  isSustainable(): boolean {
    return !this.sustainability.paper_reception;
  }
}

export class PortuguesePersonalData implements IPortuguesePersonalData {
  full_name: string;
  nif: string;
  address: PortugueseAddress;
  contact_options: PortugueseContactOptions;

  constructor(data: IPortuguesePersonalData) {
    this.full_name = data.full_name;
    this.nif = data.nif;
    this.address = data.address && new PortugueseAddress(data.address);
    this.contact_options = data.contact_options;
  }
}

export interface Sustainability {
  paper_reception: boolean;
  show_warning?: boolean;
  show_reminder?: boolean;
}

export interface ContactOptions {
  phone1?: string;
  phone2?: string;
  mobile?: string;
  email?: string;
}

export interface PortugueseContactOptions {
  phones?: {
    phone1?: IPhone;
    phone2?: IPhone;
    mobile?: IPhone;
  };
  email_info?: IEmail;
}

export interface IPhone {
  id: string;
  phone: string;
}

export interface IEmail {
  id?: string;
  email?: string;
}

export enum PersonalDataSections {
  PROFILE = 'PROFILE',
  PHONE_CONTACT = 'PHONE_CONTACT',
  EMAIL_EDITION = 'EMAIL_EDITION',
  ADDRESS_EDITION = 'ADDRESS_EDITION'
}

export interface PersonalDataItem {
  label?: string;
  value: string;
  isEditable?: boolean;
  cssClass?: string;
}

export interface PersonalDataSection {
  title: string;
  isEditable: boolean;
  data: PersonalDataItem[];
}

export interface DeletePhone {
  phone_type: string;
}

export interface PhoneStatus {
  phone1: PhoneValidationData;
  phone2: PhoneValidationData;
  mobile: PhoneValidationData;
}

export interface PhoneValidationData {
  status_code: string;
  status_name: string;
  status_date: string;
  request_date: string;
  value: string;
}
