<article class="menu is-desktop">
  <div class="menu-content">
    <a
      *ngFor="let segment of segments; trackBy: trackByItems"
      (click)="openPage(segment, $event)"
      class="segment u-lmenu"
      [class.scrapper-more-tag]="segment.icon === 'app-icon-more'"
      [class.is-active]="segment.isActive"
    >
      <i role="img" [ngClass]="segment.icon" class="segment-icon"></i>
      {{ segment.text | translate }}
    </a>
  </div>
</article>
