import { Injectable } from '@angular/core';

import * as Bowser from 'bowser';

export const SATISFIES = {
  'Microsoft Edge': '>15.15063',
  'internet explorer': '>11',
  chrome: '>63.0.3239.84',
  firefox: '>60',
  safari: '>10.1.2'
};

export const BROWSERS = [
  {
    name: 'Microsoft Edge',
    min_version: '16.16',
    short_name: 'edge'
  },
  {
    name: 'internet explorer',
    min_version: '11',
    short_name: 'edge'
  },
  {
    name: 'chrome',
    min_version: '64',
    short_name: 'chrome'
  },
  {
    name: 'firefox',
    min_version: '61',
    short_name: 'firefox'
  },
  {
    name: 'safari',
    min_version: '11',
    short_name: 'safari'
  }
];

const BROWSER_DEFAULT = {
  name: undefined,
  min_version: undefined,
  short_name: 'chrome'
};

@Injectable({ providedIn: 'root' })
export class OutdatedBrowserService {
  private browser: Bowser.Parser.Parser;

  browserName!: string;
  browserShortName!: string;
  browserVersion!: string;
  minVersion!: string;
  invalidBrowser = false;
  alertConfirmed = false;

  constructor() {
    this.browser = Bowser.getParser(window.navigator.userAgent);

    if (this.browser) {
      this.setBrowserInfo();
    }

    this.invalidBrowser = !this.browser || !this.browser.satisfies(SATISFIES);
  }

  shouldShowAlert() {
    return this.invalidBrowser && !this.alertConfirmed;
  }

  confirmBrowser() {
    this.alertConfirmed = true;
  }

  private setBrowserInfo() {
    this.browserName = this.browser.getBrowser().name as string;
    this.browserVersion = this.browser.getBrowser().version as string;
    const browserProps =
      BROWSERS.find((browser) => browser.name.toUpperCase() === this.browserName.toUpperCase()) || BROWSER_DEFAULT;
    this.minVersion = browserProps.min_version as string;
    this.browserShortName = browserProps.short_name;
  }
}
