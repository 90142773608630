import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentComponent } from '@app/components/content/content.component';

import { AVATAR_STYLE } from '@app/shared/enums/comment/avatar-style.enum';
import { UserCommentPermission } from '@app/shared/enums/comment/comment.enum';
import { Comment } from '@app/shared/models/comment/comment';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { RepresentedUser } from '@app/shared/models/user/represented-user.model';
import { User } from '@app/shared/models/user/user.model';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss']
})
export class CommentsListComponent {
  @Input() comments!: Comment[];
  @Input() parentItemId!: number;
  @Input() user!: User;
  @Input() userPermission!: UserCommentPermission;
  @Input() isLoading!: boolean;
  @Input() userIsOwner!: boolean;
  @Input() userIsPresident!: boolean;
  @Input() asUsers!: RepresentedUser[];
  @Input() showLoadingOnComment: MSafeAny;
  @Input() highlightedCommentId!: number;
  @Input() mainContent!: ContentComponent;
  @Input() doScrollToComment!: boolean;

  @Output() action = new EventEmitter();

  commentInfo!: Comment | null;

  // TODO: Remove when the actual photo comes.
  getDefaultAvatarStyle(i: number) {
    return i % 2 ? AVATAR_STYLE.EMPTY_AVATAR : AVATAR_STYLE.FILLED_AVATAR;
  }

  getAvatarPhoto(comment, index) {
    return comment.photo && comment.photo !== '' ? comment.photo : this.getDefaultAvatarStyle(index);
  }

  onSendAction($event) {
    this.closeUserInfo();
    this.action.emit($event);
  }

  openUserInfoOnEnterAvatar(event: MSafeAny, comment: Comment) {
    let commentInfo = comment;
    const isCommentReply = Object.prototype.hasOwnProperty.call(event, 'reply');

    if (isCommentReply) {
      commentInfo = event.reply;
    }

    this.openUserInfo(commentInfo);
  }

  closeUserInfo() {
    if (this.commentInfo) {
      this.commentInfo = null;
    }
  }

  isCurrentCommentInfo(comment: Comment): boolean {
    return Boolean(this.commentInfo && this.commentInfo.id === comment.id);
  }

  openUserInfo(comment: Comment) {
    this.commentInfo = { ...comment };
  }

  trackComment(_: number, comment: Comment) {
    return comment.id;
  }
}
