import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { User } from '@app/shared/models/user/user.model';
import { WebViewService } from '@app/shared/services/webview.service';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '@services/logger/logger.service';
import { DEFAULT_LANGUAGE, LANGUAGES } from '@shared/constants/language/language.const';
import { isNullOrUndefined } from '@shared/utils/utils';

import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private logger: Logger = new Logger('Language config');
  private isInitialized = false;
  private langInitSubject = new Subject<string>();

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private webviewService: WebViewService
  ) {
    this.userService.userInfoChanges$.subscribe((userInfo: User) => {
      if (userInfo) {
        this.useLanguage(userInfo.language_code);
      }
    });
  }

  init() {
    this.translateService.use(DEFAULT_LANGUAGE);
    this.setLanguage();
  }

  setLanguage() {
    this.userService
      .getStoredUser()
      .then((userInfo: User) => {
        if (userInfo) {
          return this.setLanguageFromProfile(userInfo);
        }
        return this.setLanguageFromBrowser();
      })
      .catch((error) => {
        this.logger.error(`Can't get user info to set language: ${error}`);
        return this.useLanguage(DEFAULT_LANGUAGE);
      });
  }

  getCurrentLanguage(): Observable<string> {
    if (this.isInitialized) {
      return of(this.translateService.currentLang);
    }

    return this.langInitSubject.pipe(map(() => this.translateService.currentLang));
  }

  setLanguageFromBrowser() {
    const browserLanguage = navigator.language.substring(0, 2) === 'pt' ? 'pt' : 'es';

    this.logger.info('set language from navigator:', browserLanguage);
    this.useLanguage(browserLanguage);
  }

  getFullLanguageCode(languageCode: string): string {
    const lang = Object.keys(LANGUAGES)
      .map((k) => LANGUAGES[k])
      .find((o) => o.code === languageCode);

    return `${lang.code}_${lang.country}`;
  }

  private useLanguage(language: string) {
    let newLanguage = DEFAULT_LANGUAGE;

    if (this.isValidLanguage(language)) {
      newLanguage = language;
    }

    if (this.webviewService.getLanguageCode()) {
      newLanguage = this.webviewService.getLanguageCode();
    }

    this.translateService.use(newLanguage).subscribe(() => {
      this.logger.info('language changed to:', newLanguage);
      // TODO: ruben.rica -> This method no longer exists
      // this.platform.setLang(newLanguage, true);
      this.isInitialized = true;
      this.langInitSubject.next('');
    });
  }

  private setLanguageFromProfile(userInfo: User) {
    const profileLanguage = userInfo.language_code === 'pt' ? 'pt' : 'es';

    this.logger.info('set language from profile:', profileLanguage);
    this.useLanguage(profileLanguage);
  }

  private isValidLanguage(language: MSafeAny) {
    return !(
      isNullOrUndefined(language) ||
      language.length < 1 ||
      Object.keys(LANGUAGES).indexOf(language.toUpperCase()) === -1
    );
  }
}
