import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { PersonalDataCvSections } from '@app/activo2/personal-data/personal-data.constants';
import { FeedbackTypes } from '@app/ptrab/shared/enums/feedback.enum';
import { TwoFactorAuthorization } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { IDataResponse } from '@app/shared/interfaces/data/data.interface';
import {
  AvailableLanguages,
  AvailableLanguagesModel,
  CreateCertificateEmployee,
  EmployeeLanguages,
  Knowledge,
  TestLevel
} from '@app/shared/models/personal-data/employee-languages.model';
import {
  PersonalInfo,
  PersonalData,
  PersonalDataRequest,
  ResultDescription,
  ContactOptions,
  PortuguesePersonalData,
  PortuguesePersonalInfo,
  PortuguesePersonalDataRequest
} from '@app/shared/models/personal-data/personal-data.model';
import {
  CreateQualification,
  Qualification,
  QualificationMaster,
  Studies,
  Study
} from '@app/shared/models/personal-data/studies.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { Logger } from '..';
import { ApiUrls } from '../api/api.urls.service';
import { ErrorCodes } from '../error/error.model';

/* eslint-disable @typescript-eslint/naming-convention */

interface UpdatePersonalPhoneResponse {
  request_phone: object;
}

@Injectable({ providedIn: 'root' })
export class PersonalDataService {
  messages!: ResultDescription[] | null;
  maintenanceSections: string[] = [];
  errorSections: string[] = [];
  private employeeLanguages!: EmployeeLanguages;
  private studies!: Studies;
  private onError = new Subject<FeedbackTypes>();
  onError$ = this.onError.asObservable();

  private logger: Logger = new Logger('PersonalDataService');
  languageChanges: EventEmitter<string> = new EventEmitter();

  constructor(private http: HttpClient, private urls: ApiUrls) {}

  getPersonalData(): Observable<PersonalData> {
    return this.http
      .get<PersonalInfo>(this.urls.user.personalInfo)
      .pipe(map((data) => new PersonalData(data.personal_info)));
  }

  getPortuguesePersonalData(): Observable<PortuguesePersonalData> {
    return this.http
      .get<PortuguesePersonalInfo>(this.urls.user.personalInfo)
      .pipe(map((data) => new PortuguesePersonalData(data.personal_info)));
  }

  updatePersonalData(
    dataRequest: PersonalDataRequest,
    authorization?: TwoFactorAuthorization
  ): Observable<[PersonalData, ResultDescription[]]> {
    return this.http
      .patch<[PersonalData, ResultDescription[]]>(this.urls.user.personalInfo, {
        personal_info: dataRequest,
        authorization
      })
      .pipe(map((data: MSafeAny) => [new PersonalData(data.personal_info), data.info_messages as ResultDescription[]]));
  }

  updatePortuguesePersonalData(
    dataRequest: PortuguesePersonalDataRequest,
    authorization?: TwoFactorAuthorization
  ): Observable<[PortuguesePersonalData, ResultDescription[]]> {
    return this.http
      .patch<[PortuguesePersonalData, ResultDescription[]]>(this.urls.user.personalInfo, {
        personal_info: dataRequest,
        authorization
      })
      .pipe(
        map((data: MSafeAny) => [
          new PortuguesePersonalData(data.personal_info),
          data.info_messages as ResultDescription[]
        ])
      );
  }

  deleteEmail(authorization?: TwoFactorAuthorization): Observable<object> {
    const request = {
      body: {
        authorization
      }
    };
    return this.http.delete(this.urls.user.deleteEmail, request);
  }

  disablePaperReception(paper_reception: boolean = false): Observable<MSafeAny> {
    return this.http.post(this.urls.sustainability.paper_reception, { paper_reception });
  }

  updatePersonalPhone(dataRequest: ContactOptions): Observable<object> {
    return this.http
      .post<UpdatePersonalPhoneResponse>(this.urls.user.requestPhone, dataRequest)
      .pipe(map((r) => r.request_phone));
  }

  deletePhone(phoneSectionId: string): Observable<object> {
    return this.http.delete(this.urls.user.deletePhone(phoneSectionId));
  }

  getEmployeeLanguages(): Observable<MSafeAny> {
    return this.http.get<EmployeeLanguages>(this.urls.language.get).pipe(
      tap((employeeLanguages: EmployeeLanguages) => (this.employeeLanguages = employeeLanguages)),
      catchError((error) => this.handleError(error, PersonalDataCvSections.LANGUAGES))
    );
  }

  getTestLevels(): Observable<TestLevel[]> {
    return this.http.get<TestLevel[]>(this.urls.language.test_level);
  }

  getAllLanguages(): Observable<AvailableLanguagesModel> {
    return this.http
      .get<AvailableLanguages>(this.urls.language.languages)
      .pipe(map((data) => new AvailableLanguagesModel(data)));
  }

  getLanguagesData(): Knowledge[] | undefined {
    return this.employeeLanguages?.knowledge;
  }

  getStudiesData(): Study[] | undefined {
    return this.studies?.qualifications;
  }

  getEmployeeStudies(): Observable<MSafeAny> {
    return this.http.get<Studies>(this.urls.qualification.base).pipe(
      tap((studies: Studies) => (this.studies = studies)),
      catchError((error) => this.handleError(error, PersonalDataCvSections.STUDIES))
    );
  }

  getQualificationMaster(): Observable<QualificationMaster> {
    return this.http.get<IDataResponse<QualificationMaster>>(this.urls.qualification.master).pipe(map((d) => d.data));
  }

  getQualifications(
    qualificationZoneId: string,
    educationalLevelId: string,
    name?: string,
    firstPage?: number,
    size?: number
  ): Observable<Qualification> {
    let params = new HttpParams();
    params = params.set('qualification_zone_id', qualificationZoneId);
    params = params.set('educational_level_id', educationalLevelId);
    if (name) params = params.set('qualification_name', name);
    if (firstPage) params = params.set('first_page', firstPage.toString());
    if (size) params = params.set('size_page', size.toString());
    return this.http.get<Qualification>(this.urls.qualification.qualifications, { params });
  }

  updateEmployeeCertificate(certificate: CreateCertificateEmployee): Observable<EmployeeLanguages> {
    return this.http.post<EmployeeLanguages>(this.urls.language.employee_certificates, certificate).pipe(
      catchError(
        // eslint-disable-next-line
        (error) =>
          // eslint-disable-next-line
          throwError(error)
      )
    );
  }

  addStudyToEmployee(qualification: CreateQualification): Observable<Study> {
    return this.http.post<Study>(this.urls.qualification.base, qualification).pipe(
      catchError(
        // eslint-disable-next-line
        (error) =>
          // eslint-disable-next-line
          throwError(error)
      )
    );
  }

  updateEmployeeStudy(qualification: CreateQualification): Observable<Study> {
    return this.http.put<Study>(this.urls.qualification.base, qualification).pipe(
      catchError(
        // eslint-disable-next-line
        (error) =>
          // eslint-disable-next-line
          throwError(error)
      )
    );
  }

  deleteEmployeeCertificate(languageId: string): Observable<object> {
    const params = new HttpParams().set('language', languageId);
    return this.http.delete(this.urls.language.employee_certificates, { params });
  }

  deleteStudy(studyId: string, studyZoneId): Observable<object> {
    const request = {
      body: {
        qualification_id: studyId,
        qualification_zone_id: studyZoneId
      }
    };

    return this.http.delete(this.urls.qualification.base, request);
  }

  broadCastLanguageSystemChange(language?: string) {
    this.languageChanges.emit(language);
  }

  private handleError = (error: HttpErrorResponse, section?: string): Observable<MSafeAny[]> => {
    let errorType;

    this.logger.error(error);
    if (section) {
      switch (error.status) {
        case ErrorCodes.LOCKED:
          errorType = FeedbackTypes.SERVICE_LOCKED;
          this.errorSections.push(section);
          break;
        case ErrorCodes.SECTION_MAINTENANCE:
          errorType = FeedbackTypes.SECTION_MAINTENANCE;
          this.maintenanceSections.push(section);
          break;
        default:
          errorType = FeedbackTypes.SERVER_ERROR;
          this.errorSections.push(section);
          break;
      }
    }

    this.onError.next(errorType);

    return of([]);
  };
}
