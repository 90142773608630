import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Logger } from '@app/services';
import { IProfileAvatarEvent, ProfileAvatarService } from '@app/services/profile/profile.avatar.service';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { User } from '@app/shared/models/user/user.model';

@Component({
  selector: 'app-avatar-manager',
  templateUrl: './avatar-manager.component.html',
  styleUrls: ['./avatar-manager.component.scss']
})
export class AvatarManagerComponent implements OnInit {
  @ViewChild('fileInput') avatarInput!: ElementRef;

  @Input() user!: User;
  @Output() changeAvatar = new EventEmitter<IProfileAvatarEvent>();

  private readonly logger: Logger = new Logger('Avatar Manager');

  acceptedFileExtensions!: string;

  constructor(private avatarService: ProfileAvatarService) {}

  ngOnInit() {
    this.acceptedFileExtensions = this.avatarService.getAcceptedFileExtensions();
  }

  async onChangeAvatar(event: MSafeAny) {
    try {
      const profileAvatar = await this.avatarService.triggerDesktopFlow(event);
      this.changeAvatar.emit(profileAvatar);
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.clearFileInput();
    }
  }

  private clearFileInput() {
    if (this.avatarInput) {
      this.avatarInput.nativeElement.value = '';
    }
  }
}
