import { Component, Injector, Input } from '@angular/core';

import { SuccessModalComponent } from '@app/ptrab/components/modals/success-modal/success-modal.component';
import { DIRECT_DEBIT_CERTIFICATES } from '@app/ptrab/payslips/components/direct-debit/direct-debit.const';
import { PdfService } from '@app/ptrab/services';
import { PtrabSessionManager } from '@app/ptrab/services/session/ptrab-session.manager.service';
import { TwoFactorController } from '@app/ptrab/services/two-factor/two-factor.controller';
import { Certificate } from '@app/ptrab/shared/interfaces/certificate.interface';
import { TwoFactorAuthorization } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { Buttons } from '@app/shared/models/buttons/buttons';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-ptrab-direct-debit-success',
  templateUrl: './direct-debit-success.component.html',
  styleUrls: ['../success-modal.shared.scss']
})
export class DirectDebitSuccessComponent extends SuccessModalComponent {
  @Input() portugueseUser!: boolean;
  @Input() email!: string;
  // eslint-disable-next-line
  readonly buttons: Buttons[] = [
    {
      text: 'EMPLOYEE_PORTAL.BACK',
      type: 'primary',
      enabled: true,
      onClick: () => this.goBack()
    }
  ];

  private validation_hash!: string | undefined;
  private readonly certificate: Certificate = DIRECT_DEBIT_CERTIFICATES.CHANGE_DIRECT_DEBIT;

  constructor(
    injector: Injector,
    private ptrabSessionManager: PtrabSessionManager,
    private twoFactorController: TwoFactorController,
    private pdfService: PdfService,
    private analyticsService: AnalyticsService
  ) {
    super(injector);
  }

  async requestCertificate() {
    this.goBack();
    if (!this.validation_hash) {
      try {
        this.validation_hash = await this.twoFactorController.validateOperation(
          this.certificate.operationCode,
          this.portugueseUser
        );
      } catch {
        return;
      }
    }

    this.downloadCertificate(this.validation_hash);
  }

  downloadCertificate(validation_hash: string) {
    const twoFactorAuthorization: TwoFactorAuthorization = {
      validation_hash
    };
    const { url, onErrorMessage } = this.certificate;
    this.pdfService.fetchDirectDebitPdf(url, twoFactorAuthorization, onErrorMessage);
    this.validation_hash = undefined;
  }

  async goBack() {
    await this.ptrabSessionManager.checkSession();
    this.modalManager.dismissModal();
  }
}
