import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { StorageService } from '@app/services/storage';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class WebViewService {
  constructor(private router: Router) {}

  isWebView(): boolean {
    const urlParams = new URLSearchParams(window.location.search);

    return (
      StorageService.isWebView() ||
      get(this.router.getCurrentNavigation(), 'extras.state.iswebview') ||
      urlParams.get('iswebview') === 'true'
    );
  }

  getLanguageCode(): string {
    const urlParams = new URLSearchParams(window.location.search);

    return (
      StorageService.getLanguageCode() ||
      get(this.router.getCurrentNavigation(), 'extras.state.languageCode') ||
      urlParams.get('languageCode')
    );
  }
}
