import { Component, Input, OnInit } from '@angular/core';

import { NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { ActionCookies, COOKIES_TIME, CookiesConfig, UserCookies } from '@app/shared/models/cookies/cookies.config';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from '@services/analytics/analytics.service';

@Component({
  selector: 'app-cookies-modal',
  templateUrl: './cookies-modal.component.html',
  styleUrls: ['./cookies-modal.component.scss']
})
export class CookiesModalComponent implements OnInit {
  @Input() hasAcceptedCookies = true;
  displayCookies: MSafeAny = {
    essentials: false,
    notEssentials: false
  };
  cookiesNotEssentials: MSafeAny = [
    {
      name: 'COOKIE_MODAL.TABLE.COOKIE1',
      value: 'COOKIE_MODAL.TABLE.VALUE2',
      desc: 'COOKIE_MODAL.TABLE.USE1',
      timeExpired: 'COOKIE_MODAL.TABLE.T_EXP_COOKIE3'
    },
    {
      name: 'COOKIE_MODAL.TABLE.COOKIE1',
      value: 'COOKIE_MODAL.TABLE.VALUE1',
      desc: 'COOKIE_MODAL.TABLE.USE1',
      timeExpired: 'COOKIE_MODAL.TABLE.T_EXP_COOKIE1'
    }
  ];
  cookiesEssentials: MSafeAny = [
    {
      name: 'COOKIE_MODAL.TABLE.COOKIES_PROP',
      value: 'SS_ X_JSESSIONID',
      desc: 'COOKIES.OWN_COOKIES_TABLE.USE_3',
      timeExpired: 'COOKIE_MODAL.TABLE.T_EXP_COOKIE5'
    },
    {
      name: 'COOKIE_MODAL.TABLE.COOKIES_PROP',
      value: 'GCLB',
      desc: 'COOKIES.OWN_COOKIES_TABLE.USE_4',
      timeExpired: 'COOKIE_MODAL.TABLE.T_EXP_COOKIE4'
    },
    {
      name: 'COOKIE_MODAL.TABLE.COOKIES_PROP',
      value: 'GUEST_LANGUAGE_ID',
      desc: 'COOKIE_MODAL.TABLE.USE3',
      timeExpired: 'COOKIE_MODAL.TABLE.T_EXP_COOKIE6'
    },
    {
      name: 'COOKIE_MODAL.TABLE.COOKIES_PROP',
      value: 'COOKIE_SUPPORT',
      desc: 'COOKIE_MODAL.TABLE.USE4',
      timeExpired: 'COOKIE_MODAL.TABLE.T_EXP_COOKIE6'
    },
    {
      name: 'COOKIE_MODAL.TABLE.COOKIES_WSC',
      value: '_hjid',
      desc: 'COOKIE_MODAL.TABLE.USE5',
      timeExpired: 'COOKIE_MODAL.TABLE.T_EXP_COOKIE5'
    }
  ];
  cookies: UserCookies = {
    essentialsCookies: true,
    notEssentialsCookies: false,
    expireTimeInMilliseconds: COOKIES_TIME.expireTime
  };

  constructor(
    private modalCtrl: ModalController,
    private analyticsService: AnalyticsService,
    private cookieConfig: CookiesConfig,
    private navigationService: NavigationService
  ) {}

  async ngOnInit() {
    if (this.hasAcceptedCookies && (await this.cookieConfig.getCookiesService())) {
      this.cookies = await this.cookieConfig.getCookiesService();
    }
  }

  displayInfoCookie(cookie: string) {
    this.displayCookies[cookie] = !this.displayCookies[cookie];
    setTimeout(() => {
      const icon = document.getElementById('icon-' + cookie);
      const info = document.getElementById('info-' + cookie);
      if (this.displayCookies[cookie]) {
        icon?.classList.add('open');
        info?.classList.add('open');
        info?.classList.remove('hidden');
      } else {
        info?.classList.add('hidden');
        icon?.classList.remove('open');
        setTimeout(() => {
          info?.classList.remove('open');
          info?.classList.remove('hidden');
        }, 220);
      }
    }, 250);
  }

  updateCookies() {
    this.analyticsService.toggleTracking(this.cookies.notEssentialsCookies);
    this.checkDevice();
  }

  checkDevice() {
    const dataEmit = { action: ActionCookies.SAVE, requestCookie: false, cookies: this.cookies };
    this.closeCookiesModal(dataEmit);
  }

  goToCookiesPage() {
    this.closeCookiesModal();
    const navEvent = new NavigationEvent(NavigationEvents.Push, { path: PAGES.COOKIES });
    this.navigationService.navigate(navEvent);
  }

  onChangeCookiesState(state: boolean) {
    this.cookies.notEssentialsCookies = state;
  }

  closeCookiesModal(data?: MSafeAny) {
    this.modalCtrl.dismiss(data);
  }

  trackByItems(item: MSafeAny) {
    return item;
  }
}
