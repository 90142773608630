<div *ngIf="caption" #captionContainer class="caption is-desktop">
  <div
    class="caption__element"
    *ngFor="let captionElement of caption; trackBy: trackByItems"
    (mouseenter)="showPopover(captionElement.description, $event)">
    <div class="caption-element d-flex" *ngIf="captionElement">
      <div class="caption-element__circle" [ngStyle]="getCaptionStyle(captionElement)"></div>
      <p class="caption-element__text u-p6 u-text-medium">{{ captionElement.name }}</p>
    </div>
  </div>
</div>
