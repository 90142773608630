import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

import { domChanges } from '@app/shared/utils/utils';
import { Platform } from '@ionic/angular';
import { Comment } from '@shared/models/comment/comment';

@Directive({
  selector: '[app-scroll-to-comment]'
})
export class ScrollToCommentDirective implements AfterViewInit {
  @Input() isHighlightComment = false;
  @Input() doScrollToComment = false;
  @Input() comment!: Comment;
  @Output() afterScroll: EventEmitter<Comment> = new EventEmitter();

  constructor(private el: ElementRef, private platform: Platform) {}

  async ngAfterViewInit() {
    if (this.isHighlightComment || this.doScrollToComment) {
      const content = this.el.nativeElement.closest('app-content');
      const clientRect: DOMRect = this.el.nativeElement.getBoundingClientRect();
      const isDesktop = !this.platform.is('cordova');
      let newY = isDesktop ? clientRect.y - Math.floor(content.offsetHeight / 2) : clientRect.y;
      if (this.doScrollToComment) {
        newY = this.el.nativeElement.offsetTop;
      }
      content.scrollToPoint(0, newY, 1000);

      await domChanges(1000);
      this.afterScroll.emit(this.comment);
    }
  }
}
