import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { tap } from 'rxjs/operators';

import { SliderComponent } from '@app/components/slider/slider.component';
import { Logger } from '@app/services';
import { Multimedia } from '@app/shared/models/multimedia/multimedia-detail.model';
import { AbstractMultimediaItem } from '@app/shared/models/multimedia/multimedia.abstract.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { MultimediaDetailDirective } from '../multimedia-detail.component';

@Component({
  selector: 'app-multimedia-carousel',
  templateUrl: './multimedia-carousel.component.html',
  styleUrls: ['./multimedia-carousel.component.scss', '../multimedia-detail.component.scss']
})
export class MultimediaCarouselComponent extends MultimediaDetailDirective implements OnInit {
  @ViewChild(SliderComponent) slides!: SliderComponent;

  // eslint-disable-next-line
  photoViewer!: HTMLIonModalElement;
  logger: Logger = new Logger('Multimedia Detail');
  item!: Multimedia;
  multimediaNotLoaded = false;
  onSlideTouchEnd = false;
  setFullScreen!: boolean;

  startIndexGallery = 0;
  videoCurrentTime!: number;

  updateMultimedia!: boolean | undefined;
  multimediaCarousel: MSafeAny = {
    currentTime: 0,
    isPlaying: false,
    videoId: null,
    mediaPlayer: null,
    positionPlayer: 0,
    muted: false,
    isCurrentIndexSlider: 0
  };
  auxUpdateCarrousel: MSafeAny[] = [];

  isWebView!: boolean;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getMultimedia();
    this.isWebView = this.webViewService.isWebView();
  }

  enterFullScreenVideo(element: MSafeAny) {
    this.setFullScreen = element;
  }

  async emitFullScreen(mediaPlayer: MSafeAny) {
    this.multimediaCarousel = mediaPlayer.actual;
    this.auxUpdateCarrousel = mediaPlayer.allVideos;
    this.openMultimediaCarousel(this.startIndexGallery);
  }

  async openMultimediaCarousel(activeIndex: number) {
    if (this.multimediaCarousel) {
      this.updateMultimedia = true;
    }
    this.photoViewer = (await this.photoViewerService.create({
      images: this.item.multiple,
      initialSlideIndex: activeIndex,
      multimediaCarousel: this.multimediaCarousel,
      auxUpdateCarrousel: this.auxUpdateCarrousel
    })) as MSafeAny;

    this.photoViewer.onDidDismiss().then(async (event) => {
      this.startIndexGallery = event.data.position;
      if (this.item.multiple?.length) {
        this.slideToIndex(this.startIndexGallery);
        this.updateMultimedia = !this.updateMultimedia;
        this.multimediaCarousel = event.data.multimediaCarousel;
        this.auxUpdateCarrousel = event.data.auxUpdateCarrousel;
      } else {
        this.updateMultimedia = undefined;
      }
    });
  }

  getActiveIndexSlide() {
    this.startIndexGallery = this.slides.getActiveIndex();
  }

  slideToIndex(index: number) {
    this.slides.slideTo(index);
  }

  getMultimedia() {
    this.getItem()
      .pipe(
        tap((item: AbstractMultimediaItem) => {
          if (this.emptyUrl(item)) {
            this.multimediaNotLoaded = true;
            this.toastService.showError(
              this.messages['ERRORS_TOASTS.ERROR_TITLE'],
              this.messages['ERRORS_TOASTS.INVALID_CONTENT_URL']
            );
          }
        })
      )
      .subscribe(
        () => {
          this.incrementViews();
        },
        (err) => {
          this.logger.error(err);
          this.close();
        }
      );
  }

  onSlideChange(isVideoEnterFullscreen: boolean) {
    if (!isVideoEnterFullscreen) {
      this.getActiveIndexSlide();
      this.slides.pauseCurrentPlayerPlaying();
    }
  }

  like() {
    this.sendLike(this.item);
  }

  private emptyUrl(item: MSafeAny) {
    return !item.url && item.multiple.length === 0;
  }
}
