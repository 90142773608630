import { CategoriesAnalytics, PagesAnalytics, ActionsAnalytics } from '@app/services/analytics/models/analytics.enum';
import { ICONS } from '@app/shared/enums/items-icons/items-icons.enum';
import { MOT_PAGES, PAGES, PTRAB_PAGES } from '@app/shared/enums/pages/pages.enum';
import { getMotUrlFromMap, getPtrabUrlFromMap, getUrlFromMap } from '@app/shared/enums/pages/pages.urls';
import { Permissions } from '@app/shared/enums/permissions/permissions.enum';
import { ProfileMenuLink } from '@app/shared/models/profile-menu/profile-menu-link.model';
import { MOT_SECTIONS_PERMISSIONS } from '@mot/mot-permissions.const';
import { PTRAB_SECTIONS_PERMISSIONS } from '@ptrab/ptrab-permissions.const';

const PTRAB_ACCESS_LIST: ProfileMenuLink[] = [
  new ProfileMenuLink({
    name: 'EMPLOYEE_PORTAL.MY_PAYSLIPS',
    page: getPtrabUrlFromMap(PTRAB_PAGES.PAYSLIPS),
    icon: ICONS.PAYSLIPS,
    permission: PTRAB_SECTIONS_PERMISSIONS.PAYSLIPS,
    analyticsLabel: CategoriesAnalytics.PAYSLIPS
  }),
  new ProfileMenuLink({
    name: 'EMPLOYEE_PORTAL.MY_PERSONAL_INCOME_TAX',
    page: getPtrabUrlFromMap(PTRAB_PAGES.PERSONAL_INCOME_TAX),
    icon: ICONS.INCOME_TAX,
    permission: PTRAB_SECTIONS_PERMISSIONS.PERSONAL_INCOME_TAX,
    analyticsLabel: CategoriesAnalytics.IRPF
  }),
  new ProfileMenuLink({
    name: 'EMPLOYEE_PORTAL.MY_DOCUMENTS',
    page: getPtrabUrlFromMap(PTRAB_PAGES.CERTIFICATES),
    icon: ICONS.CERTIFICATES,
    permission: PTRAB_SECTIONS_PERMISSIONS.CERTIFICATES,
    analyticsLabel: PagesAnalytics.CERTIFICATES
  }),
  new ProfileMenuLink({
    name: 'PROFILE.PERSONAL_DATA.TITLE',
    page: getUrlFromMap(PAGES.PERSONAL_DATA),
    icon: ICONS.PERSONAL_DATA,
    permission: PTRAB_SECTIONS_PERMISSIONS.PERSONAL_DATA,
    analyticsLabel: ActionsAnalytics.MY_DATA
  })
];

const PROFILE_LANGUAGE: ProfileMenuLink[] = [
  new ProfileMenuLink({
    name: 'PROFILE.LANGUAGE',
    page: PAGES.LANGUAGE,
    icon: ICONS.LANGUAGE,
    permission: [],
    analyticsLabel: PagesAnalytics.LANGUAGE
  })
];

const MOT_LIST: ProfileMenuLink[] = [
  new ProfileMenuLink({
    name: 'MOT.MENU_LINK',
    page: getMotUrlFromMap(MOT_PAGES.SCHEDULE),
    icon: ICONS.MY_TIMETABLE,
    permission: MOT_SECTIONS_PERMISSIONS.MOT_SCHEDULE,
    analyticsLabel: CategoriesAnalytics.SCHEDULE
  }),
  new ProfileMenuLink({
    name: 'MOT.VACATIONS_MENU_LINK',
    page: getMotUrlFromMap(MOT_PAGES.VACATIONS),
    icon: ICONS.MY_HOLIDAYS,
    permission: MOT_SECTIONS_PERMISSIONS.MOT_VACATIONS,
    analyticsLabel: ActionsAnalytics.MY_HOLIDAYS
  }),
  new ProfileMenuLink({
    name: 'MOT.VACATIONS_REQUEST_MENU_LINK',
    page: getMotUrlFromMap(MOT_PAGES.VACATIONS_REQUEST),
    imgIcon: 'assets/imgs/icons/notes-edit-black.svg',
    permission: MOT_SECTIONS_PERMISSIONS.MOT_MANAGE_VACATIONS,
    analyticsLabel: ActionsAnalytics.HOLIDAYS_REQUEST
  })
];

const CONTENT_LIST: ProfileMenuLink[] = [
  new ProfileMenuLink({
    name: 'PROFILE.MY_EXAMPLES',
    page: PAGES.MY_EXAMPLES,
    permission: [],
    analyticsLabel: 'Ejemplos'
  })
];

const LEGAL_CONDITIONS_LIST: ProfileMenuLink[] = [
  new ProfileMenuLink({
    name: 'PROFILE.LINK_CONDITIONS',
    page: PAGES.TERMS_AND_CONDITIONS,
    icon: ICONS.TERMS_AND_CONDITIONS,
    permission: [],
    analyticsLabel: PagesAnalytics.PRIVACY
  }),
  new ProfileMenuLink({
    name: 'PROFILE.LINK_COOKIES',
    page: PAGES.COOKIES,
    icon: ICONS.COOKIES,
    permission: [],
    analyticsLabel: PagesAnalytics.COOKIES
  }),
  new ProfileMenuLink({
    name: 'PROFILE.LINK_DATA_TREATMENT',
    page: PAGES.DATA_TREATMENT,
    icon: ICONS.DATA_TREATMENT,
    permission: [],
    analyticsLabel: PagesAnalytics.DATA_TREATMENT
  }),
  new ProfileMenuLink({
    name: 'PROFILE.LINK_BEHAVIOUR_CODE',
    page: PAGES.BEHAVIOUR_CODE,
    icon: ICONS.BEHAVIOUR_CODE,
    permission: [Permissions.PORTUGAL_PERMISSIONS],
    analyticsLabel: PagesAnalytics.BEHAVIOUR_CODE
  })
];

const CONTACT_INFO_LIST: ProfileMenuLink[] = [
  new ProfileMenuLink({
    name: 'PROFILE.OTHERS.CONTACT_US',
    page: PAGES.CONTACT_US,
    permission: [],
    analyticsLabel: 'Contacto'
  }),
  new ProfileMenuLink({
    name: 'PROFILE.LINK_DEVICE_INFO',
    page: PAGES.DEVICE_INFO,
    icon: ICONS.DEVICE_INFO,
    permission: [],
    analyticsLabel: 'Informacion'
  })
];

const DEVICE_INFO_LIST: ProfileMenuLink[] = [
  new ProfileMenuLink({
    name: 'PROFILE.LINK_DEVICE_INFO',
    page: PAGES.DEVICE_INFO,
    icon: ICONS.DEVICE_INFO,
    permission: [],
    analyticsLabel: PagesAnalytics.DEVICE_INFO
  })
];
const CONFIGURATION: ProfileMenuLink[] = [
  new ProfileMenuLink({
    name: 'PROFILE.CONFIGURATION.TITLE',
    page: PAGES.CONFIGURATION,
    icon: ICONS.CONFIGURATION,
    permission: [],
    analyticsLabel: PagesAnalytics.CONFIGURATION
  })
];

const OFFICESERVICES: ProfileMenuLink[] = [
  new ProfileMenuLink({
    name: 'PROFILE.OFFICE_SERVICE.TITLE',
    page: PAGES.OFFICESERVICES,
    icon: ICONS.OFFICESERVICES,
    permission: [],
    analyticsLabel: PagesAnalytics.OFFICESERVICES
  })
];

const LOGISTIC_ANUAL_CALENDAR: ProfileMenuLink[] = [
  new ProfileMenuLink({
    name: 'LOGISTIC.TITLE',
    page: getUrlFromMap(PAGES.LOGISTIC_INFO),
    icon: ICONS.LOGISTIC_INFO,
    permission: [Permissions.ORGANIZA2_CALENDAR],
    analyticsLabel: PagesAnalytics.LOGISTIC_INFO
  })
];

export const PROFILE_MENU_GROUPS = {
  PROFILE_LANGUAGE,
  PTRAB_ACCESS_LIST,
  CONTENT_LIST,
  LEGAL_CONDITIONS_LIST,
  CONTACT_INFO_LIST,
  DEVICE_INFO_LIST,
  MOT_LIST,
  LOGISTIC_ANUAL_CALENDAR,
  CONFIGURATION,
  OFFICESERVICES
};
