import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { NewsPopoverComponent } from '@app/components/header/components/news-popover/news-popover.component';
import { NewsItem } from '@app/shared/models/conditions/conditions.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { isIOS } from '@app/shared/utils/is-tablet.util';
import { PopoverController } from '@ionic/angular';

import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class NewsPopoverService {
  // eslint-disable-next-line
  newsPopover!: HTMLIonPopoverElement | null;
  newsItem!: NewsItem;
  allowGoBack!: boolean;
  popoverHidden!: boolean;
  isPopoverVisible!: boolean;
  isIOs = isIOS();

  private visibilityChangeSubject = new BehaviorSubject<boolean>(true);
  visibilityChange$ = this.visibilityChangeSubject.asObservable();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private popoverCtrl: PopoverController
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((data) => {
        this.allowGoBack = data.allowGoBack;
        this.popoverHidden = data.popoverHidden;
        if (this.newsPopover) {
          this.newsPopover.cssClass = this.getCssClass();
          this.emitVisibilityChange();
        }
      });
  }

  async openNewsPopover(target: HTMLElement, newsItem: NewsItem) {
    if (this.isPopoverVisible) {
      return;
    }
    this.isPopoverVisible = true;
    const event: MSafeAny = { target };
    this.newsPopover = await this.popoverCtrl.create({
      component: NewsPopoverComponent,
      componentProps: {
        newsType: newsItem.type
      },
      event,
      cssClass: this.getCssClass(),
      showBackdrop: false,
      backdropDismiss: false
    });

    this.emitVisibilityChange();

    this.newsPopover.onDidDismiss().then((result) => {
      if (result) {
        this.isPopoverVisible = false;
        this.userService.setAcceptNews(newsItem).subscribe();
      }
    });

    this.newsPopover.present();
  }

  getCssClass() {
    let cssClass = 'news-popover';
    if (this.popoverHidden) {
      cssClass += ' hidden';
    }
    if (this.isIOs) {
      cssClass += ' ios';
    }
    return cssClass;
  }

  hidePopover() {
    if (!this.newsPopover) {
      return;
    }
    this.popoverHidden = true;
    this.updateVisibility();
  }

  showPopover() {
    if (!this.newsPopover) {
      return;
    }
    this.popoverHidden = false;
    this.updateVisibility();
  }

  private updateVisibility() {
    if (this.newsPopover) {
      this.newsPopover.cssClass = this.getCssClass();
    }
    this.emitVisibilityChange();
  }

  private emitVisibilityChange() {
    const isVisible = !this.getCssClass().includes('hidden');
    this.visibilityChangeSubject.next(isVisible);
  }
}
