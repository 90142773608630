import { Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';

import { PhoneStatusTypes } from '@app/ptrab/shared/enums/phone-status-types';

@Component({
  selector: 'app-data-item',
  templateUrl: './data-item.component.html',
  styleUrls: ['./data-item.component.scss']
})
export class DataItemComponent implements OnChanges {
  @HostBinding('class.c-data-item') hostClass = true;

  @Input() label!: string;
  @Input() value!: string;
  @Input() actionText!: string;
  @Input() actionIcon!: string;
  @Input() status!: string;
  @Input() date!: string;
  @Input() hideAction = false;

  @Output() actionClick = new EventEmitter();

  isPending = false;

  ngOnChanges() {
    this.isPending = this.status === PhoneStatusTypes.PENDING;
  }
}
