import { Component, Input, OnInit } from '@angular/core';

import { ErrorInfoMessage } from '@app/shared/interfaces/error/error-info.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { appVersion } from '@shared/utils/utils';

@Component({
  selector: 'app-error-info',
  templateUrl: './error-info.component.html',
  styleUrls: ['./error-info.component.scss']
})
export class ErrorInfoComponent implements OnInit {
  version: string = appVersion;

  imageAction: MSafeAny;
  cssClasses!: string;

  @Input() errorInfo!: ErrorInfoMessage;
  @Input() showAdditionalData!: boolean;

  constructor() {}

  ngOnInit() {
    this.imageAction = this.errorInfo.imageAction || (() => {});
    this.cssClasses = !this.errorInfo.justified_style ? 'error-info' : 'error-info-justified';
  }

  onClickImage() {
    return this.imageAction();
  }

  trackByItems(item: MSafeAny) {
    return item;
  }
}
