import { Component, Input, OnInit } from '@angular/core';

import { TwoFactorOperationCode } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { Phone } from '@app/ptrab/shared/models/phone';
import { ModalManager, ToastService } from '@app/services';
import { RadiosDirective } from '@app/shared/view/radios.directive';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ptrab-second-fa-phone-selection',
  templateUrl: './second-fa-phone-selection.component.html',
  styleUrls: ['./second-fa-phone-selection.component.scss']
})
export class SecondFaPhoneSelectionComponent extends RadiosDirective implements OnInit {
  @Input() phones!: Phone[];
  @Input() toastMessage!: string;

  selectedPhoneId!: string | null | undefined;
  description!: string;
  reminderMessage!: string;
  isSinglePhone!: boolean;
  availablePhones!: Phone[];

  buttons = [
    {
      text: 'EMPLOYEE_PORTAL.PHONE_NOT_FOUND',
      type: 'link',
      enabled: true,
      onClick: () => this.editPhones()
    },
    {
      text: 'EMPLOYEE_PORTAL.CONTINUE',
      type: 'primary',
      enabled: false,
      onClick: () => this.close(true)
    }
  ];

  private messages!: string[];

  constructor(
    private translateService: TranslateService,
    private modalManager: ModalManager,
    private toastService: ToastService
  ) {
    super();
    this.getTranslations();
  }

  ngOnInit() {
    this.availablePhones = this.phones.filter((phone) => phone.sms);
    this.isSinglePhone = this.availablePhones && this.availablePhones.length === 1;
    this.checkPhones();
  }

  get getUniquePhone(): Phone | undefined {
    return this.availablePhones.find((phone) => phone.sms);
  }

  editPhones() {
    if (this.phones.length && this.phones.some((phone) => phone.show_to_edit)) {
      this.modalManager.dismissModal(TwoFactorOperationCode.REQUEST_OTHER_PHONE);
    } else {
      this.toastService.showError(this.messages['EMPLOYEE_PORTAL.EDIT_ADD_NUMBER'], this.toastMessage);
    }
  }

  close(withValue: boolean) {
    if (!withValue) {
      this.selectedPhoneId = null;
    }

    this.modalManager.dismissModal(this.selectedPhoneId);
  }

  setSelectedPhone(phoneId: string | undefined) {
    this.selectedPhoneId = phoneId;
    this.buttons[1].enabled = true;
  }

  trackByItems(item: Phone) {
    return item;
  }

  private checkPhones() {
    this.reminderMessage = this.messages['EMPLOYEE_PORTAL.ONLY_MOBILE'];

    if (this.isSinglePhone) {
      this.description = this.messages['EMPLOYEE_PORTAL.SINGLE_PHONE.MESSAGE'];
      this.setSelectedPhone(this.getUniquePhone?.id);
      return;
    }
    this.description = this.messages['EMPLOYEE_PORTAL.SELECT_NUMBER'];
  }

  private getTranslations() {
    this.translateService
      .get([
        'EMPLOYEE_PORTAL.SELECT_NUMBER',
        'EMPLOYEE_PORTAL.ONLY_MOBILE',
        'EMPLOYEE_PORTAL.SINGLE_PHONE.MESSAGE',
        'EMPLOYEE_PORTAL.EDIT_ADD_NUMBER'
      ])
      .subscribe((messages) => (this.messages = messages));
  }
}
