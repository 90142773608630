import { Component, OnInit } from '@angular/core';

import { WebViewService } from '@app/shared/services/webview.service';
import { Platform } from '@ionic/angular';

import { OutdatedBrowserService } from './outdated-browser.service';

@Component({
  selector: 'app-outdated-browser-alert',
  templateUrl: './outdated-browser-alert.component.html',
  styleUrls: ['./outdated-browser-alert.component.scss']
})
export class OutdatedBrowserAlertComponent implements OnInit {
  showAlert = false;
  icon!: string;
  browser!: string;
  version!: string;
  minVersion!: string;

  constructor(
    private outdatedBrowserService: OutdatedBrowserService,
    private platform: Platform,
    private webViewService: WebViewService
  ) {}

  ngOnInit() {
    /**
     * There is a issue with the library which checks the browser properties inside webview.
     * Issue URL: https://github.com/lancedikson/bowser/issues/140
     */
    //
    this.showAlert =
      !this.platform.is('cordova') && this.outdatedBrowserService.shouldShowAlert() && !this.webViewService.isWebView();
    this.browser = this.outdatedBrowserService.browserName;
    this.version = this.outdatedBrowserService.browserVersion;
    this.minVersion = this.outdatedBrowserService.minVersion;
    this.icon = this.outdatedBrowserService.browserShortName;
  }

  dismiss() {
    this.showAlert = false;
    this.outdatedBrowserService.confirmBrowser();
  }
}
