import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

export class StreetType {
  code: string;
  name: string;

  constructor(data: MSafeAny) {
    this.code = data?.code;
    this.name = data?.type_name;
  }
}
