import { Component, EventEmitter, Output } from '@angular/core';

import { CookiesModalComponent } from '@app/components/modals/cookies-modal/cookies-modal.component';
import { NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { ActionCookies } from '@app/shared/models/cookies/cookies.config';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-lightbox-cookies',
  templateUrl: './lightbox-cookies.component.html',
  styleUrls: ['./lightbox-cookies.component.scss']
})
export class LightboxCookiesComponent {
  errorTitleCookie!: string;
  errorMessageCookie!: string;
  @Output() action: EventEmitter<MSafeAny> = new EventEmitter();

  constructor(private navigationservice: NavigationService, private modalCtrl: ModalController) {}

  acceptCookies() {
    this.action.emit({ action: ActionCookies.ACCEPT });
  }

  rejectCookies() {
    this.action.emit({ action: ActionCookies.REJECT });
  }

  goToCookiesPage() {
    const navEvent = new NavigationEvent(NavigationEvents.Push, { path: PAGES.COOKIES });
    this.navigationservice.navigate(navEvent);
  }

  async configCookies() {
    const container = document.getElementById('lightbox-cookies');
    const cookiesModal = await this.modalCtrl.create({
      component: CookiesModalComponent,
      componentProps: { hasAcceptedCookies: false },
      backdropDismiss: true,
      cssClass: 'cookies-modal'
    });
    cookiesModal.present().then(() => {
      container?.classList.add('hidden-container');
    });
    cookiesModal.onDidDismiss().then((event) => {
      container?.classList.remove('hidden-container');
      if (event?.data) {
        this.action.emit({ action: event.data.action, cookies: event.data.cookies });
      }
    });
  }
}
