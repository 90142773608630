import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';
import { ImageCompressionService } from '@services/files/image-compression/image-compression.service';
import { LoadingService } from '@services/loading/loading.service';
import { Logger } from '@services/logger/logger.service';
import { ToastService } from '@services/toast/toast.service';
import { ExtensionsManager } from '@shared/extensions/extensions-manager';
/* eslint-disable @typescript-eslint/naming-convention */

enum AvatarExtensions {
  IMAGE = 'IMAGE'
}

const AVATAR_VALID_EXTENSIONS = {};
AVATAR_VALID_EXTENSIONS[AvatarExtensions.IMAGE] = ['jpg', 'jpeg'];

const AVATAR_MAX_SIZE_MB = 0.05;

export interface IProfileAvatarEvent {
  file: File;
  blob?: Blob;
  preview: SafeUrl | string;
  reason?: MSafeAny;
}

@Injectable({ providedIn: 'root' })
export class ProfileAvatarService {
  private logger = new Logger('ProfileAvatarProvider');
  private extensionsManager = new ExtensionsManager<AvatarExtensions>(AVATAR_VALID_EXTENSIONS);
  private readonly imageScale = 300;

  constructor(
    private imageCompressionService: ImageCompressionService,
    private loadingService: LoadingService,
    private sanitizer: DomSanitizer,
    private toastService: ToastService,
    private translate: TranslateService
  ) {
    this.extensionsManager.setExtensions(AvatarExtensions.IMAGE);
  }

  getAcceptedFileExtensions(): string {
    return this.extensionsManager.getParsedExtensions();
  }

  async handleDesktopImage(evt): Promise<IProfileAvatarEvent> {
    const file = evt && evt.target && evt.target.files && evt.target.files[0];

    if (!file) {
      return Promise.reject({ error: 'File not selected...', type: 'None' });
    }

    if (!this.extensionsManager.isValidExtension(file.name)) {
      return Promise.reject({ msgKey: 'EXTENSION', error: 'Error with file extension', type: 'Alert' });
    }

    const preview = this.sanitizer.sanitize(SecurityContext.URL, URL.createObjectURL(file)) as string;
    this.loadingService.show();

    try {
      this.logger.debug(`file is "${file.name}"`);
      const fileResult = await this.imageCompressionService.compress(file, {
        maxSizeMB: AVATAR_MAX_SIZE_MB,
        maxWidthOrHeight: this.imageScale
      });
      this.loadingService.hide();
      const profileAvatarEvent: IProfileAvatarEvent = { file: fileResult as MSafeAny, preview };
      return profileAvatarEvent;
    } catch (error) {
      this.loadingService.hide();
      return Promise.reject({ error, type: 'Alert' });
    }
  }

  async triggerDesktopFlow(event: MSafeAny): Promise<MSafeAny> {
    try {
      const profileAvatarEvent: IProfileAvatarEvent = await this.handleDesktopImage(event);
      return profileAvatarEvent;
    } catch (err: MSafeAny) {
      const error = err;
      this.logger.error(error);
      const msgKey = error.msgKey || 'ERROR_BODY';
      const errorType = error.type || 'Error';

      const errorAction = {
        Alert: () => this.toastService.showWarning(this.getMessage('WARNING'), this.getMessage(msgKey), 3000),
        Error: () => this.toastService.showError(this.getMessage('ERROR_TITLE'), this.getMessage(msgKey), 3000)
      };

      errorAction[errorType]();

      throw new Error(error.error);
    }
  }

  private getMessage(subkey: string) {
    const key = `PROFILE.EDIT_AVATAR.${subkey}`;
    return this.translate.instant(key);
  }
}
