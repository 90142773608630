import { NgModule } from '@angular/core';

import { IbanPipe } from '@app/shared/pipes/iban/iban.pipe.pipe';
import { NounUpperCasePipe } from '@app/shared/pipes/noun-upper-case/noun-upper-case.pipe';
import { StripHtmlPipe } from '@app/shared/pipes/strip-html/strip-html.pipe';
import { TextOrBlankSpace } from '@app/shared/pipes/text-or-blank/text-or-blank-space.pipe';

import { AddressPipe } from './address/address.pipe';
import { PortugueseAddressPipe } from './portuguese-address/portuguese-address.pipe';
import { SafePipe } from './safe/safe.pipe';
import { UcfirstPipe } from './ucfirst/ucfirst.pipe';

const pipes = [
  NounUpperCasePipe,
  StripHtmlPipe,
  TextOrBlankSpace,
  IbanPipe,
  SafePipe,
  AddressPipe,
  UcfirstPipe,
  PortugueseAddressPipe
];

@NgModule({
  declarations: pipes,
  exports: pipes
})
export class PipesModule {}
