import { Component, Input } from '@angular/core';

import { PagesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { Platform } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';

interface IConfirmationProps {
  buttons: Buttons[];
  contents: string[];
  example?: string;
  hasFixedHeight?: boolean;
  pageAnalytics?: PagesAnalytics;
  title: string;
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements IConfirmationProps {
  @Input() buttons!: Buttons[];
  @Input() contents!: string[];
  @Input() example!: string;
  @Input() hasFixedHeight = false;
  @Input() title!: string;

  constructor(private platform: Platform) {}

  trackByItems(item: MSafeAny) {
    return item;
  }
}

export interface IConfirmationModalOptions extends ModalOptions {
  componentProps: IConfirmationProps;
}
