import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { PortugueseAddress } from '../../models/personal-data/address.model';

@Pipe({ name: 'portugueseAddress' })
export class PortugueseAddressPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(address: PortugueseAddress): string {
    if (!address) {
      return this.translate.instant('PROFILE.PERSONAL_DATA.ADDRESS.EMPTY');
    }

    const place = `${address.street_description}\n${address.postal_code}, ${address.locality}, ${address.concelho.name}`;

    return place;
  }
}
