import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {
  ActionSheetController,
  PopoverController,
  ModalController,
  MenuController,
  AlertController
} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AutoCloseOverlaysService {
  private unsubscribeBackButtonSubject = new BehaviorSubject<string>('subscribe');
  unsubscribeBackButtonSubject$ = this.unsubscribeBackButtonSubject.asObservable();

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private menu: MenuController
  ) {}

  async checkIsOpenModal(): Promise<boolean> {
    const ctrlElementOpen = (await this.modalCtrl.getTop()) || (await this.alertCtrl.getTop());
    return Boolean(ctrlElementOpen);
  }

  async trigger() {
    const actionSheet = await this.actionSheetCtrl.getTop();
    const popover = await this.popoverCtrl.getTop();
    const modal = await this.modalCtrl.getTop();
    if (!actionSheet && !popover && !modal) {
      this.getMenuStatus();
    } else if (actionSheet) {
      actionSheet.dismiss();
    } else if (popover) {
      popover.dismiss();
    } else if (modal) {
      modal.dismiss();
    }
  }

  async getMenuStatus() {
    const menu = await this.menu.getOpen();
    if (!menu) {
      this.exitApp();
    } else {
      this.menu.close();
    }
  }

  subscribeToBackButton() {
    this.unsubscribeBackButtonSubject.next('subscribe');
  }

  exitApp() {
    this.unsubscribeBackButtonSubject.next('exitApp');
  }
}
