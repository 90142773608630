<div *ngIf="userInfo" class="menu-header">
  <div class="menu-header_container">
    <div class="menu-header__avatar">
      <app-avatar-manager [user]="userInfo" (changeAvatar)="confirmAvatarEdit($event)"></app-avatar-manager>
    </div>
    <div class="menu-header__info">
      <h4 class="u-h4 info-text" *ngIf="userInfo.alias && !userInfo.name">
        {{ userInfo.alias }}
      </h4>
      <h4 class="u-h4 info-text" *ngIf="userInfo.name">
        {{ userInfo.name | nounUpperCase }} {{ userInfo.lastname | nounUpperCase }}
      </h4>
    </div>
    <ion-icon name="close" (click)="onClose()"></ion-icon>
  </div>

  <div class="menu-subheader__info" *ngIf="!userInfo.isDiscontinousFixed">
    <h4 class="u-h4 u-medium-grey u-margin-medium-bottom" *ngIf="userInfo.department">
      {{ userInfo.department | nounUpperCase }}
    </h4>
    <div class="container-space-bottom">
      <h4 class="u-h4 u-book u-margin-none-bottom u-medium-grey" *ngIf="userInfo.division_zone">
        {{ userInfo.division_zone | nounUpperCase }}
      </h4>
      <h4 class="u-h4 u-book u-margin-none-bottom u-medium-grey" *ngIf="userInfo.store">
        {{ userInfo.store | nounUpperCase }}
      </h4>
    </div>
  </div>
</div>
