<div class="title-description-multiple-card" [class.c-pointer]="hasAction" [class.box]="!insideModal">
  <div class="grid">
    <span class="title-description-multiple-card__title">{{ cardTitle }}</span>
    <div class="row" *ngFor="let item of values; trackBy: trackByItems">
      <div class="col title-description-multiple-card__info" size="12">
        <p class="title-description-multiple-card__info__title">{{ item.title }}</p>
        <ng-container *ngIf="listStyle; else standard">
          <p
            class="title-description-multiple-card__info__description list"
            *ngFor="let desc of item.description; trackBy: trackByDesc">
            {{ desc }}
          </p>
        </ng-container>
        <ng-template #standard>
          <p class="title-description-multiple-card__info__description">
            {{ item.description }}
          </p>
        </ng-template>
      </div>
    </div>
  </div>
</div>
