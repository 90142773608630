import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from '@app/components/components.module';
import { PtrabComponentsModule } from '@app/ptrab/components/ptrab-components.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { DateSelectComponent } from './date-select/date-select.component';
import { MonthPickerComponent } from './date-select/month-picker/month-picker.component';
import { DropdownFiltersComponent } from './dropdown-filters.component';

const components = [DropdownFiltersComponent, DateSelectComponent, MonthPickerComponent];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    PtrabComponentsModule,
    ComponentsModule
  ],
  declarations: [components],
  exports: [components],
  entryComponents: [DateSelectComponent, MonthPickerComponent]
})
export class DropdownFiltersModule {}
