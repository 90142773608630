import { Injectable } from '@angular/core';

import { ENV } from 'src/environments/environment';
/* eslint-disable @typescript-eslint/naming-convention */

@Injectable({
  providedIn: 'root'
})
export class ApiPtrabUrls {
  readonly payslip = {
    list: `${ENV.API_URL_PTRAB}/ptrab/payslip`,
    getFile: (payslipId: string) => `${ENV.API_URL_PTRAB}/ptrab/payslip/${payslipId}/duplicate`,
    wageChargeCertificate: `${ENV.API_URL_PTRAB}/ptrab/payslip/wage-charge-certificate`,
    status: `${ENV.API_URL_PTRAB}/ptrab/payslip/status`
  };

  readonly directDebit = {
    list: `${ENV.API_URL_PTRAB}/ptrab/payslip/direct-debit`,
    getFile: `${ENV.API_URL_PTRAB}/ptrab/payslip/direct-debit/copy`,
    certificateStatus: `${ENV.API_URL_PTRAB}/ptrab/payslip/direct-debit/status-certificate`
  };

  readonly twoFactor = {
    userPhoneList: `${ENV.API_URL_USERS}/user/phones`,
    phoneList: `${ENV.API_URL_USERS}/user/v2/phones`,
    sendValidationCode: `${ENV.API_URL_USERS}/2fa`,
    checkUserStatus: `${ENV.API_URL_USERS}/2fa/status`,
    validate: `${ENV.API_URL_USERS}/2fa/validate`
  };

  readonly withholding = {
    list: `${ENV.API_URL_PTRAB}/ptrab/payslip/withholding`,
    portugalList: `${ENV.API_URL_PTRAB}/ptrab/payslip/withholding/list`,
    certificate: (year: number) => `${ENV.API_URL_PTRAB}/ptrab/payslip/withholding/certificate?year=${year}`,
    portugueseCertificate: (year: number, company_code: string | undefined, certificate_type: string | undefined) =>
      `${ENV.API_URL_PTRAB}/ptrab/payslip/withholding/certificate?year=${year}&company_code=${company_code}&certificate_type=${certificate_type}`
  };

  readonly garnishment = {
    list: `${ENV.API_URL_PTRAB}/ptrab/payslip/garnishment`,
    calculate: `${ENV.API_URL_PTRAB}/ptrab/payslip/garnishment/calculate`
  };

  readonly carTransfer = {
    list: `${ENV.API_URL_PTRAB}/ptrab/payslip/car-transfer`,
    getFile: (payslipId: string) => `${ENV.API_URL_PTRAB}/ptrab/payslip/car-transfer/${payslipId}/copy`
  };

  readonly certificate = {
    studyAid: `${ENV.API_URL_PTRAB}/ptrab/certificate/study-aid`,
    employment: `${ENV.API_URL_PTRAB}/ptrab/certificate/employment`,
    employmentSituation: `${ENV.API_URL_PTRAB}/ptrab/certificate/employment-situation`,
    weeklyHours: `${ENV.API_URL_PTRAB}/ptrab/certificate/weekly-hours`,
    workingTimeReduction: `${ENV.API_URL_PTRAB}/ptrab/certificate/working-time-reduction`,
    riskyPregnancyList: `${ENV.API_URL_PTRAB}/ptrab/certificate/risk-during-pregnancy-list`,
    riskyPregnancy: `${ENV.API_URL_PTRAB}/ptrab/certificate/risk-during-pregnancy`,
    disability: `${ENV.API_URL_PTRAB}/ptrab/certificate/disability`,
    income: `${ENV.API_URL_PTRAB}/ptrab/certificate/income-and-assets`,
    wageCharge: `${ENV.API_URL_PTRAB}/ptrab/certificate/wage-charge`
  };

  readonly session = {
    status: `${ENV.API_URL_PTRAB}/ptrab/login/status`,
    login: `${ENV.API_URL_PTRAB}/ptrab/login`,
    logout: `${ENV.API_URL_PTRAB}/ptrab/login/logout`
  };

  readonly personalIncomeTax = {
    data: `${ENV.API_URL_PTRAB}/ptrab/personal-income-tax`,
    getFile: `${ENV.API_URL_PTRAB}/ptrab/personal-income-tax/pit-pdf`
  };

  readonly readjustment = {
    regularization: `${ENV.API_URL_PTRAB}/ptrab/personal-income-tax/navarra-regularization`
  };

  readonly irs = {
    civilStatus: `${ENV.API_URL_PTRAB}/ptrab/irs/civil-status`,
    personalData: `${ENV.API_URL_PTRAB}/ptrab/irs/personal-data`,
    spouse: `${ENV.API_URL_PTRAB}/ptrab/irs/spouse`,
    dependant: `${ENV.API_URL_PTRAB}/ptrab/irs/dependent`,
    youngBenefit: `${ENV.API_URL_PTRAB}/ptrab/irs/irs-jovem`,
    roomBenefit: `${ENV.API_URL_PTRAB}/ptrab/irs/housing-credit`,
    withholding: `${ENV.API_URL_PTRAB}/ptrab/irs/withholding`,
    enjoyedYears: `${ENV.API_URL_PTRAB}/ptrab/irs/enjoyed-years`
  };

  readonly documents = {
    data: `${ENV.API_URL_PTRAB}/ptrab/documents`,
    url: (documentId: string) => `${ENV.API_URL_PTRAB}/ptrab/documents/${documentId}/url`,
    onboarding: `${ENV.API_URL_PTRAB}/ptrab/documents/onboarding`
  };
}
