import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

import { MediaPlayerComponent } from '@app/components/media-player/media-player.component';
import { Logger, NetworkService, ToastService } from '@app/services';
import { MediaPlayerService } from '@app/services/media-player/media-player.service';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-video-preview',
  templateUrl: './video-preview.component.html'
})
export class VideoPreviewComponent implements OnInit {
  @Input() previewVideo!: SafeUrl;
  @Input() isLoadingFile!: boolean;
  @Input() isPublicationParent = false;

  @Output() remove = new EventEmitter<void>();

  @ViewChild('player') player!: MediaPlayerComponent;

  private readonly CSS_CLASSES = {
    MODAL: 'media-content-modal--fullscreen',
    PAGE: 'example-forms--fullscreen'
  };
  private readonly logger = new Logger('VideoPreviewComponent');
  // eslint-disable-next-line
  private modalRef!: HTMLIonModalElement;

  constructor(
    private renderer: Renderer2,
    private playerService: MediaPlayerService,
    private networkService: NetworkService,
    private modalCtrl: ModalController,
    private toastCtrl: ToastService,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.modalRef = (await this.modalCtrl.getTop()) as MSafeAny;
  }

  onEnterFullscreen() {
    this.renderer.addClass(this.modalRef, this.CSS_CLASSES.MODAL);
    this.renderer.addClass(document.body, this.CSS_CLASSES.PAGE);
    this.playerService.enterFullscreenDevice(this.player);
  }

  onExitFullscreen() {
    this.renderer.removeClass(this.modalRef, this.CSS_CLASSES.MODAL);
    this.renderer.removeClass(document.body, this.CSS_CLASSES.PAGE);
    this.playerService.exitFullscreenDevice(this.player);
  }

  onStalled() {
    this.logger.debug(`Video stalled at ${new Date().toLocaleTimeString('es')}`);
    this.networkService.redirectIfOffline();
  }

  onRemove() {
    this.remove.emit();
  }

  onMultimediaError(event) {
    const error = event?.detail.plyr.media.error || {};
    this.logger.debug('Media player has errors: ', error);

    if (error.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
      this.showError();
    }
  }

  private showError() {
    const title = this.translate.instant('ERRORS_TOASTS.ERROR_TITLE');
    const msg = this.translate.instant('ERRORS_TOASTS.GENERIC_MSG');
    this.toastCtrl.showError(title, msg);
  }
}
