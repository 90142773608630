import { Component, Input, OnInit } from '@angular/core';

import { Logger, ModalManager, ToastService } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { CategoriesAnalytics, PagesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { PublicationContentType } from '@app/shared/enums/multimedia/content-status-types.enum';
import { AbstractMultimediaItem } from '@app/shared/models/multimedia/multimedia.abstract.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';

import { IShareItem, IShareSlide, ShareVias } from './share-item.model';
import { SHARE_ITEMS } from './share-modal.const';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit {
  @Input() multimediaItem!: AbstractMultimediaItem;

  linkToShare!: string;
  navigatorOn: MSafeAny;
  messages: MSafeAny;
  shareSlides: IShareSlide[] = [];
  shareItems: IShareItem[] = [];
  isPublic!: boolean;

  readonly itemsPerSlide = 3;

  private logger = new Logger('ShareModalComponent');
  private readonly pageAnalytics = PagesAnalytics.MODAL_SHARE_CONTENT;

  constructor(
    private toastService: ToastService,
    private modalManager: ModalManager,
    private translate: TranslateService,
    private analyticsService: AnalyticsService
  ) {
    this.navigatorOn = window.navigator;
    this.getTranslations();
  }

  async ngOnInit() {
    this.analyticsService.setPage(
      CategoriesAnalytics.CONTENT,
      CategoriesAnalytics.SHARE,
      this.multimediaItem.isPublic() ? PagesAnalytics.PUB_CONTENT : PagesAnalytics.PRIVATE_CONTENT
    );

    this.defineLinkToShare();
    this.shareItems = this.getShareItems();

    this.isPublic = this.multimediaItem.isPublic() as boolean;
  }

  getShareItems(): IShareItem[] {
    const items: IShareItem[] = [SHARE_ITEMS.getEmail()];

    if (this.multimediaItem.isPublic()) {
      items.push(SHARE_ITEMS.getFacebook());
      items.push(SHARE_ITEMS.getTwitter());
    }
    return items;
  }

  close() {
    this.modalManager.dismissModal();
  }

  shareVia(type: ShareVias) {
    const actions = {
      [ShareVias.EMAIL]: () => this.shareViaMail(),
      [ShareVias.FACEBOOK]: () => this.shareViaFacebook(),
      [ShareVias.TWITTER]: () => this.shareViaTwitter(),
      [ShareVias.LINK]: () => this.copyUrl()
    };
    actions[type]();
  }

  defineLinkToShare() {
    this.linkToShare =
      this.multimediaItem.content_type === PublicationContentType.NEWS
        ? (this.multimediaItem.url as string)
        : (this.multimediaItem.link as string);

    // fix to deep-links on some Android devices
    if (this.linkToShare && this.linkToShare[this.linkToShare.length - 1] !== '/') {
      this.linkToShare += '/';
    }
  }

  private getFacebookShareLink(link: string) {
    return `https://facebook.com/sharer/sharer.php?u=${link}`;
  }

  private getTwitterShareLink(title: string, link: string) {
    return `https://twitter.com/intent/tweet?text=${title} - ${link}`;
  }

  private shareViaMail() {
    location.href = `mailto: ${''} ?body=${this.linkToShare}`;
  }

  private shareViaFacebook() {
    window.open(this.getFacebookShareLink(this.linkToShare), '_blank');
  }

  private shareViaTwitter() {
    window.open(this.getTwitterShareLink(this.multimediaItem.title, this.linkToShare), '_blank');
  }

  private copyUrl() {
    this.navigatorOn.clipboard
      .writeText(this.linkToShare)
      .then(() => {
        this.toastService.showSuccess(this.messages['SHARE.COPY_SUCCESS'], '', 2000);
        this.modalManager.dismissModal();
      })
      .catch((error) => {
        this.logger.error(error);
      });
  }

  private getTranslations() {
    this.translate.get(['SHARE.COPY_SUCCESS']).subscribe((messages) => (this.messages = messages));
  }
}
