import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { Logger } from '@services/logger';
import { InternalRoute } from '@shared/helpers/route.helper';

export enum NavigationEvents {
  Push,
  PushRemove,
  Pop,
  SetRoot
}

export class NavigationEvent {
  constructor(public eventType: NavigationEvents, public internalRoute: InternalRoute | undefined) {}
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private readonly logger = new Logger('NavigationService');
  private subjectNavigationEvent: Subject<NavigationEvent> = new Subject();
  navigationEvent$: Observable<NavigationEvent> = this.subjectNavigationEvent.asObservable();

  navigate(navigationEvent: NavigationEvent) {
    this.logger.debug(`Requested navigation to: ${JSON.stringify(navigationEvent)}`);
    if (this.shouldCancelNavigation(navigationEvent)) {
      return;
    }

    this.subjectNavigationEvent.next(navigationEvent);
  }

  goBack(previous: string | null = null, previousParams?: MSafeAny) {
    let navEvent: NavigationEvent;

    if (previous !== null) {
      navEvent = new NavigationEvent(NavigationEvents.Push, {
        path: previous,
        navParams: previousParams
      });
      return this.navigate(navEvent);
    }
    navEvent = new NavigationEvent(NavigationEvents.Pop, undefined);
    this.navigate(navEvent);
    return null;
  }

  private shouldCancelNavigation(navigationEvent: NavigationEvent) {
    if (!navigationEvent) {
      return true;
    }

    const notPop = navigationEvent.eventType !== NavigationEvents.Pop;
    const invalidInternalRoute = !navigationEvent.internalRoute || !navigationEvent.internalRoute.path;
    return notPop && invalidInternalRoute;
  }
}
