import { Injectable } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ENV } from 'src/environments/environment';

/* eslint-disable @typescript-eslint/naming-convention */

@Injectable({
  providedIn: 'root'
})
export class ApiUrls {
  readonly model = {
    base: `${ENV.API_URL}/model`
  };

  readonly example = {
    route: `${ENV.API_URL}/example`,
    get: (itemId: MSafeAny) => `${ENV.API_URL}/example/${itemId}`,
    put: (itemId: MSafeAny) => `${ENV.API_URL}/example/${itemId}`,
    delete: (itemId: MSafeAny) => `${ENV.API_URL}/example/${itemId}`,
    like: (itemId: MSafeAny) => `${ENV.API_URL}/example/${itemId}/like`,
    views: (itemId: MSafeAny) => `${ENV.API_URL}/example/${itemId}/views`,
    sendToReview: (itemId: MSafeAny) => `${ENV.API_URL}/example/${itemId}/publish`,
    videoLoaded: (exampleId: MSafeAny) => `${ENV.API_URL}/example/${exampleId}/video-loaded`
  };
  readonly office = {
    headquarters: `${ENV.API_URL}/headquarters`,
    services: (headquarterId: number) => `${ENV.API_URL}/headquarters/${headquarterId}/services`,
    floors: (headquarterId: number) => `${ENV.API_URL}/headquarters/${headquarterId}/floors`,
    districts: (headquarterId: number) => `${ENV.API_URL}/headquarters/${headquarterId}/districts`,
    districtDetail: (headquarterId: number, districtId: number) =>
      `${ENV.API_URL}/headquarters/${headquarterId}/districts/${districtId}`,
    districtsTypes: (headquarterId: number) => `${ENV.API_URL}/headquarters/${headquarterId}/districts/types`,
    capacity: `${ENV.API_URL}/capacity`
  };

  readonly healthCheck = {
    get: `${ENV.API_URL}/health-check`
  };

  readonly search = {
    base: `${ENV.API_URL}/search`
  };

  readonly multimediaItem = {
    list: `${ENV.API_URL}/publication`,
    filters: `${ENV.API_URL}/publications/filters`,
    get: (itemId: MSafeAny) => `${ENV.API_URL}/publication/${itemId}`,
    getPublic: (itemId: MSafeAny) => `${ENV.API_URL}/publication/public/${itemId}`,
    views: (itemId: MSafeAny) => `${ENV.API_URL}/publication/${itemId}/views`,
    rating: (itemId: MSafeAny) => `${ENV.API_URL}/publication/${itemId}/rating`,
    like: (itemId: MSafeAny) => `${ENV.API_URL}/publication/${itemId}/like`
  };

  readonly comment = {
    comments: `${ENV.API_URL}/comment`,
    comments_highlighted: `${ENV.API_URL}/comment/highlighted`,
    get: (itemId: number) => `${ENV.API_URL}/comment/${itemId}`,
    delete: (itemId: MSafeAny) => `${ENV.API_URL}/comment/${itemId}`,
    update: (itemId: MSafeAny) => `${ENV.API_URL}/comment/${itemId}`
  };

  readonly token = {
    getRefreshToken: `${ENV.API_URL_USERS}/token/get-refresh`,
    renewToken: `${ENV.API_URL_USERS}/token/renew`
  };

  readonly user = {
    info: `${ENV.API_URL_USERS}/user/info`,
    segments: `${ENV.API_URL_USERS}/user/segments`,
    legalconditions: `${ENV.API_URL_USERS}/user/legal-conditions`,
    model_tour: `${ENV.API_URL_USERS}/user/model-tour`,
    editAvatar: `${ENV.API_URL_USERS}/user/image`,
    language: `${ENV.API_URL_USERS}/user/language`,
    language_database: `${ENV.API_URL_USERS}/user/language`,
    image_database: `${ENV.API_URL_USERS}/user/image`,
    database: `${ENV.API_URL_USERS}/user/info-updatable`,
    deviceId: `${ENV.API_URL_USERS}/user/device-id`,
    contact: `${ENV.API_URL}/contact`,
    notification: `${ENV.API_URL_NOTIFICATIONS}/notification`,
    notificationAction: (itemId: MSafeAny) => `${ENV.API_URL_NOTIFICATIONS}/notification/${itemId}`,
    representedUsers: `${ENV.API_URL_USERS}/user/represented-users`,
    conditions: `${ENV.API_URL_USERS}/user/conditions`,
    acceptNews: `${ENV.API_URL_USERS}/user/news`,
    personalInfo: `${ENV.API_URL_USERS}/user/personal-info`,
    deleteEmail: `${ENV.API_URL_USERS}/user/personal-info/email`,
    deletePhone: (phoneId: MSafeAny) => `${ENV.API_URL_USERS}/user/personal-info/phone/${phoneId}`,
    requestPhone: `${ENV.API_URL_USERS}/user/request-phone`,
    logout: `${ENV.API_URL_USERS}/user/logout`,
    user_configuration: `${ENV.API_URL_USERS}/user/configuration`,
    onBoarding: `${ENV.API_URL_USERS}/user/onboarding`
  };

  readonly term = {
    history: `${ENV.API_URL}/term/history`,
    trends: `${ENV.API_URL}/term/trends`
  };

  readonly videos = {
    getPrivate: (key: string) => `${ENV.API_URL}/video/private/${key}`,
    getPublic: (key: string) => `${ENV.API_URL}/video/public/${key}`
  };

  readonly translate = {
    translate: `${ENV.API_URL}/translate`
  };

  readonly audit = {
    route: `${ENV.API_URL}/audit`
  };

  readonly enterprise = {
    ios: `${ENV.API_URL}/ios-enterprise/code`
  };

  readonly geography = {
    provinces: `${ENV.API_URL}/geography/provinces`,
    cities: `${ENV.API_URL}/geography/cities`,
    concelhos: `${ENV.API_URL}/geography/concelhos`,
    street_types: `${ENV.API_URL}/ptrab/geography/street-types`
  };

  readonly sustainability = {
    paper_reception: `${ENV.API_URL}/ptrab/sustainability/disable-paper-reception`
  };

  readonly language = {
    get: `${ENV.API_URL}/languages/employee`,
    employee_certificates: `${ENV.API_URL}/languages/employee/certificates`,
    test_level: `${ENV.API_URL}/languages/levels`,
    languages: `${ENV.API_URL}/languages/languages`
  };

  readonly qualification = {
    base: `${ENV.API_URL}/qualifications/employee`,
    master: `${ENV.API_URL}/qualifications/params`,
    qualifications: `${ENV.API_URL}/qualifications`
  };

  readonly localPublication = {
    base: `${ENV.API_URL}/local-publication`,
    get: (itemId: number) => `${ENV.API_URL}/local-publication/${itemId}`,
    patch: (itemId: number) => `${ENV.API_URL}/local-publication/${itemId}`,
    delete: (itemId: number) => `${ENV.API_URL}/local-publication/${itemId}`,
    update: (itemId: number) => `${ENV.API_URL}/local-publication/${itemId}`,
    calendar: `${ENV.API_URL}/local-publication/calendar`
  };

  readonly staticText = {
    get: `${ENV.API_URL}/static-text`
  };

  readonly logisticAnualCalendar = {
    get: `${ENV.API_URL}/organiza2/v1/calendar`
  };

  readonly logistic_calendar = {
    pdf: `${ENV.API_URL}/organiza2/v1/pdf`
  };

  readonly hours_bag = {
    get: `${ENV.API_URL}/organiza2/v1/hours-bag`
  };

  readonly content = {
    onboarding: `${ENV.API_URL}/content/onboarding`
  };
}
