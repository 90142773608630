import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { Logger } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { PagesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { SearchService } from '@app/services/search/search.service';
import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { Platform } from '@ionic/angular';

import { SIZES } from './search-dropdown.enum';

const TWO_MILLISECONDS = 200;

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent implements OnInit {
  @Input() records: string[] = [];
  @Input() searchTerm!: string;
  @Input() active!: PAGES;
  @Input() searchbarInput!: EventEmitter<UIEvent>;
  @Input() pageAnalytics!: PagesAnalytics;

  @Output() search = new EventEmitter();
  @Output() remove = new EventEmitter();

  latestSearch = true;
  predictiveSearch = false;
  needMoreChars = false;
  activeSection!: string;
  predictiveSection!: string;
  searchHistory: string[] = [];

  private logger: Logger = new Logger('SearchDropdownComponent');

  constructor(
    private searchService: SearchService,
    private analyticsService: AnalyticsService,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.activeSection = this.searchService.getSection();
    this.loadPredictiveResults();
  }

  existRecords() {
    return this.records.length > 0 && !this.needMoreChars;
  }

  loadPredictiveResults() {
    let value: string;
    this.searchHistory = this.records;
    this.searchbarInput
      .pipe(
        debounceTime(TWO_MILLISECONDS),
        switchMap((event: MSafeAny) => {
          value = event.target.value;
          const searchValue = value.trim();
          if (searchValue && searchValue.length >= SIZES.MAX_CHARS_TO_SHOW_PREDICT) {
            return this.searchService.getPredictiveResults(this.buildParams(searchValue));
          }
          return of(null);
        })
      )
      .subscribe(
        (searchPredictive) => {
          if (searchPredictive) {
            this.needMoreChars = false;
            this.latestSearch = false;
            this.predictiveSearch = true;
            this.setPredictiveSectionDropdown();
            this.records = searchPredictive.map((record) => {
              return (record.phrase = record.phrase.replace(
                new RegExp(value || record.phrase, 'gi'),
                (match) => `<b>${match}</b>`
              ));
            });
          } else if (!value) {
            this.records = this.searchHistory;
            this.latestSearch = true;
            this.predictiveSearch = false;
            this.needMoreChars = false;
          } else {
            this.needMoreChars = true;
          }
        },
        (error) => {
          this.logger.error(error);
        }
      );
  }

  searchByHistory(term: string) {
    const termToSearch = this.cleanHTMLTags(term);
    this.search.emit(termToSearch);
  }

  searchByTerm(term: string) {
    this.search.emit(term);
  }

  removeHistory() {
    this.searchHistory = [];
    this.remove.emit();
  }

  get showHeader() {
    return this.latestSearch && this.existRecords();
  }

  get showPredictive() {
    return this.predictiveSearch && !this.needMoreChars;
  }

  get isTabletLandscape() {
    return this.platform.is('cordova');
  }

  trackByItems(item: string) {
    return item;
  }

  private buildParams(term) {
    return {
      section: this.activeSection,
      phrase: term
    };
  }

  private setPredictiveSectionDropdown() {
    return (this.predictiveSection = `FINDER.${this.activeSection.toUpperCase()}`);
  }

  private extractRecommendedAnalyticsTerms(phrase: string): string {
    const searchedMatch = phrase.match(new RegExp('<[^>]*>(.*)<[^>]*>')) as RegExpMatchArray;
    const searchedTerm = (searchedMatch?.length as number) > 1 && searchedMatch[1];
    const fullTerm = this.cleanHTMLTags(phrase);
    return `${searchedTerm}|${fullTerm}`;
  }

  private cleanHTMLTags(phrase: string): string {
    return phrase.replace(new RegExp('<[^>]*>', 'g'), '');
  }
}
