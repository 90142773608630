<app-outdated-browser-alert></app-outdated-browser-alert>

<ion-nav hideBackButton="true">
  <!-- Desktop -->
  <div class="grid-fixed desktop">
    <div class="row no-padding-vertical" align-items-center [class.finder__mode]="inFinderMode">
      <div size="auto" *ngIf="!inFinderMode" class="col no-padding-vertical avatar-notification-wrapper">
        <app-avatar
          *ngIf="userInfo"
          class="header-avatar"
          size="small"
          [userInfo]="userInfo"
          [hasNews]="newsItem.length > 0"
          [pendingNews]="pendingNews"
          (click)="displayProfileMenu($event)"
          (loadElement)="onLoadAvatar($event)"
          (destroy)="onDestroyAvatar()">
        </app-avatar>
        <app-notification-advice
          *ngIf="showNotifications()"
          [unreadNotificationsNumber]="getUnreadNotificationNumber()"
          (click)="displayNotifications($event)"
          class="header-notification-advice"></app-notification-advice>
      </div>
      <div #finderRef size="4" class="col no-padding-vertical global-finder">
        <ion-searchbar
          *ngIf="showSearchbar()"
          class="finder__input"
          [class.finder__mode]="inFinderMode"
          [placeholder]="'SEARCH' | translate"
          [(ngModel)]="searchTerm"
          (ionFocus)="focusOnFinder()"
          (ionBlur)="onFinderBlur()"
          (keydown.enter)="search($event.target.value)">
        </ion-searchbar>
      </div>
      <div *ngIf="!inFinderMode" size="auto" class="col no-padding-vertical header-toolbar">
        <app-menu
          app-tour-popover-directive
          (popoverClicked)="onPopoverClicked($event)"
          [popoverTitle]="'NEWS_POPOVER.TITLE_NEWS' | translate"
          [popoverDescription]="'NEWS_POPOVER.DESCRIPTION_NEWS_GENERAL' | translate"></app-menu>
      </div>
    </div>
  </div>
</ion-nav>
<app-search-dropdown
  *ngIf="searchResultsLoaded"
  [searchbarInput]="searchInput"
  [active]="active"
  [records]="searchHistory"
  [searchTerm]="searchTerm"
  [pageAnalytics]="pageAnalytics"
  (search)="search($event)"
  (remove)="removeLastSearches()"
  [ngStyle]="searchDropdownStyle">
</app-search-dropdown>

<div #backdrop *ngIf="inFinderMode" class="backdrop" [ngStyle]="backdropStyles"></div>
