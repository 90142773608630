/* eslint-disable id-denylist */
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { StreetType } from '../street-type/street-type.model';
import { Territory } from '../territory/territory.model';

/* eslint-disable @typescript-eslint/naming-convention */
export class Address {
  road_type: string;
  street_type!: StreetType | null;
  street: string;
  number: string;
  floor?: string;
  door?: string;
  province: Territory;
  city: Territory;
  postal_code: string;

  constructor(data: MSafeAny) {
    this.road_type = data?.road_type;
    this.street = data?.street;
    this.number = data?.number;
    this.floor = data?.floor;
    this.door = data?.door;
    this.province = new Territory(data?.province);
    this.city = new Territory(data?.city);
    this.postal_code = data?.postal_code;
  }

  setStreetType(types: StreetType[]) {
    this.street_type = new StreetType({
      code: this.road_type,
      type_name: types?.find((type) => type?.code === this.road_type)?.name
    });
  }
}

export class PortugueseAddress {
  id: string;
  street_description: string;
  locality: string;
  concelho: Territory;
  postal_code: string;

  constructor(data: MSafeAny) {
    this.id = data?.id;
    this.street_description = data?.street_description;
    this.locality = data?.locality;
    this.concelho = data?.concelho;
    this.postal_code = data?.postal_code;
  }
}
