import { Injectable } from '@angular/core';

import { ENV } from 'src/environments/environment';
/* eslint-disable @typescript-eslint/naming-convention */

@Injectable({
  providedIn: 'root'
})
export class ApiMotUrls {
  readonly schedule = {
    list: `${ENV.API_URL_MOT}/mot/v2/schedule`,
    monthly_observations: `${ENV.API_URL_MOT}/mot/v2/schedule/month-info`,
    weekly_observations: `${ENV.API_URL_MOT}/mot/v2/schedule/week-info`
  };
  readonly vacations = {
    list: `${ENV.API_URL_MOT}/mot/v3/vacations`,
    info: `${ENV.API_URL_MOT}/mot/v2/vacations/year-info`,
    pdf: `${ENV.API_URL_MOT}/mot/v2/vacations/pdf`,
    signed: `${ENV.API_URL_MOT}/mot/v3/signed-vacations`
  };
  readonly signed_timetables = {
    list: `${ENV.API_URL_MOT}/mot/v2/signed-timetables`
  };
  readonly manage_vacations = {
    proposals: `${ENV.API_URL_MOT}/mot/v3/vacations-proposal`,
    criteria: `${ENV.API_URL_MOT}/mot/v3/vacations-proposal/criteria`,
    calendar: `${ENV.API_URL_MOT}/mot/v3/vacations-proposal/season-calendar`,
    observations: `${ENV.API_URL_MOT}/mot/v3/vacations-proposal/observations`
  };
}
