import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { ErrorMsgEnum } from '@app/components/error-msg/error-msg-enum';
import { IrsService } from '@app/ptrab/services/irs/irs.service';
import { TwoFactorController } from '@app/ptrab/services/two-factor/two-factor.controller';
import {
  IrpfDataSection,
  IrpfDataSectionError,
  SECTIONS_IRPF
} from '@app/ptrab/shared/interfaces/irpf-section.interface';
import { IrsView } from '@app/ptrab/shared/models/irs-view.model';
import { IrsModel } from '@app/ptrab/shared/models/irs.model';
import { NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { LoadingService } from '@app/services/loading/loading.service';
import { PTRAB_PAGES } from '@app/shared/enums/pages/pages.enum';
import { getPtrabUrlFromMap } from '@app/shared/enums/pages/pages.urls';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';

import { IrpfViewFormatter } from '../../formatter/irpf-view.formatter';
import { IrpfSections } from '../../irpf.constants';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { CategoriesAnalytics, ActionsAnalytics } from '@app/services/analytics/models/analytics.enum';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-ptrab-my-data-pt',
  templateUrl: './my-data-pt.component.html',
  styleUrls: ['./my-data-pt.component.scss']
})
export class MyDataPtComponent implements OnChanges, OnDestroy {
  @Input() irs!: IrsModel;
  @Input() reloadData!: boolean;

  @Output() showInfoMessages = new EventEmitter<IrpfDataSectionError[]>();

  irsView!: IrsView;
  errorOcurred!: ErrorMsgEnum | null;
  preventLoadData!: boolean;
  hasChanges = false;
  sections = IrpfSections;
  irpfDataSections: IrpfDataSection[] = [];

  workerError!: IrpfDataSectionError | null;
  generalError!: IrpfDataSectionError | null;

  private irpfViewFormatter = new IrpfViewFormatter(this.translate, this.datePipe);
  private subscriptions = new Subscription();

  constructor(
    private irsService: IrsService,
    private translate: TranslateService,
    private navigationService: NavigationService,
    private datePipe: DatePipe,
    private twoFactorController: TwoFactorController,
    private loadingService: LoadingService,
    private analyticsService: AnalyticsService
  ) {
    this.initializeErrorHandling();
    this.subscribeToLangChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.irs && changes.irs.currentValue) {
      this.updateDataView();
    } else if (changes.reloadData && changes.reloadData.currentValue) {
      this.errorOcurred = null;
    }
  }

  async openDetail(title: string) {
    this.loadingService.show();
    const blocked = await this.twoFactorController.assertUserBlocked();
    await this.loadingService.hide();

    if (blocked) {
      return;
    }

    this.goToSection(title);
  }

  get hasProviderError(): boolean {
    return this.errorOcurred === ErrorMsgEnum.SERVER_ERROR || this.errorOcurred === ErrorMsgEnum.SERVICE_LOCKED;
  }

  get hasGeneralError() {
    return this.generalError;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.irsService.clearData();
  }

  trackByItems(item: IrpfDataSection) {
    return item;
  }

  private goToPage(page: PTRAB_PAGES, params: MSafeAny = {}) {
    const navEvent = new NavigationEvent(NavigationEvents.Push, {
      path: getPtrabUrlFromMap(page),
      navParams: params
    });
    this.navigationService.navigate(navEvent);
  }

  private getFormattedData() {
    if (!this.irs) {
      return;
    }
    this.irsView = this.irpfViewFormatter.formatPt(this.irs);
    this.formatSections();
  }

  private updateDataView() {
    this.getFormattedData();
    this.hasChanges = this.irsService.hasChanges();
    if (!this.hasChanges) {
      this.clearSectionErrors();
    }
  }

  private initializeErrorHandling() {
    this.subscriptions.add(
      this.irsService.onError$.subscribe((errorType) => {
        this.errorOcurred = errorType;
      })
    );
  }

  private clearSectionErrors() {
    this.workerError = null;
    this.formatSections();
  }

  private goToSection(title: string) {
    this.analyticsService.sendEvent(CategoriesAnalytics.CLICK, {
      [ActionsAnalytics.CLICKACTION]: CategoriesAnalytics[title]
    });

    const section = {
      WORKER: () => this.goToPage(PTRAB_PAGES.IRPF_WORKER_PT_PAGE),
      SPOUSE: () => this.goToPage(PTRAB_PAGES.IRPF_SPOUSE),
      DEPENDANTS: () => this.goToPage(PTRAB_PAGES.IRPF_DEPENDANTS),
      YOUNG_WITHHOLDING: () => this.goToPage(PTRAB_PAGES.IRPF_YOUNG_WITHHOLDING),
      ROOM_BENEFIT: () => this.goToPage(PTRAB_PAGES.IRPF_ROOM_BENEFIT)
    };

    return section[title]();
  }

  private formatSections() {
    this.irpfDataSections = [
      {
        data: this.irsView.worker,
        isVisible: true,
        sectionError: this.workerError,
        title: this.sections.WORKER,
        id: SECTIONS_IRPF.WORKER
      },
      {
        data: this.irsView.spouse,
        isVisible: true,
        sectionError: undefined,
        title: this.sections.SPOUSE,
        id: SECTIONS_IRPF.SPOUSE
      },
      {
        data: this.irsView.dependants,
        isVisible: true,
        sectionError: undefined,
        title: this.sections.DEPENDANTS,
        id: SECTIONS_IRPF.DEPENDANTS
      },
      {
        data: this.irsView.youngBenefit,
        isVisible: true,
        sectionError: undefined,
        title: this.sections.YOUNG_WITHHOLDING,
        emptyMsg: 'EMPLOYEE_PORTAL.WITHOUT_YOUNG_IRS_RECORDS',
        id: SECTIONS_IRPF.YOUNG_WITHHOLDING
      },
      {
        data: this.irsView.roomBenefit,
        isVisible: true,
        sectionError: undefined,
        title: this.sections.ROOM_BENEFIT,
        emptyMsg: 'EMPLOYEE_PORTAL.WITHOUT_ROOM_BENEFIT_RECORDS',
        id: SECTIONS_IRPF.ROOM_BENEFIT
      }
    ];
  }

  private subscribeToLangChange() {
    this.subscriptions.add(this.translate.onLangChange.subscribe(() => this.updateDataView()));
  }
}
