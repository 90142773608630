<div *ngIf="showAlert" class="outdated-browser-alert">
  <div class="grid-fixed">
    <div class="row">
      <div class="col" size="9">
        <img alt="{{ icon }}" src="assets/imgs/outdated-browser-version/{{ icon }}.svg" />
        <h1>{{ 'OUTDATED_BROWSER.HEADER' | translate }}</h1>
        <p *ngIf="minVersion">
          {{ 'OUTDATED_BROWSER.BODY' | translate : { browser: browser, version: version, minVersion: minVersion } }}
        </p>
        <p *ngIf="!minVersion">{{ 'OUTDATED_BROWSER.BODY_OTHERS' | translate }}</p>
      </div>
      <div size="3" class="col buttons-container">
        <button class="c-button secondary" (click)="dismiss()">{{ 'OUTDATED_BROWSER.CONTINUE' | translate }}</button>
      </div>
    </div>
  </div>
</div>
