import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalManager } from '@app/services';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { domChanges } from '@app/shared/utils/utils';
import { Observable } from 'rxjs';
import { last, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-content-modal',
  templateUrl: './content-modal.component.html',
  styleUrls: ['./content-modal.component.scss']
})
export class ContentModalComponent implements AfterViewInit {
  @ViewChild('contentWrapper') contentWrapper!: ElementRef;
  @Input() buttons!: Buttons[];
  @Input() hideClose = false;
  @Input() disableAnimation = false;
  @Input() contentLoad!: Observable<boolean>;
  @Input() hasFixedHeight = false;
  @Input() canShowErrors = false;
  @Input() darkMode!: boolean;
  @Output() closeModal = new EventEmitter();

  readonly buttonsHeight = 145;

  cssClassButtons!: string;

  constructor(private modalManager: ModalManager) {}

  async ngAfterViewInit() {
    if (window.outerWidth < 768 || this.hasFixedHeight) {
      return;
    }

    const selfHtml: HTMLElement = this.contentWrapper.nativeElement;
    const contentModal: HTMLElement = selfHtml.closest('app-content-modal') as HTMLElement;

    if (!contentModal || this.disableAnimation) {
      return;
    }

    const ionModal: HTMLElement = contentModal.closest('ion-modal') as HTMLElement;
    const wrapper = ionModal.shadowRoot?.querySelector('.modal-wrapper') as HTMLElement;

    wrapper.style.maxHeight = `300px`;

    if (this.canShowErrors) {
      return (wrapper.style.maxHeight = '600px');
    }

    if (this.contentLoad) {
      return this.contentLoad
        .pipe(
          takeWhile((loading) => loading, true),
          last()
        )
        .subscribe(() => {
          wrapper.style.maxHeight = '90%';
        });
    }
    wrapper.style.maxHeight = '75%'; // TODO Revisar esta parte cuando hagamos el refactor para quitar ionic
    // wrapper.style.maxHeight = await this.calculateHeight();
  }

  // This function checks if the content is rendered (When its height > 0) and then returns that height in pixels or %
  async calculateHeight(): Promise<string> {
    const content: HTMLElement = this.contentWrapper.nativeElement;
    let retries = 0;
    const maxRetries = 5;

    while (retries < maxRetries) {
      if (content.clientHeight !== 0) {
        break;
      }
      await domChanges(300);
      retries++;
    }

    const heightValue = content.clientHeight + this.buttonsHeight;
    let maxHeight = `${heightValue}px`;
    if (heightValue > window.outerHeight * 0.9) {
      maxHeight = '90%';
    }
    return maxHeight;
  }

  onCloseIconClick() {
    this.closeModal.emit();
    this.modalManager.dismissModal();
  }

  trackByItems(item: MSafeAny) {
    return item;
  }
}
