<div class="grid grid-lite error-message" *ngIf="errorData">
  <div class="row">
    <div class="col error-message__image ion-text-center" size="12">
      <img src="{{ errorData.image }}" />
    </div>
  </div>
  <div class="row">
    <div class="col error-message__title" size="12">
      <img class="warning" src="assets/imgs/icons/danger.svg" />
      <p class="u-text-medium">{{ errorData.title | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col error-message__description" size="12">
      <p class="u-p2 u-medium-grey">{{ errorData.description | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col error-message__actions" size="12">
      <button (click)="goBack()" class="c-button primary back-button">{{ 'GO_BACK' | translate }}</button>
    </div>
  </div>
</div>
