import { Component, Input } from '@angular/core';

import { ModalManager } from '@app/services/modal-manager/modal-manager';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { WIZARD_SECTIONS } from '@shared/constants/model/model-wizard.const';
import { IWizardSection } from '@shared/interfaces/model/model-wizard-section.interface';
import { objectClone } from '@shared/utils/utils';

@Component({
  selector: 'app-model-wizard',
  templateUrl: './model-wizard.component.html',
  styleUrls: ['./model-wizard.component.scss']
})
export class ModelWizardComponent {
  @Input() darkMode!: boolean;
  private readonly wizardSections: IWizardSection[] = objectClone(WIZARD_SECTIONS);

  currentSection: IWizardSection;
  sectionStep = 0;
  progressSection!: boolean[];
  buttons: Buttons[] = [];

  constructor(private modalManager: ModalManager) {
    this.currentSection = this.wizardSections[this.sectionStep];
    this.createProgressArray();
    this.buttons = this.getButtonsUpdated();
  }

  goNext() {
    if (this.sectionStep < this.wizardSections.length - 1) {
      this.progressSection[this.sectionStep] = true;
      this.sectionStep++;
      this.currentSection = this.wizardSections[this.sectionStep];
      this.buttons = this.getButtonsUpdated();
    } else {
      this.modalManager.dismissModal(true);
    }
  }

  goBack() {
    if (this.sectionStep >= 0) {
      this.progressSection[this.sectionStep - 1] = false;
      this.sectionStep--;
      this.currentSection = this.wizardSections[this.sectionStep];
      this.buttons = this.getButtonsUpdated();
    }
  }

  getButtonsUpdated(): Buttons[] {
    const buttons: Buttons[] = [
      {
        text: this.currentSection.next,
        type: 'primary',
        enabled: true,
        onClick: () => this.goNext()
      }
    ];

    if (this.currentSection.back) {
      buttons.unshift({
        text: this.currentSection.back,
        type: 'secondary',
        enabled: true,
        onClick: () => this.goBack()
      });
    }

    return buttons;
  }

  welcomeOrSectionClass(current: IWizardSection) {
    const cssClasses = !current.back ? 'first-image' : 'section-image';
    return `${cssClasses} ${this.currentSection.image_class}`;
  }

  trackByItems(item: boolean) {
    return item;
  }

  private createProgressArray() {
    this.progressSection = this.wizardSections
      .map((section) => {
        if (section.active !== null) {
          return section.active;
        }
      })
      .filter((value): value is boolean => value !== undefined);
    this.progressSection.shift();
  }
}
