import { Component, OnInit } from '@angular/core';

import { LanguageSelectModalComponent } from '@app/components/modals/language-select-modal/language-select-modal.component';
import { PROFILE_MENU_GROUPS } from '@app/components/profile-menu/profile-menu.constants';
import { ModalManager, NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { StorageService } from '@app/services/storage';
import { UserService } from '@app/services/user/user.service';
import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { getUrlFromMap } from '@app/shared/enums/pages/pages.urls';
import { ProfileMenuLink, STATUS } from '@app/shared/models/profile-menu/profile-menu-link.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { isNullOrUndefined } from '@app/shared/utils/utils';
import { PopoverController } from '@ionic/angular';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-more-popover',
  templateUrl: './more-popover.component.html',
  styleUrls: ['./more-popover.component.scss']
})
export class MorePopoverComponent implements OnInit {
  private readonly employeeLegalConditions: ProfileMenuLink[] = PROFILE_MENU_GROUPS.LEGAL_CONDITIONS_LIST.slice();
  private readonly employeeDeviceInfo: ProfileMenuLink[] = PROFILE_MENU_GROUPS.DEVICE_INFO_LIST;
  private readonly employeeChangeLanguage: ProfileMenuLink[] = PROFILE_MENU_GROUPS.PROFILE_LANGUAGE;
  private readonly employeeConfiguration: ProfileMenuLink[] = PROFILE_MENU_GROUPS.CONFIGURATION;
  private readonly OFFICESERVICES: ProfileMenuLink[] = PROFILE_MENU_GROUPS.OFFICESERVICES;

  employeeSections!: ProfileMenuLink[][];
  infoUserConfiguration!: boolean;
  storeOffice?: boolean;
  // eslint-disable-next-line
  infoPopover!: HTMLIonPopoverElement;

  constructor(
    private popoverCtrl: PopoverController,
    private navService: NavigationService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private modalManager: ModalManager,
    private storageService: StorageService
  ) {}

  async ngOnInit() {
    this.storeOffice = await this.storageService.getCheckOfficeService();
    await this.getEmployeeSections();
  }

  private async getEmployeeSections() {
    const userPermissions = await this.userService.getPermissions();

    const legalConditionsLinks: ProfileMenuLink[] = this.userService.hasAccessToPtrabSections()
      ? this.updateLinks(this.employeeLegalConditions, userPermissions)
      : this.updateLinks(this.employeeLegalConditions.slice(0, -2), userPermissions);

    this.getUserServiceOffice(legalConditionsLinks);
  }

  setEmployeeSections(legalConditionsLinks) {
    if (this.storeOffice) {
      this.employeeSections = [
        this.OFFICESERVICES.concat(this.employeeChangeLanguage)
          .concat(this.employeeDeviceInfo.concat(legalConditionsLinks))
          .concat(this.employeeConfiguration)
      ];
      this.OFFICESERVICES.filter((link) => link.page !== getUrlFromMap(PAGES.OFFICESERVICES)).forEach(
        (l) => (l.status = STATUS.NEW)
      );
    } else if (this.storeOffice === false) {
      this.employeeSections = [
        this.employeeChangeLanguage
          .concat(this.employeeDeviceInfo.concat(legalConditionsLinks))
          .concat(this.employeeConfiguration)
      ];
    } else {
      this.employeeSections = [
        this.employeeChangeLanguage.concat(this.employeeDeviceInfo.concat(legalConditionsLinks))
      ];
    }
  }

  updateLinks(links: ProfileMenuLink[], allowedPermissions: string[]): ProfileMenuLink[] {
    return links.filter((link) => {
      if (link.permission.length === 0) {
        return true;
      }

      return link?.permission?.some((permission) => allowedPermissions?.includes(permission));
    });
  }

  goToPage(item: ProfileMenuLink) {
    this.sendAnalyticsEvent(item.analyticsLabel as string);
    this.popoverCtrl.dismiss(item.page !== 'language');
    if (item.page === 'language') {
      this.changeLanguage();
      return;
    }
    const navEvent = new NavigationEvent(NavigationEvents.SetRoot, {
      path: item.page as string,
      navParams: { info: this.infoUserConfiguration }
    });
    this.navService.navigate(navEvent);
  }

  trackByItems(_: number, item: MSafeAny) {
    return item;
  }

  async changeLanguage() {
    const modalOpts = {
      component: LanguageSelectModalComponent,
      backdropDismiss: false,
      cssClass: 'base-modal confirmation-modal'
    };

    this.modalManager.openModal(modalOpts);
  }

  private sendAnalyticsEvent(eventLabel: string) {
    this.analyticsService.sendEvent(CategoriesAnalytics.SETTING, {
      [ActionsAnalytics.CLICKACTION]: eventLabel
    });
  }

  private getUserServiceOffice(legalConditionsLinks: ProfileMenuLink[]) {
    if (isNullOrUndefined(this.storeOffice)) {
      this.userService.getUserConfiguration().subscribe((datos) => {
        this.storeOffice = datos.headquartersSwitch;
        this.storageService.setCheckOfficeService(datos.headquartersSwitch);
      });
    }

    this.setEmployeeSections(legalConditionsLinks);
  }
}
