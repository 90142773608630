<app-content-modal [buttons]="buttons" [hideClose]="true" [darkMode]="darkMode">
  <div class="row">
    <h1>
      <img class="warning" src="assets/imgs/icons/danger.svg" />
      {{ 'ERROR_MESSAGES.ERROR_OCCURRED' | translate }}
    </h1>
  </div>
  <div class="row error-image"><img src="assets/imgs/no-results/empty.svg" /></div>
  <div class="row error-text">
    <p>{{ 'ERROR_MESSAGES.ERROR_UNEXPECTED' | translate }}</p>
  </div>
</app-content-modal>
