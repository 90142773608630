import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { TwoFactorAuthorization } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { CarTransfer } from '@app/ptrab/shared/models/car-transfer';
import { DirectDebit } from '@app/ptrab/shared/models/direct-debit';
import { Logger, ToastService } from '@app/services';
import { ErrorCodes, ErrorMessages } from '@app/services/error/error.model';
import { IDataResponse } from '@app/shared/interfaces/data/data.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackTypes } from '@ptrab/shared/enums/feedback.enum';
import { Garnishment, GarnishmentResponse } from '@ptrab/shared/models/garnishment';
import { Payslip } from '@ptrab/shared/models/payslip';

import { ApiPtrabUrls } from '../api/api.service';
/* eslint-disable @typescript-eslint/naming-convention */

@Injectable()
export class PayslipsService {
  private logger = new Logger('PayslipsService');
  private messages!: { [key: string]: string };
  private onError = new Subject<FeedbackTypes>();

  onError$ = this.onError.asObservable();
  currentStatus!: ErrorCodes | null;

  constructor(
    private apiUrls: ApiPtrabUrls,
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    this.getTranslations();
  }

  getPayslips(): Observable<Payslip[]> {
    return this.http.get<{ data: Payslip[] }>(this.apiUrls.payslip.list).pipe(
      map((rawData: { data: Payslip[] }) => rawData.data.map((data) => new Payslip(data))),
      catchError(this.handleError)
    );
  }

  /* istanbul ignore next */
  // eslint-disable-next-line
  getGarnishmentList(): Observable<Garnishment[]> {
    return this.http.get<IDataResponse<GarnishmentResponse[]>>(this.apiUrls.garnishment.list).pipe(
      map((rawData) => rawData.data.map((data) => new Garnishment(data))),
      catchError(this.handleError)
    );
  }

  /* istanbul ignore next */
  // eslint-disable-next-line
  getCarTransferList(): Observable<CarTransfer[]> {
    return this.http.get<IDataResponse<CarTransfer[]>>(this.apiUrls.carTransfer.list).pipe(
      map((arrayData) => arrayData.data.map((data) => new CarTransfer(data))),
      catchError(this.handleError)
    );
  }

  /* istanbul ignore next */
  // eslint-disable-next-line
  getDirectDebits(): Observable<DirectDebit[]> {
    return this.http.get<MSafeAny>(this.apiUrls.directDebit.list).pipe(
      map((rawData: { data: { companies: MSafeAny[] } }) =>
        rawData.data.companies.map((company) => new DirectDebit(company))
      ),
      catchError(this.handleError)
    );
  }

  /* istanbul ignore next */
  // eslint-disable-next-line
  async arePayslipsAvailable(showToast = true): Promise<boolean | null> {
    this.currentStatus = null;

    try {
      await this.http.get(this.apiUrls.payslip.status).toPromise();
    } catch (error: MSafeAny) {
      this.currentStatus = error?.status;

      switch (error?.status) {
        case ErrorCodes.LOCKED:
          if (showToast) {
            this.toastService.showWarning(
              this.messages['EMPLOYEE_PORTAL.MODIFICATION_NOT_AVAILABLE'],
              this.messages['EMPLOYEE_PORTAL.PAYSLIP_PROCESS_SOON']
            );
          }
          return false;
        case ErrorCodes.UNAUTHORIZED:
          return true;
        default:
          return null;
      }
    }
    return true;
  }

  /* istanbul ignore next */
  // eslint-disable-next-line
  calculateGarnishPayslip(company_code: string): Observable<MSafeAny> {
    return this.http.get(this.apiUrls.garnishment.calculate, { params: { company_code } });
  }

  /* istanbul ignore next */
  // eslint-disable-next-line
  async isDirectDebitCertificateAvaible(): Promise<boolean> {
    try {
      await this.http.get(this.apiUrls.directDebit.certificateStatus).toPromise();
    } catch (error: MSafeAny) {
      this.logger.error(error);

      if (error && error.status === ErrorCodes.NOT_FOUND) {
        this.toastService.showError(
          this.messages['ERRORS_TOASTS.GENERIC_MSG'],
          this.messages['EMPLOYEE_PORTAL.NO_CHANGE_IN_BANK_ACCOUNT']
        );
      }
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }

  /* istanbul ignore next */
  // eslint-disable-next-line
  modifyDirectDebit(directDebit: DirectDebit, authorization: TwoFactorAuthorization): Observable<MSafeAny> {
    return this.http.post(this.apiUrls.directDebit.list, {
      params: {
        company_code: directDebit.code,
        employee_number: directDebit.employee_number,
        bank_account: directDebit.account
      },
      authorization
    });
  }

  private handleError = (error: HttpErrorResponse): Observable<MSafeAny[]> => {
    let errorType;

    if (this.isEMPTY_RESULT(error)) {
      errorType = FeedbackTypes.EMPTY_RESULT;
    } else {
      switch (error.status) {
        case ErrorCodes.LOCKED:
          errorType = FeedbackTypes.SERVICE_LOCKED;
          break;
        case ErrorCodes.SECTION_MAINTENANCE:
          errorType = FeedbackTypes.SECTION_MAINTENANCE;
          break;
        default:
          errorType = FeedbackTypes.SERVER_ERROR;
          break;
      }
    }

    this.onError.next(errorType);
    this.logger.error(error);

    return of([]);
  };

  isEMPTY_RESULT(error: HttpErrorResponse) {
    if (!error.error) {
      return false;
    }

    return error.error.code === ErrorMessages.EMPTY_GARNISHMENT || error.error.code === ErrorMessages.EMPTY_PAYSLIPS;
  }

  private getTranslations() {
    this.translateService
      .stream([
        'EMPLOYEE_PORTAL.MODIFICATION_NOT_AVAILABLE',
        'EMPLOYEE_PORTAL.PAYSLIP_PROCESS_SOON',
        'EMPLOYEE_PORTAL.NO_CHANGE_IN_BANK_ACCOUNT',
        'ERRORS_TOASTS.GENERIC_MSG'
      ])
      .subscribe((messages) => {
        this.messages = messages;
      });
  }
}
