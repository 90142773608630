import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-data-section',
  templateUrl: './data-section.component.html',
  styleUrls: ['./data-section.component.scss']
})
export class DataSectionComponent {
  @HostBinding('class.c-data-section') hostClass = true;

  @Input() title!: string;
  @Input() actionText!: string;
  @Input() popover!: boolean;
  @Input() hideHeader = false;
  @Input() showDot = false;

  @Output() actionClick = new EventEmitter();
  @Output() showInfoPopover = new EventEmitter<Event>();

  constructor() {}

  showPopover(event: Event) {
    this.showInfoPopover.emit(event);
  }
}
