<div class="personal-income-tax-page">
  <app-spinner [showText]="true" *ngIf="isListLoading"></app-spinner>
  <ng-container *ngIf="!isListLoading && !hasProviderError">
    <app-error-display
      *ngIf="hasGeneralError"
      [title]="'EMPLOYEE_PORTAL.SECTION_ERROR' | translate"
      [error]="generalError"></app-error-display>
    <ng-container *ngFor="let irpfDataSection of irpfDataSections; trackBy: trackByItems">
      <app-ptrab-irpf-item
        *ngIf="irpfDataSection.isVisible"
        [data]="irpfDataSection.data"
        [title]="irpfDataSection.title"
        [sectionErrors]="irpfDataSection.sectionError"
        [hasChanges]="hasChanges"
        (modify)="openDetail(irpfDataSection.id)"></app-ptrab-irpf-item>
    </ng-container>
    <div class="download-section" (click)="downloadIrpfData()">
      <i class="app-icon-download-ep"></i>
      <span class="download-section__title u-ls">
        {{ 'EMPLOYEE_PORTAL.SEE_PIT_DATA' | translate }}
      </span>
    </div>
    <button app-no-double-click class="c-button primary" [disabled]="!hasChanges" (click)="sendData()">
      {{ 'EMPLOYEE_PORTAL.SEND_CHANGES' | translate }}
    </button>
  </ng-container>
  <div class="row" *ngIf="hasProviderError">
    <div class="col" size="12">
      <app-ptrab-feedback-msg [type]="errorOcurred"></app-ptrab-feedback-msg>
    </div>
  </div>
</div>
