<ng-template #ImageDetail>
  <article *ngIf="item" class="animated fadeIn">
    <h1>{{ item.internal_title || item.title }}</h1>
    <app-message-info
      *ngIf="canShowStatusInfo"
      [title]="statusInfoTitle | translate"
      [message]="this.item.status_info"></app-message-info>
    <div
      *ngIf="isPublicOrOfficialContent()"
      class="detail__info"
      [class.only-views]="!item?.likes_allowed"
      [ngPlural]="item.views">
      <div *ngIf="item?.likes_allowed" class="detail__info-likes">
        <span class="spacing">
          {{ item.likes | number }}
        </span>
        <span>{{ 'LIKES.COUNT' | translate | ucfirst }}</span>
      </div>
      <div class="detail__info-views">
        <span>
          {{ item.views | number }}
        </span>
        <span class="spacing">
          <ng-template ngPluralCase="=1">{{ 'VIEW' | translate | ucfirst }}</ng-template>
          <ng-template ngPluralCase="other">{{ 'VIEWS' | translate | ucfirst }}</ng-template>
        </span>
      </div>
    </div>

    <div class="slider-container">
      <app-slider #slider [image]="item.images" (setFullscreen)="openImage()"> </app-slider>
    </div>

    <app-multimedia-actions
      *ngIf="!isWebView"
      [item]="item"
      [analyticsCategory]="analyticsCategory"
      (commentsClickedEvent)="goToComments()"
      (sendLikeEvent)="like()"></app-multimedia-actions>

    <app-multimedia-detail-description
      #description
      *ngIf="item.text !== ''"
      class="description"
      [multimediaItem]="item">
    </app-multimedia-detail-description>

    <app-multimedia-detail-author
      *ngIf="!item.isOfficialContent()"
      [exampleItem]="item"
      [detailRenderMode]="detailRenderMode"
      class="author-info">
    </app-multimedia-detail-author>

    <app-comments-section
      *ngIf="item.isOfficialContent()"
      (scrollToTop)="goToComments($event)"
      [publicationItem]="item"
      [userPermission]="userCommentPermission"
      [mainContent]="mainContent"
      [userIsOwner]="userIsOwner"
      [userIsPresident]="userIsPresident"
      [analyticsCategory]="analyticsCategory">
    </app-comments-section>
  </article>
  <app-modal-placeholder *ngIf="isLoadingItem"></app-modal-placeholder>
</ng-template>

<ng-container *ngIf="isDetailRenderModeModal()">
  <app-content-modal [buttons]="draftButtons" (closeModal)="close()" [contentLoad]="contentLoad$">
    <ng-template [ngTemplateOutlet]="ImageDetail"></ng-template>
  </app-content-modal>
</ng-container>

<ng-container *ngIf="isDetailRenderModePage()">
  <ng-template [ngTemplateOutlet]="ImageDetail"></ng-template>
</ng-container>
