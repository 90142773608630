<div class="c-data-item__label">
  <span class="c-data-item__label-text u-p3 u-medium-grey">{{ label }}</span>
  <span *ngIf="!isPending && !hideAction" class="c-data-item__label-action u-ls" (click)="actionClick.emit()">
    <span *ngIf="actionIcon"> <ion-icon [name]="actionIcon"></ion-icon> </span>{{ actionText }}</span
  >
  <app-badge-info
    *ngIf="isPending && !hideAction"
    class="status--pending"
    [title]="'EMPLOYEE_PORTAL.PENDING'"></app-badge-info>
</div>
<ng-container *ngIf="date; else oneCol">
  <div class="row">
    <div class="col col-2 no-padding" size="2">
      <p class="c-data-item__value u-h7 u-medium-grey">{{ value }}</p>
    </div>
    <div class="col col-2 no-padding" size="2">
      <p class="c-data-item__value u-h7 u-medium-grey">
        {{ date | date : 'dd/MM/yyyy' }}
      </p>
    </div>
    <div class="col col-8 no-padding" size="8"></div>
  </div>
</ng-container>
<ng-template #oneCol>
  <p class="c-data-item__value u-h7 u-medium-grey">{{ value }}</p>
</ng-template>
<ng-content></ng-content>
