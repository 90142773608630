import { Component, Input, ViewEncapsulation } from '@angular/core';

import { Filter } from '@app/shared/models/filters/filters';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-filter-popover',
  templateUrl: './filter-popover.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./filter-popover.component.scss']
})
export class FilterPopoverComponent {
  @Input() filterList!: Filter[];
  @Input() title!: string;

  constructor(private popoverController: PopoverController) {}

  sendFilter(filter: Filter) {
    this.popoverController.dismiss(filter);
  }

  trackByItems(item: Filter) {
    return item;
  }
}
