import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

import { AuditCodes, AuditLevels, AuditMessage } from '@app/shared/models/audit-message/audit-message';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { decodeResponse } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { AuditService } from '@services/audit/audit.service';
import { ToastService } from '@services/toast/toast.service';

import { genericErrors } from './handler-error.model';
import { ErrorCodes } from '../error/error.model';

@Injectable({
  providedIn: 'root'
})
export class HandlerErrorService {
  private errorTitle!: string | null;

  constructor(
    private auditService: AuditService,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {}

  handleError(error: MSafeAny, request: HttpRequest<MSafeAny>): Observable<never> {
    if (error && error.status !== 418 && request.url.indexOf('/audit') === -1) {
      // infinite loop when '/audit' XHR failed
      // don't audit maintenance status error
      this.auditService.push(
        new AuditMessage({
          level: error.status === ErrorCodes.SECTION_MAINTENANCE ? AuditLevels.Info : AuditLevels.Error,
          message: `handleError: handler error service ${JSON.stringify(error)} from url ${request.url}`,
          status_code: error.status === ErrorCodes.SECTION_MAINTENANCE ? AuditCodes.Maintenance : AuditCodes.Error,
          app_component: 'HandlerErrorService'
        })
      );
      this.checkError(error);
      return throwError(error);
    }
    return of();
  }

  setErrorTitle(title: string | null) {
    this.errorTitle = title;
  }

  private checkError(error: MSafeAny) {
    error = decodeResponse(error);
    const { code, details } = error.error;

    const errorOcurred = Object.keys(genericErrors).find((errorCode) => code === errorCode);

    if (errorOcurred) {
      const toastError = details || error.error;
      const toastTitle = this.errorTitle || this.translateService.instant('EMPLOYEE_PORTAL.FAIL_MODIFY_PIT_DATA.TITLE');
      const errorToShow = genericErrors[errorOcurred];
      this.toastService.showError(toastTitle, this.translateService.instant(errorToShow, toastError));
    }

    this.setErrorTitle(null);
  }
}
