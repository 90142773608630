import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ModalManager } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics, PagesAnalytics } from '@app/services/analytics/models/analytics.enum';

import { PublicationFilterComponent } from '../publication-filter.component';

export interface PublicationFilterResponse {
  filterIds: number[];
  initialFilterIds: number[];
  saveChanges: boolean;
  allSelected: boolean;
}

@Component({
  selector: 'app-publication-filter-button',
  templateUrl: './publication-filter-button.component.html',
  styleUrls: ['./publication-filter-button.component.scss']
})
export class PublicationFilterButtonComponent {
  @Input() selectedFilterIds: number[] = [];
  @Input() allFiltersSelected = false;
  @Output() filtersChanged = new EventEmitter<PublicationFilterResponse>();

  _disabled!: boolean;

  constructor(private modalManager: ModalManager, private analyticsService: AnalyticsService) {}

  @Input()
  set disabled(disable: boolean) {
    this._disabled = disable;
  }

  openPublicationFilters() {
    if (this._disabled) {
      return;
    }

    this.analyticsService.sendEvent(CategoriesAnalytics.CLICK, {
      [ActionsAnalytics.CLICKACTION]: PagesAnalytics.FILTER_PUBLICATIONS
    });

    this.modalManager
      .openModal({
        component: PublicationFilterComponent,
        componentProps: { selectedFilterIds: this.selectedFilterIds, allFiltersSelected: this.allFiltersSelected },
        backdropDismiss: false,
        cssClass: 'base-modal'
      })
      .then((response: PublicationFilterResponse) => {
        if (response.saveChanges) {
          this.filtersChanged.emit(response);
        } else {
          this.selectedFilterIds = response.initialFilterIds;
        }
      });
  }
}
