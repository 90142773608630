<!-- Google Tag Manager (noscript) -->
<noscript>
  <iframe
    [src]="analyticsUrl"
    height="0"
    width="0"
    style="display: none; visibility: hidden"
  ></iframe>
</noscript>
<!-- End Google Tag Manager (noscript) -->

<ion-app>
  <ion-router-outlet id="main" [swipeGesture]="activeSwipeGesture"></ion-router-outlet>

  <app-lightbox-cookies *ngIf="showLightBoxCookies()" (action)="manageActionCookies($event)"></app-lightbox-cookies>
</ion-app>
