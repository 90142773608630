import { Component, HostListener, Input, OnInit, HostBinding } from '@angular/core';

import { ModalManager } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { PublicationContentType } from '@app/shared/enums/multimedia/content-status-types.enum';
import { AbstractMultimediaItem } from '@app/shared/models/multimedia/multimedia.abstract.model';
import { ModalOptions } from '@ionic/core';

import { ShareModalComponent } from '../modals/share-modal/share-modal.component';
/* eslint-disable @typescript-eslint/naming-convention */

enum SharedButtonColors {
  Black = 'black',
  White = 'white',
  Green = 'green'
}

@Component({
  selector: 'app-share-content',
  templateUrl: './share-content.component.html',
  styleUrls: ['./share-content.component.scss']
})
export class ShareContentComponent implements OnInit {
  @Input() item!: AbstractMultimediaItem;
  @Input() @HostBinding('class.is-detail') isDetail!: boolean;
  @Input() shareTitle!: string;
  @Input() analyticsCategory!: string;

  cssIconColor!: string;

  private readonly SHARE_BUTTON_COLOR = {
    [PublicationContentType.IMAGE]: SharedButtonColors.White,
    [PublicationContentType.NEWS]: SharedButtonColors.White,
    [PublicationContentType.TEXT]: SharedButtonColors.White,
    [PublicationContentType.VIDEO]: SharedButtonColors.White
  };

  constructor(private modalManager: ModalManager, private analyticsService: AnalyticsService) {}

  @HostListener('click', ['$event'])
  openShareModal(event) {
    event.stopPropagation();
    this.analyticsService.sendEvent(CategoriesAnalytics.SHARE, {
      [ActionsAnalytics.CLICKACTION]: this.item.getAnalyticsLabel()
    });

    const modalOpts: ModalOptions = {
      component: ShareModalComponent,
      componentProps: { multimediaItem: this.item },
      backdropDismiss: true,
      cssClass: 'share-modal'
    };

    this.modalManager.openModal(modalOpts);
  }

  ngOnInit() {
    this.cssIconColor = this.getCssIconColor();
  }

  private getCssIconColor() {
    return this.isDetail ? SharedButtonColors.Green : this.SHARE_BUTTON_COLOR[this.item.content_type];
  }
}
