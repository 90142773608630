<app-header [text]="title | translate" [scrollArea]="myContent" [showTitleOnly]="true" (back)="cancelEdition()">
</app-header>

<app-content #myContent class="data-edition">
  <div class="grid-fixed grid-lite data-edition__grid">
    <div class="row">
      <div class="col" size="12">
        <app-error-display
          *ngIf="hasError"
          [title]="'EMPLOYEE_PORTAL.SECTION_ERROR' | translate"
          [error]="errors"></app-error-display>
      </div>
    </div>
    <div class="row d-none d-md-block">
      <div class="col">
        <app-title-section [text]="title | translate" (back)="cancelEdition()"></app-title-section>
      </div>
    </div>
    <div class="row">
      <div class="col" size="12">
        <ng-content></ng-content>
        <div class="data-edition__buttons d-sm-flex d-none">
          <button
            app-no-double-click
            class="data-edition__confirm c-button primary"
            (click)="continueEdition()"
            [disabled]="disabled">
            {{ continueButtonLabel | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</app-content>
