<ng-container *ngIf="user">
  <app-avatar size="small" [userInfo]="user"></app-avatar>
    <button class="edit-image" (click)="fileInput.click()">
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
    <input #fileInput type="file" hidden [accept]="acceptedFileExtensions" (change)="onChangeAvatar($event)" />
</ng-container>

<ng-template #buttonContent>
  <img src="assets/imgs/profile/edit-photo.svg" alt="edit image" />
</ng-template>
