export enum TwoFactorError {
  VALIDATION_CODE_INVALID = 'activo2-010',
  VALIDATION_CODE_EXPIRED = 'activo2-011',
  VALIDATION_CODE_MAX_TRIES_EXCEEDED = 'activo2-012',
  VALIDATION_CODE_USER_BLOCKED = 'activo2-013'
}

export enum TwoFactorOperationCode {
  LOGIN_2FA = 'PTRAB_2FA_LOGIN',
  DIRECT_DEBIT = 'PTRAB_UPDATE_DIRECT_DEBIT',
  WITHHOLDING = 'PTRAB_UPDATE_WITHHOLDING',
  PIT_DATA = 'PTRAB_UPDATE_PERSONAL_INCOME_TAX',
  PERCENTAGE_READJUSTMENT = 'PTRAB_DEMAND_NAVARRA_REGULARIZATION',
  USER_UPDATE_PERSONAL_INFO = 'USER_UPDATE_PERSONAL_INFO',
  DIRECT_DEBIT_CERTIFICATE = 'PTRAB_GET_DIRECT_DEBIT_CERTIFICATE',
  WAGE_CHARGE_CERTIFICATE = 'PTRAB_GET_WAGE_CHARGE_CERTIFICATE',
  REQUEST_OTHER_PHONE = 'PTRAB_REQUEST_OTHER_PHONE',
  REQUEST_OTHER_PHONE_DF = 'PTRAB_REQUEST_OTHER_PHONE_DISCONTINOUS_FIXED'
}

export enum TwoFactorValidationStatus {
  SUCCESS,
  ERROR,
  CANCELLED,
  USER_BLOCKED
}

export interface TwoFactorValidationResult {
  status: TwoFactorValidationStatus;
  hash?: string;
}

export enum TwoFactorUserStatus {
  ALLOWED = 'ALLOWED',
  BLOCKED = 'BLOCKED'
}

export interface TwoFactorUserStatusResponse {
  status: TwoFactorUserStatus;
  remaining_time: number;
}

export interface TwoFactorAuthorization {
  user_id?: string;
  validation_hash: string;
}

export interface TwoFactorResponse {
  code: {
    code: string;
    timestamp: string;
    tries_count: number;
  };
  blocking_time: number;
}
