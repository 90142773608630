import { Component, OnInit, Input, HostBinding } from '@angular/core';

import { NewsTypes } from '@app/shared/enums/news/news-types.enum';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-news-popover',
  templateUrl: './news-popover.component.html',
  styleUrls: ['./news-popover.component.scss']
})
export class NewsPopoverComponent implements OnInit {
  @Input() newsType!: string;

  constructor(private popoverCtrl: PopoverController) {}

  @HostBinding('class.is-legal-conditions') isLegalConditions!: boolean;

  ngOnInit() {
    this.isLegalConditions = this.newsType === NewsTypes.LEGAL_CONDITIONS;
  }

  close() {
    this.popoverCtrl.dismiss();
  }
}
