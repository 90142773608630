<app-data-section
  [title]="title | translate"
  [actionText]="'EMPLOYEE_PORTAL.MODIFY' | translate"
  (actionClick)="modifySection()">
  <i
    *ngIf="hasError"
    class="header-left-item app-icon-alert"
    (click)="showPopover($event)"
    (mouseover)="openInfo($event)"></i>
  <ng-container *ngFor="let item of dataToload; trackBy: trackByItems">
    <app-data-item [label]="item.label" [value]="item.value" *ngIf="item.value"></app-data-item>
  </ng-container>
  <app-data-item
    *ngIf="!hasDataToLoad"
    [label]="
      emptyMsg
        ? (emptyMsg | translate)
        : ('EMPLOYEE_PORTAL.WITHOUT_RECORDS' | translate) + ' ' + (title | translate | lowercase)
    "></app-data-item>
</app-data-section>
