import { Injectable } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { Platform, ToastController } from '@ionic/angular';
/* eslint-disable @typescript-eslint/naming-convention */

enum ToastTypes {
  success = 'success-toast',
  info = 'info-toast',
  alert = 'alert-toast',
  error = 'error-toast',
  simple = 'simple-toast'
}

enum ToastPositions {
  top = 'top',
  bottom = 'bottom',
  middle = 'middle'
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private static readonly toastDefaultDuration = 5000;
  private static COMMON_STYLE = `
    .toast-message {
      margin: 30px 0 20px 6px;
      color: var(--medium-grey);
      font-size: 12px;
      line-height: 1.5;
    }

    .toast-message ul {
      padding-left: 15px;
    }

    .toast-button {
      position: absolute !important;
      right: 5px;
      top: 9px;
    }

    .toast-button::after {
      content: url("assets/imgs/toast/close.svg")
    }

    .toast-button:hover {
      background: transparent !important;
    }

    .toast-button ion-ripple-effect {
      display: none;
    }

    .toast-content::after {
      top: 15px;
      left: 22px;
      position: absolute;
      height: 20px;
      width: 20px;
    }

  `;

  // eslint-disable-next-line
  private toast!: HTMLIonToastElement;

  constructor(private toastCtrl: ToastController, private platform: Platform) {}

  showSuccess(title: MSafeAny, description: MSafeAny, duration = ToastService.toastDefaultDuration) {
    this.show(title, description, ToastTypes.success, duration);
  }

  showInfo(title: MSafeAny, description: MSafeAny, duration = ToastService.toastDefaultDuration) {
    this.show(title, description, ToastTypes.info, duration);
  }

  showWarning(title: MSafeAny, description: MSafeAny, duration = ToastService.toastDefaultDuration) {
    this.show(title, description, ToastTypes.alert, duration);
  }

  showError(title: MSafeAny, description: MSafeAny, duration = ToastService.toastDefaultDuration) {
    this.show(title, description, ToastTypes.error, duration);
  }

  async showSimpleToast(message: string, duration = ToastService.toastDefaultDuration) {
    // eslint-disable-next-line
    const toast: HTMLIonToastElement = await this.toastCtrl.create({
      message,
      duration,
      cssClass: 'simple-toast',
      position: 'middle'
    });

    const styles = `
      .toast-message {
        margin: 0;
        color: var(--secondary-675);
        font-size: 14px;
        font-weight: var(--gotham-medium);
        line-height: 1.14;
      }
    `;
    this.patchToastStyles(toast, styles);
    toast.present();
  }

  dismiss(): Promise<boolean> {
    if (this.toast) {
      return this.toast.dismiss();
    }
    return Promise.resolve(true);
  }

  private show(
    title: MSafeAny,
    description: MSafeAny,
    toastType: ToastTypes,
    duration = ToastService.toastDefaultDuration
  ) {
    const formatedMessage = this.formatMessage(description);
    const position: ToastPositions = this.platform.is('cordova') ? ToastPositions.bottom : ToastPositions.top;
    this.generateToast(title, formatedMessage, toastType, duration, position);
  }

  private formatMessage(description: MSafeAny) {
    const lineJump = '\n';
    const message = `${lineJump}${description}`;
    return message;
  }

  private async generateToast(
    title: string,
    message: string,
    toastType: ToastTypes,
    duration: number,
    position: ToastPositions
  ) {
    const buttons = [
      {
        text: ' ',
        role: 'cancel'
      }
    ];
    this.toast = await this.toastCtrl.create({
      message,
      duration,
      position,
      cssClass: toastType,
      buttons
    });

    ToastService.COMMON_STYLE += `
    .toast-content::after {
      content: url("assets/imgs/toast/${toastType}.svg")
    }

    .toast-message::before {
      content: "${title}";
      display: block;
      color: var(--secondary-850);
      font-size: 17px;
    }
  `;
    this.patchToastStyles(this.toast);
    await this.dismiss();
    this.toast.present();
  }

  /**
   * @description Patch Toast Styles through Shadow Dom
   * TODO: Right now is impossible to change some styles since Ionic does not export the desired CSS Vars:
   *     - https://github.com/ionic-team/ionic/issues/18899
   *     - https://forum.ionicframework.com/t/ionic-v4-shadow-dom/137350
   *     - https://stackoverflow.com/questions/47625017/override-styles-in-a-shadow-root-element
   */
  // eslint-disable-next-line
  private patchToastStyles(toast: HTMLIonToastElement, styles: string = ToastService.COMMON_STYLE) {
    const style = document.createElement('style');
    style.innerHTML = styles;
    toast.shadowRoot?.appendChild(style);
  }
}
