<app-content-modal [buttons]="buttons" [hideClose]="maxTriesExceeded" [canShowErrors]="true">
  <h1>{{ 'EMPLOYEE_PORTAL.ENTER_CODE' | translate }}</h1>
  <p class="base-modal__description" [innerHtml]="'EMPLOYEE_PORTAL.SENT_SMS_WITH_CODE' | translate"></p>
  <div class="base-modal__image image-code-validation"></div>

  <form class="code-validation-form" [formGroup]="form" *ngIf="this.showCodeValidationForm()">
    <app-input-wrapper
      id="code"
      [label]="'EMPLOYEE_PORTAL.WRITE_CODE'"
      [errorMsg]="'EMPLOYEE_PORTAL.ENTERED_CODE_NOT_CORRECT'"
      [hasError]="isCodeWrong">
      <input
        id="codeInput"
        class="form-input"
        formControlName="code"
        minlength="6"
        maxlength="6"
        type="text"
        (keydown.enter)="validateCode()" />
    </app-input-wrapper>
  </form>

  <app-message-error
    *ngIf="maxTriesExceeded"
    [title]="'EMPLOYEE_PORTAL.MAX_TRIES_EXCEEDED' | translate"
    [message]="'EMPLOYEE_PORTAL.MAX_TRIES_EXCEEDED_DESCRIPTION' | translate : { minutes: remainingMinutes }"
    type="error">
  </app-message-error>
  <app-message-error
    *ngIf="expiredCode"
    class="mb-0"
    [title]="'EMPLOYEE_PORTAL.EXPIRED_CODE' | translate"
    [message]="'EMPLOYEE_PORTAL.GET_NEW_CODE' | translate"
    type="alert"></app-message-error>

  <p class="mb-0" *ngIf="!maxTriesExceeded">
    <span>
      {{ 'EMPLOYEE_PORTAL.CODE_RECEIVED' | translate }}
    </span>
    <button class="c-button link" [disabled]="!sendAgainAvailable" (click)="resendCode()">
      {{ 'EMPLOYEE_PORTAL.SEND_AGAIN' | translate }}
    </button>
    <span *ngIf="!sendAgainAvailable">
      {{ 'EMPLOYEE_PORTAL.AFTER_TIMER' | translate }}
      {{ timeRemaining$ | async | date : 'mm:ss' }}
    </span>
  </p>
</app-content-modal>
