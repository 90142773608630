export interface Filter {
  value?: string;
  label?: string;
  icon?: string;
  subSections?: Filter[];
  isOpened?: boolean;
}

export enum FilterTypes {
  EXAMPLE = 'ExampleFilters',
  PUBLICATION = 'PublicationFilters'
}

export enum PopoverTitles {
  EXAMPLE = 'MODEL_PAGES.UNIVERSAL_TRUTHS.MENU.SECTIONS'
}

export interface PublicationFilter {
  filter_id: number;
  filter_name: string;
  filter_image: string;
  subfilters?: PublicationFilter[];
  result_number: number;
  selected?: boolean;
}
