import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { FeedbackTypes } from '@app/ptrab/shared/enums/feedback.enum';
import { MaritalStatus } from '@app/ptrab/shared/enums/irpf.enum';
import { IrpfDataResponse } from '@app/ptrab/shared/interfaces/irpf-data-response.interface';
import { IrpfDataPostResponse } from '@app/ptrab/shared/interfaces/irpf-section.interface';
import { TwoFactorAuthorization } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { IrpfModel, PensionPayment, Worker } from '@app/ptrab/shared/models/irpf.model';
import { Ancestor, Descendant } from '@app/ptrab/shared/models/worker-relative';
import { Logger } from '@app/services';
import { ErrorCodes } from '@app/services/error/error.model';
import { IDataResponse } from '@app/shared/interfaces/data/data.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

import { ApiPtrabUrls } from '../api/api.service';
import { ConfirmExitService } from '../confirm-exit/confirm-exit.service';

@Injectable({
  providedIn: 'root'
})
export class IrpfService {
  irpfFormattedData!: IrpfModel | null;

  private irpfEditedData!: IrpfModel | null;
  private irpfOriginalData!: IrpfDataResponse | null;

  private logger = new Logger('IrpfService');
  private onError = new Subject<FeedbackTypes>();
  private updateIrpfViewSubject = new BehaviorSubject<IrpfModel>(new IrpfModel({} as IrpfDataResponse));

  onError$ = this.onError.asObservable();
  onUpdateIrpfView$ = this.updateIrpfViewSubject.asObservable();

  constructor(
    private apiUrls: ApiPtrabUrls,
    private http: HttpClient,
    private confirmExitService: ConfirmExitService,
    private translate: TranslateService
  ) {
    this.addWindowListener();
  }

  getData(): Observable<IrpfModel> {
    return this.http.get<IDataResponse<IrpfDataResponse>>(this.apiUrls.personalIncomeTax.data).pipe(
      map((d) => d.data),
      tap((irpfDataResponse: IrpfDataResponse) => this.setIrpfData(irpfDataResponse)),
      map(() => this.irpfEditedData as IrpfModel),
      catchError((error: HttpErrorResponse) => {
        const errorType =
          error && error.status === ErrorCodes.LOCKED ? FeedbackTypes.SERVICE_LOCKED : FeedbackTypes.SERVER_ERROR;
        this.onError.next(errorType);
        this.logger.error(error);

        return throwError(error);
      })
    );
  }

  modifyIrpfData(authorization: TwoFactorAuthorization): Observable<IrpfDataPostResponse> {
    return this.http
      .post<IrpfDataPostResponse>(this.apiUrls.personalIncomeTax.data, {
        params: this.getFormattedDataForBack(),
        authorization
      })
      .pipe(tap((response: IrpfDataPostResponse) => this.setIrpfData(response.data)));
  }

  getIrpfEditedData(): IrpfModel | null {
    return this.irpfEditedData;
  }

  getFormattedDataForBack(): IrpfDataResponse {
    const updatedData: MSafeAny = cloneDeep(this.irpfOriginalData);

    updatedData.alimony = this.irpfEditedData?.pensionPayment.alimony;
    updatedData.childAllowance = this.irpfEditedData?.pensionPayment.childAllowance;
    updatedData.courtRulingDate = this.irpfEditedData?.pensionPayment.courtRulingDate;

    updatedData.disability = this.irpfEditedData?.worker.disability;
    updatedData.maritalStatus = this.irpfEditedData?.worker.maritalStatus;
    updatedData.reducedMobility = this.irpfEditedData?.worker.reducedMobility;
    updatedData.spouseNif = this.irpfEditedData?.worker.spouseNif;
    updatedData.geographicMobilityDate = this.irpfEditedData?.worker.geographicMobilityDate;
    updatedData.i18nTexts = this.irpfEditedData?.i18nTexts;

    updatedData.mortgageReduction = this.irpfEditedData?.mortgageReduction;
    updatedData.ceutaMelillaResidence = this.irpfEditedData?.ceutaMelillaResidence;
    updatedData.descendants = this.irpfEditedData?.descendants;
    updatedData.ancestors = this.irpfEditedData?.ancestors;

    delete updatedData.minimumChildrenAge;
    delete updatedData.childrenIncomeCondition;

    this.updateMaritalStatus(updatedData);

    return updatedData;
  }

  setMortgageReduction(value: boolean) {
    if (this.irpfEditedData) {
      this.irpfEditedData.mortgageReduction = value;
    }
    this.updateIrpfView();
  }

  setCeutaMelillaResidence(value: boolean) {
    if (this.irpfEditedData) {
      this.irpfEditedData.ceutaMelillaResidence = value;
    }
    this.updateIrpfView();
  }

  setDescendants(descendants: Descendant[]) {
    if (this.irpfEditedData) {
      this.irpfEditedData.descendants = descendants;
    }
    this.updateIrpfView();
  }

  setWorkerInfo(value: Worker) {
    if (this.irpfEditedData) {
      this.irpfEditedData.worker = value;
    }
    this.updateIrpfView();
  }

  setAncestors(value: Ancestor[]) {
    if (this.irpfEditedData) {
      this.irpfEditedData.ancestors = value;
    }
    this.updateIrpfView();
  }

  setPensionPayment(value: PensionPayment) {
    if (this.irpfEditedData) {
      this.irpfEditedData.pensionPayment = value;
    }
    this.updateIrpfView();
  }

  hasChanges(): boolean {
    if (this.irpfFormattedData && this.irpfEditedData) {
      return !isEqual(this.irpfFormattedData, this.irpfEditedData);
    }
    return false;
  }

  clearData() {
    this.irpfOriginalData = null;
    this.irpfEditedData = null;
    this.irpfFormattedData = null;
    this.updateIrpfView();
  }

  dismissChanges() {
    this.irpfEditedData = cloneDeep(this.irpfFormattedData);
    this.updateIrpfView();
  }

  addWindowListener() {
    this.confirmExitService.setCloseWindowListener(this.setChangesAlert);
  }

  removeWindowListener() {
    this.confirmExitService.removeCloseWindowListener(this.setChangesAlert);
  }

  async confirmChangeDismiss(hasChanges: boolean, browserAlert?: (event: MSafeAny) => void, isLeavingIrpf = true) {
    const canLeave = await this.confirmExitService.confirmChangeDismiss(hasChanges, browserAlert);
    if (canLeave) {
      this.removeWindowListener();

      if (isLeavingIrpf) {
        this.dismissChanges();
      }
    } else {
      this.addWindowListener();
    }
    return canLeave;
  }

  showBrowserWarning(event: MSafeAny) {
    if (this.hasChanges()) {
      this.translate.stream(['EMPLOYEE_PORTAL.EXIT_WITH_PENDING_CHANGES.MESSAGE']).subscribe((translations) => {
        event.returnValue = translations['EMPLOYEE_PORTAL.EXIT_WITH_PENDING_CHANGES.MESSAGE'];
        return translations['EMPLOYEE_PORTAL.EXIT_WITH_PENDING_CHANGES.MESSAGE'];
      });
    }
  }

  private setIrpfData(irpfDataResponse: IrpfDataResponse) {
    if (!irpfDataResponse) {
      return;
    }
    this.irpfOriginalData = cloneDeep(irpfDataResponse);
    this.irpfFormattedData = new IrpfModel(cloneDeep(irpfDataResponse));
    this.irpfEditedData = new IrpfModel(cloneDeep(irpfDataResponse));
    this.updateIrpfViewSubject.next(this.irpfEditedData);
  }

  private updateIrpfView() {
    const irpfEditedData = cloneDeep(this.getIrpfEditedData());
    this.updateIrpfViewSubject.next(irpfEditedData as IrpfModel);
  }

  private setChangesAlert = (event: Event) => {
    if (this.hasChanges()) {
      return this.confirmExitService.getAlertText(event);
    }

    return;
  };

  private updateMaritalStatus(irpfData: IrpfDataResponse) {
    if (irpfData.maritalStatus !== MaritalStatus.MARRIED) {
      irpfData.spouseNif = null;
    }

    return irpfData;
  }
}
