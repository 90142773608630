import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { Company } from './company';
import { PayslipTypes } from '../enums/payslip-types.enum';
/* eslint-disable @typescript-eslint/naming-convention */

export class CarTransfer {
  id: string;
  company: Company;
  type: PayslipTypes;
  end_date: string;

  constructor(data: MSafeAny) {
    this.id = data.id;
    this.company = new Company(data.company);
    this.end_date = data.date;
    this.type = data.type;
  }
}
