import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { Permissions } from '@shared/enums/permissions/permissions.enum';

export enum STATUS {
  NEW = 'new',
  SOON = 'soon',
  NEW_OPTIONS = 'new_options',
  PENDING_DOC = 'pending_doc',
  DEFAULT = ''
}

export class ProfileMenuLink {
  analyticsLabel?: string;
  isExternal?: boolean;
  name!: string;
  page?: string;
  permission!: Permissions[];

  hasSoon!: boolean;
  hasPendingDoc!: boolean;
  hasNew!: boolean;
  hasNewOptions!: boolean;
  statusBadge!: string;
  statusLabelClass!: string;

  private elStatus!: string;

  constructor(data: MSafeAny) {
    const defaults = { permission: [] };
    Object.assign(this, defaults, data);
  }

  get status() {
    return this.elStatus;
  }

  set status(status: string) {
    this.elStatus = status;

    switch (this.status) {
      case STATUS.NEW:
        this.statusBadge = 'EMPLOYEE_PORTAL.NEW';
        this.hasNew = true;
        break;
      case STATUS.SOON:
        this.statusBadge = 'EMPLOYEE_PORTAL.SOON';
        this.hasSoon = true;
        break;
      case STATUS.NEW_OPTIONS:
        this.statusBadge = '';
        this.hasNewOptions = true;
        break;
      case STATUS.PENDING_DOC:
        this.statusBadge = '';
        this.hasPendingDoc = true;
        break;
      case STATUS.DEFAULT:
        this.statusBadge = '';
        this.hasPendingDoc = false;
        this.hasNew = false;
        this.hasNewOptions = false;
        break;
    }

    if (this.hasSoon) {
      this.statusLabelClass = 'item-label--disabled';
    } else {
      this.statusLabelClass = '';
    }
  }
}
