import { Component, Input, OnInit } from '@angular/core';

import { ModalManager, NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { getUrlFromMap } from '@app/shared/enums/pages/pages.urls';
import { Buttons } from '@app/shared/models/buttons/buttons';

@Component({
  selector: 'app-ptrab-phone-registration',
  templateUrl: './phone-registration.component.html',
  styleUrls: ['./phone-registration.component.scss']
})
export class PhoneRegistrationComponent implements OnInit {
  @Input() isPortugueseUser!: boolean;
  email!: string;

  buttons: Buttons[] = [];

  constructor(private modalManager: ModalManager, private navigationService: NavigationService) {}

  ngOnInit() {
    this.setButtons();
  }

  openRegisterPage() {
    const navEvent = new NavigationEvent(NavigationEvents.Push, {
      path: getUrlFromMap(PAGES.PERSONAL_DATA)
    });

    this.navigationService.navigate(navEvent);

    this.modalManager.dismissModal();
  }

  private setButtons() {
    this.buttons = [
      {
        text: this.isPortugueseUser ? 'EMPLOYEE_PORTAL.ACCEPT' : 'EMPLOYEE_PORTAL.REGISTER',
        type: 'primary',
        enabled: true,
        onClick: () => this.openRegisterPage()
      }
    ];
  }
}
