import { MSafeAny } from '@shared/models/safe-any/safe-any.model';

interface Window {
  [key: string]: MSafeAny;
}

/**
 * Environment helper class.
 * Allows to define a default environment (for local development), but it checks
 * on global configuration (via window object) that can be provided after build.
 */
export class MEnviroment<T> {
  constructor(private appName: string, private defaultEnvironment: T) {}

  private get window(): Window {
    return window as Window;
  }

  get windowConfigPath(): string {
    return `${this.appName}_environment`;
  }

  get environment(): T {
    return this.window[this.windowConfigPath] ? (this.window[this.windowConfigPath] as T) : this.defaultEnvironment;
  }
}
