import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss']
})
export class InfoPopoverComponent {
  @Input() event!: MouseEvent;
  @Input() message = 'EMPLOYEE_PORTAL.SOON_ACCESS_INFO';
  // eslint-disable-next-line
  @Output() close = new EventEmitter<MSafeAny>();

  constructor(private popoverCtrl: PopoverController) {}

  @HostListener('document:mousemove', ['$event'])
  closeInfoOnExit(event: MouseEvent) {
    const element = this.event.target as HTMLElement;
    const { bottom, left, right, top } = element.getBoundingClientRect();

    if (
      event.x >= Math.floor(left) &&
      event.x <= Math.ceil(right) &&
      event.y <= Math.floor(bottom) &&
      event.y >= Math.ceil(top)
    ) {
      return;
    }

    this.popoverCtrl.dismiss(event);
  }
}
