import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { entryComponents } from '@app/app.entry.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ApiInterceptor } from '@services/api/api.interceptor.service';
import { STORAGE_CONFIG } from '@shared/constants/storage/storage.config.const';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DragulaModule } from 'ng2-dragula';
import { CookieService } from 'ngx-cookie-service';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ENV, TELEMETRY_CONFIG } from 'src/environments/environment';

import { MLoggerModule } from '@mercadona/core/logger';
import { MPlatformEnvironment, MPlatformModule } from '@mercadona/core/platform';
import { MTelemetryModule } from '@mercadona/core/telemetry';

import { ComponentsModule } from './components/components.module';
import { RelativeModalsModule } from './ptrab/irpf/irpf-relatives/components/modals/relative-modals.module';
import { DropdownFiltersModule } from './ptrab/payslips/components/dropdown-filters/dropdown-filters.module';
import { PtrabSessionInterceptor } from './ptrab/services/session/ptrab-session-interceptor.service';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

registerLocaleData(localeEs, 'es');

export function httpLoaderFactory(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend, ['./assets/i18n/']);
}

export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [AppComponent, ...entryComponents],
  entryComponents,
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ animated: false }),
    IonicStorageModule.forRoot(STORAGE_CONFIG),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    PickerModule,
    DragulaModule.forRoot(),
    ComponentsModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownFiltersModule,
    RelativeModalsModule,
    MTelemetryModule.forRoot(TELEMETRY_CONFIG),
    MPlatformModule.forRoot({
      appName: 'activo2-front-webapp',
      environment: ENV.name as MPlatformEnvironment
    }),
    MLoggerModule.forRoot({
      logLevel: ENV.logLevel
    }),
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PtrabSessionInterceptor,
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'es' },
    DatePipe,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
