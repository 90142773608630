import { Component, Input } from '@angular/core';

import { ModalManager, NetworkService, ToastService } from '@app/services';
import { VimeoStatus } from '@app/shared/constants/multimedia/vimeo.constants';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { Example } from '@app/shared/models/multimedia/example.model';
import { VideoExample } from '@app/shared/models/multimedia/video.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-example-publish',
  templateUrl: './example-publish.component.html',
  styleUrls: ['./example-publish.component.scss']
})
export class ExamplePublishComponent {
  @Input() exampleItem!: Example;

  readonly confirmationButtons: Buttons[] = [
    {
      text: 'EXAMPLES_CREATION.DRAFT',
      type: 'secondary',
      enabled: true,
      onClick: () => this.close()
    },
    {
      text: 'EXAMPLES_CREATION.PUBLISH',
      type: 'primary',
      enabled: true,
      onClick: () => this.sendToReviewIfConnection()
    }
  ];

  constructor(
    private network: NetworkService,
    private translate: TranslateService,
    private toastService: ToastService,
    private modalManager: ModalManager
  ) {}

  close(confirmReview = false) {
    const event = { exampleItem: this.exampleItem, confirmReview };
    this.modalManager.dismissModal(event);
  }

  sendToReviewIfConnection() {
    if (this.isVideoUploadingError()) {
      this.toastService.showWarning(
        this.translate.instant('ERRORS_TOASTS.GENERIC_MSG'),
        this.translate.instant('ERRORS_TOASTS.CANNOT_SEND')
      );
      return;
    }

    this.network.doIfConnection(() => this.close(true));
  }

  isVideoUploadingError(): boolean {
    return (this.exampleItem as VideoExample).vimeo_status === VimeoStatus.UPLOADING_ERROR;
  }
}
