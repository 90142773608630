import {
  AfterViewInit,
  Attribute,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';

import { NewsPopoverService } from '@app/services/news-popover/news-popover.service';
import { User } from '@app/shared/models/user/user.model';
import { domChanges } from '@shared/utils/utils';

export type AvatarSize = 'small' | 'big';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() userInfo!: User;
  @Input() hasNews = false;
  @Input() pendingNews!: boolean;

  @Output() loadElement = new EventEmitter<HTMLElement>();

  avatar!: string;
  avatarInitials!: string;
  showNewsRing = true;

  private newsVisibilitySubscription!: Subscription;

  constructor(
    @Attribute('size') public size: AvatarSize = 'big',
    private elementRef: ElementRef,
    private newsPopoverService: NewsPopoverService
  ) {
    this.size = size;
  }

  ngOnInit() {
    this.newsVisibilitySubscription = this.newsPopoverService.visibilityChange$.subscribe(
      (isVisible) => (this.showNewsRing = isVisible)
    );
  }

  async ngAfterViewInit() {
    const delay = this.newsPopoverService.newsPopover ? 0 : 1000;
    await domChanges(delay);
    this.loadElement.emit(this.elementRef.nativeElement);
  }

  ngOnChanges() {
    this.avatar = this.userInfo.photo;
    if (!this.avatar && !this.avatarInitials) {
      this.avatarInitials = this.getInitials();
    }
  }

  getInitials() {
    const { name, lastname, userid } = this.userInfo;
    const hasNameAndLastName = Boolean(name) && Boolean(lastname);
    return hasNameAndLastName ? this.initialsByName(name, lastname) : this.initialsById(userid);
  }

  ngOnDestroy() {
    this.newsVisibilitySubscription.unsubscribe();
  }

  private initialsByName(name: string, lastname: string): string {
    return `${name.charAt(0)}${lastname.charAt(0)}`;
  }

  private initialsById(id: string): string {
    return id.substring(0, 2);
  }
}
