import { Component, Input } from '@angular/core';

import { ModalManager } from '@app/services/modal-manager/modal-manager';
import { Buttons } from '@app/shared/models/buttons/buttons';

@Component({
  selector: 'app-model-wizard-error',
  templateUrl: './model-wizard-error.component.html',
  styleUrls: ['./model-wizard-error.component.scss']
})
export class ModelWizardErrorComponent {
  @Input() darkMode!: boolean;
  buttons: Buttons[] = [
    {
      text: 'UNDER_CONSTRUCTION.BACK',
      type: 'primary',
      enabled: true,
      onClick: () => this.modalManager.dismissModal()
    }
  ];

  constructor(private modalManager: ModalManager) {}
}
