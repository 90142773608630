import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UNLIMITED_TIME } from '@app/ptrab/shared/constants/constants';
import { IRPF_ERRORS, VOLUNTARY_WITHHOLDING_ERRORS } from '@app/ptrab/shared/constants/irpf.const';
import { FeedbackTypes } from '@app/ptrab/shared/enums/feedback.enum';
import { TwoFactorError } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { ToastService } from '@app/services';
import { ErrorMessages } from '@app/services/error/error.model';
import { genericErrors } from '@app/services/handler-error/handler-error.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';
/* eslint-disable @typescript-eslint/naming-convention */

@Injectable({
  providedIn: 'root'
})
export class SessionErrorHandler {
  private readonly MESSAGES = {
    GENERIC: 'ERRORS_TOASTS.GENERIC_MSG',
    UNKNOWN: 'EMPLOYEE_PORTAL.UNKNOWN_EXTERNAL_ERROR'
  };

  private readonly codeExceptions = [
    ErrorMessages.PTRAB_UNAUTHORIZED,
    ErrorMessages.PTRAB_BLOCKED,
    ErrorMessages.INCORRECT_PASSWORD,
    ErrorMessages.RISKY_PREGNANCY,
    ErrorMessages.EMPTY_GARNISHMENT,
    ErrorMessages.TOKEN_EXPIRED,
    ErrorMessages.USER_BANNED,
    ErrorMessages.RESOURCE_LOCKED,
    ErrorMessages.EMPTY_PAYSLIPS,
    ErrorMessages.PERM_DEACTIVATED,
    ErrorMessages.INVALID_IBAN,
    ErrorMessages.EXTERNAL_CLIENT_UNAVAILABLE,
    ErrorMessages.EMPTY_SLOTS,
    ...VOLUNTARY_WITHHOLDING_ERRORS,
    TwoFactorError.VALIDATION_CODE_INVALID,
    TwoFactorError.VALIDATION_CODE_EXPIRED,
    TwoFactorError.VALIDATION_CODE_MAX_TRIES_EXCEEDED,
    TwoFactorError.VALIDATION_CODE_USER_BLOCKED,
    // TODO: Remove next 2 errors when /personal-info endpoint returns payslip errors properly
    FeedbackTypes.SERVICE_LOCKED,
    FeedbackTypes.SERVER_ERROR,
    ...Object.keys(genericErrors)
  ];

  private showToastErrors = true;

  constructor(private translateService: TranslateService, private toastService: ToastService) {}

  handleExternalError(response: HttpErrorResponse) {
    if (!response.error || !this.showToastErrors) {
      return;
    }

    const { code, detail, message } = response.error;

    if (this.isCodeException(code)) {
      return;
    }

    this.translateService
      .get([this.MESSAGES.GENERIC, this.getMessage(code), this.MESSAGES.UNKNOWN])
      .subscribe((translations) => this.showError(translations, this.getMessage(code), code, detail || message));
  }

  enableToast() {
    this.showToastErrors = true;
  }

  private isCodeException(code: string) {
    return (
      !code ||
      this.codeExceptions.find((errorCode) => code === errorCode) ||
      IRPF_ERRORS.find((errorCode) => code === errorCode)
    );
  }

  private showError(translations: string[], message: string, code: string, text: string) {
    if (translations[message] === this.getMessage(code)) {
      this.toastService.showError(translations[this.MESSAGES.GENERIC], text, UNLIMITED_TIME);
      return;
    }

    this.toastService.showError(translations[this.MESSAGES.GENERIC], translations[message]);
  }

  private getMessage(code: MSafeAny) {
    return `EMPLOYEE_PORTAL.${code}`;
  }
}
