<app-data-section
  [title]="title | translate"
  [actionText]="'EMPLOYEE_PORTAL.MODIFY' | translate"
  (actionClick)="modifySection()"
>
  <i
    *ngIf="hasError"
    class="header-left-item app-icon-alert"
    (click)="showPopover($event)"
    (mouseover)="openInfo($event)"
  ></i>
  <app-data-item *ngFor="let item of dataToload; trackBy: trackByItems" [label]="item.label" [value]="item.value"></app-data-item>
  <app-data-item
    *ngIf="!hasDataToLoad"
    label="{{ 'EMPLOYEE_PORTAL.WITHOUT_RECORDS' | translate }} {{ title | translate | lowercase }}"
  ></app-data-item>
</app-data-section>
