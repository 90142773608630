import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

export class EmployeeLanguages {
  knowledge: Knowledge[];
  tests: Tests[];

  constructor(data: MSafeAny) {
    this.knowledge = data?.knowledge;
    this.tests = data?.tests;
  }
}

export interface Knowledge {
  language: {
    id: string;
    description: string;
  };
  language_level: {
    id: string;
    short_description: string;
    long_description: string;
  };
  certificate: {
    status: {
      id: CertificateStatus;
      description: string;
    };
    date: string;
  };
  additional_info: {
    is_native: boolean;
    has_learned_abroad: boolean;
  };
}

export enum CertificateStatus {
  CURRENT = 'C',
  YES = 'S',
  NO = 'N'
}

export interface Tests {
  language: LanguageItem;
  level_test: TestLevel;
  test_date: string;
}

export class AvailableLanguagesModel implements AvailableLanguages {
  common: LanguageItem[];
  others: LanguageItem[];

  constructor(data: AvailableLanguages) {
    this.common = data.common;
    this.others = data.others;
  }
}

export interface LanguageItem {
  id: string;
  code: string;
  description: string;
}

export interface TestLevel {
  id: string;
  short_description: string;
  long_description: string;
}

export interface AvailableLanguages {
  common: LanguageItem[];
  others: LanguageItem[];
}

export class CertificateEmployeeModel implements CreateCertificateEmployee {
  language: string;
  languageLevel: string;
  certificate: {
    status: string;
    date: string;
  };
  additionalInfo: {
    isNative: boolean;
    hasLearnedAbroad: boolean;
  };

  constructor(data: CreateCertificateEmployee) {
    this.language = data.language;
    this.languageLevel = data.languageLevel;
    this.certificate = data.certificate;
    this.additionalInfo = data.additionalInfo;
  }
}

export interface CreateCertificateEmployee {
  language: string;
  languageLevel: string;
  certificate: {
    status: string;
    date: string;
  };
  additionalInfo: {
    isNative: boolean;
    hasLearnedAbroad: boolean;
  };
}
