import { Component, EventEmitter, OnChanges, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';

import { NetworkService } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics, PagesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { HEADER_CORRECTIONS } from '@app/shared/constants/header/header.constants';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { PopoverController } from '@ionic/angular';
import { PopoverOptions } from '@ionic/core';
import { NavigationHelperService } from '@services/navigation/navigation.helper.service';
import { NavigationEvent, NavigationEvents, NavigationService } from '@services/navigation/navigation.service';
import { PAGES } from '@shared/enums/pages/pages.enum';

import { MorePopoverComponent } from '../header/components/more-popover/more-popover.component';

interface Segment {
  value: string;
  text: string;
  icon: string;
  analyticsLabel: string;
  isActive?: boolean;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnChanges {
  @Output() sectionEmitter = new EventEmitter<string>();

  currentSegment!: string | null | undefined;
  segments: Segment[];
  initialSegment!: string;
  arrowPositionX!: number;

  pageAnalytics!: PagesAnalytics;
  private isMoreMenuPopover = false;
  private refPagesMore = [PAGES.DEVICE_INFO, PAGES.TERMS_AND_CONDITIONS, PAGES.COOKIES, PAGES.DATA_TREATMENT];
  active!: PAGES;

  constructor(
    private renderer: Renderer2,
    private navService: NavigationService,
    private navhelper: NavigationHelperService,
    private networkService: NetworkService,
    private analyticsService: AnalyticsService,
    private activatedRoute: ActivatedRoute,
    private popoverCtrl: PopoverController
  ) {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.pageAnalytics = data.pageAnalytics;
    });
    this.segments = this.getSegments();
  }
  ngOnInit() {
    const pageActivate = this.refPagesMore.find((page) => page === (this.navhelper.getActiveRoute() as PAGES));
    const pageIsPublications = this.navhelper.getActiveRoute() === PAGES.PUBLICATIONS;

    this.active = pageIsPublications ? PAGES.HOME : pageActivate ? PAGES.MORE : this.navhelper.getActiveRoute();
    this.currentSegment = this.active;
    this.initialSegment = this.active;
    this.checkIsActive(this.currentSegment);
  }

  ngOnChanges() {
    this.currentSegment = this.active;
  }

  openPage(segment: Segment, event) {
    this.networkService.doIfConnection(() => {
      this.checkIsActive(segment.value);
      if (segment.value === PAGES.MORE) {
        this.openMoreOptionsPopover(event);
      } else {
        const navEvent = new NavigationEvent(NavigationEvents.SetRoot, { path: segment.value });
        this.navService.navigate(navEvent);
        this.sendAnalyticsEvent(segment);
      }

      this.resetToInitialInstanceSegment();
    }, this.currentSegment as MSafeAny);

    this.sectionEmitter.emit(this.currentSegment as string);
  }

  checkIsActive(segmentSelect: string) {
    this.segments.forEach((segment) => (segment.isActive = segment.value === segmentSelect));
  }

  async openMoreOptionsPopover(event) {
    if (this.isMoreMenuPopover) {
      return;
    }

    const cssClass = 'menu-more-pop';

    const popoverOptions: PopoverOptions = {
      component: MorePopoverComponent,
      event,
      cssClass,
      showBackdrop: false
    };
    this.setXPositionArrow(event);
    this.isMoreMenuPopover = true;

    const moreMenuPopover = await this.popoverCtrl.create(popoverOptions);

    await moreMenuPopover.present();
    moreMenuPopover.onDidDismiss().then((res) => {
      if (!res.data || res.role === 'backdrop') {
        this.checkIsActive(this.initialSegment);
        this.resetToInitialInstanceSegment();
      }
    });
    this.isMoreMenuPopover = false;

    this.displayArrow(HEADER_CORRECTIONS.MORE_CORRECTION);
  }

  resetToInitialInstanceSegment() {
    this.currentSegment = this.initialSegment;
  }

  trackByItems(item: Segment) {
    return item;
  }

  private displayArrow(correction: number) {
    const popoverArrow = document.querySelector('.popover-arrow');
    if (popoverArrow) {
      popoverArrow.id = 'arrow';
    }

    const arrow = document.getElementById('arrow');
    if (arrow) {
      this.renderer.setStyle(arrow, 'left', `${this.arrowPositionX + correction}px`);
      this.renderer.setStyle(arrow, 'visibility', 'visible');
    }
  }

  private setXPositionArrow(event) {
    const target: HTMLElement = event.currentTarget;
    const targetClient = target.getBoundingClientRect();
    this.arrowPositionX = targetClient.left;
  }

  private getSegments(): Segment[] {
    return [
      {
        value: PAGES.HOME,
        icon: 'app-icon-home',
        text: 'HOME',
        analyticsLabel: CategoriesAnalytics.HOME,
        isActive: false
      },
      {
        value: PAGES.MODEL,
        icon: 'app-icon-content-model',
        text: 'MODEL',
        analyticsLabel: PagesAnalytics.MODEL,
        isActive: false
      },
      {
        value: PAGES.EXAMPLES,
        icon: 'app-icon-shared-ideas',
        text: 'EXAMPLES',
        analyticsLabel: PagesAnalytics.EXAMPLES,
        isActive: false
      },
      {
        value: PAGES.MORE,
        icon: 'app-icon-more',
        text: 'MORE',
        analyticsLabel: ActionsAnalytics.MORE,
        isActive: false
      }
    ];
  }

  private sendAnalyticsEvent(segment: Segment) {
    this.analyticsService.sendEvent(CategoriesAnalytics.MENU_INFERIOR, {
      [ActionsAnalytics.CLICKACTION]: segment.analyticsLabel
    });
  }
}
