/* eslint-disable @typescript-eslint/naming-convention */
export const URL_REGEX =
  // eslint-disable-next-line
  /(((http(s)?:\/\/)([a-zA-Z0-9-ñ]{1,63}\.){1,4}[a-zA-Z]{2,7})|(([a-zA-Z0-9-ñ]{1,63}\.){2,4}[a-zA-Z]{2,7}))(:\d{1,4})?((\/[?a-zA-Z0-9@\-ñ:%$_+~#=&\/\[\]]{1,256})?([.][a-zA-Z]{1,5})?(#|\?[\/?a-zA-Z0-9@\-ñ:%$_+~#=&\[\]]*)?)/gm; // NOSONAR

const PAYSLIP_ITEM_TYPES = [
  {
    id: 'RECTIFIED',
    text: 'EMPLOYEE_PORTAL.CAR_TRASFER_TYPE.RECTIFIED'
  },
  {
    id: 'CANCELLED',
    text: 'EMPLOYEE_PORTAL.CAR_TRASFER_TYPE.CANCELLED'
  },
  {
    id: 'BONUS',
    text: 'EMPLOYEE_PORTAL.BONUS'
  }
];

const BONUS_ID = 'BONUS';

enum FILTER_TYPES {
  LAST = 'last',
  MONTHS = 'filter-months',
  CUSTOM_RANGE = 'custom-range'
}

const MERCADONA_SYSTEM_ID = 'ACTIVO2';
const MERCADONA_SYSTEM_DESC = 'Activo2';
const MERCADONA_SYSTEM_URL = 'https://activo2.mercadona.com';
const MERCADONA_SYSTEM_PARAMS =
  `?idSistema=${MERCADONA_SYSTEM_ID}` + `&descSistema=${MERCADONA_SYSTEM_DESC}` + `&urlSistema=${MERCADONA_SYSTEM_URL}`;

const UNLIMITED_TIME = 0;

export { PAYSLIP_ITEM_TYPES, BONUS_ID, FILTER_TYPES, MERCADONA_SYSTEM_PARAMS, UNLIMITED_TIME };
