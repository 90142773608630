import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, Subject, tap, throwError } from 'rxjs';

import { ErrorMsgEnum } from '@app/components/error-msg/error-msg-enum';
import { IEnjoyedYear } from '@app/ptrab/shared/interfaces/enjoyed-year.interface';
import {
  IrsDataResponse,
  IrsDependent,
  IrsPutRoomBenefit,
  IrsSpouse,
  IrsWithholding,
  IrsWithholdingResponse,
  IrsWorkerPersonalData,
  IrsYoungBenefit
} from '@app/ptrab/shared/interfaces/irs-data-response.interface';
import {
  TwoFactorAuthorization,
  TwoFactorOperationCode
} from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { CivilStatus, CivilStatusResponse } from '@app/ptrab/shared/models/civil-status';
import { EnjoyedYear } from '@app/ptrab/shared/models/enjoyed-year';
import {
  Dependant,
  IrsModel,
  IrsWithholdingModel,
  Spouse,
  WorkerPt,
  YoungBenefit
} from '@app/ptrab/shared/models/irs.model';
import { Logger } from '@app/services';
import { ErrorCodes, ErrorMsg } from '@app/services/error/error.model';
import { IDataResponse } from '@app/shared/interfaces/data/data.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { isNullOrUndefined } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

import { ConfirmExitService } from '../confirm-exit/confirm-exit.service';
import { TwoFactorController } from '../two-factor/two-factor.controller';

@Injectable({
  providedIn: 'root'
})
export class IrsService {
  irsFormattedData!: IrsModel | null;

  private irsEditedData!: IrsModel | null;
  private irsOriginalData!: IrsDataResponse | null;
  private logger = new Logger('IrsService');
  private onError = new Subject<ErrorMsgEnum>();

  errorMsg$ = new Subject<ErrorMsg>();
  onError$ = this.onError.asObservable();

  private updateIrsViewSubject = new Subject<IrsModel>();
  onUpdateIrsView$ = this.updateIrsViewSubject.asObservable();

  private irsWithholdingSubject = new Subject<IrsWithholdingModel>();
  onWithholdingUpdate$ = this.irsWithholdingSubject.asObservable();

  constructor(
    private confirmExitService: ConfirmExitService,
    private translate: TranslateService,
    private twoFactorController: TwoFactorController
  ) {
    this.addWindowListener();
  }

  getIrsEditedData(): IrsModel | null {
    return this.irsEditedData;
  }

  getError() {
    return this.onError;
  }

  async checkTwoFactor(operationCode: TwoFactorOperationCode): Promise<TwoFactorAuthorization> {
    const validationHash = await this.twoFactorController.validateOperation(operationCode, true);

    const twoFactorAuthorization = {
      validation_hash: validationHash
    };

    return twoFactorAuthorization;
  }

  mapIrsModel(data: Observable<IDataResponse<IrsDataResponse>>, modifyData = false): Observable<IrsModel> {
    return data.pipe(
      map((d) => d.data),
      tap((irsDataResponse: IrsDataResponse) => this.setIrsData(irsDataResponse)),
      map(() => this.mapIrsData()),
      catchError((error: HttpErrorResponse) => {
        const errorType =
          error && error.status === ErrorCodes.LOCKED ? ErrorMsgEnum.SERVICE_LOCKED : ErrorMsgEnum.SERVER_ERROR;

        if (!modifyData) {
          this.onError.next(errorType);
        }

        this.errorMsg$.next({ code: error.error.code, detail: error.error.detail, message: error.error.message });

        this.logger.error(error);

        return throwError(() => error);
      })
    );
  }

  mapCivilStatusModel(data: Observable<IDataResponse<CivilStatusResponse[]>>): Observable<CivilStatus[]> {
    return data.pipe(
      map((rawData) => rawData.data.map((rawItem) => new CivilStatus(rawItem))),
      catchError((error: HttpErrorResponse) => {
        const errorType =
          error && error.status === ErrorCodes.LOCKED ? ErrorMsgEnum.SERVICE_LOCKED : ErrorMsgEnum.SERVER_ERROR;
        this.onError.next(errorType);

        return of([] as MSafeAny);
      })
    );
  }

  mapEnjoyedYearsModel(data: Observable<IDataResponse<IEnjoyedYear[]>>): Observable<EnjoyedYear[]> {
    return data.pipe(
      map((rawData) => rawData.data.map((rawItem) => new EnjoyedYear(rawItem))),
      catchError((error: HttpErrorResponse) => {
        const errorType =
          error && error.status === ErrorCodes.LOCKED ? ErrorMsgEnum.SERVICE_LOCKED : ErrorMsgEnum.SERVER_ERROR;
        this.onError.next(errorType);

        return of([] as MSafeAny);
      })
    );
  }

  mapWorkerData(): IrsWorkerPersonalData {
    const updatedData: IrsWorkerPersonalData = cloneDeep(this.irsOriginalData!.worker);

    updatedData.civil_status = this.irsEditedData!.worker.civilStatus!.id;
    updatedData.disability_type = Number(this.irsEditedData!.worker.disability);
    updatedData.disability_percentage = Number(this.irsEditedData!.worker.percentage);
    updatedData.income_titularity = this.irsEditedData!.worker.incomeTitularity;

    delete updatedData.civil_status_name;
    delete updatedData.fiscal_status;

    return updatedData;
  }

  mapSpouseData(): IrsSpouse {
    const updatedData: IrsSpouse = cloneDeep(this.irsOriginalData!.spouse);

    updatedData.disability_type = Number(this.irsEditedData!.spouse.disability);
    updatedData.disability_percentage = Number(this.irsEditedData!.spouse.percentage);

    return updatedData;
  }

  mapDependentData(dependantId: number): IrsDependent {
    if (isNullOrUndefined(dependantId)) {
      dependantId = this.irsEditedData!.dependants.length - 1;
    }

    const editedDependant = this.irsEditedData!.dependants[dependantId];

    const dependant: IrsDependent = {
      id: editedDependant.id!,
      birth_date: editedDependant.birthdate,
      disability_type: Number(editedDependant.disability),
      disability_percentage: Number(editedDependant.percentage)
    };

    return dependant;
  }

  mapYoungBenefitData(): IrsYoungBenefit {
    const updatedData: IrsYoungBenefit = cloneDeep(this.irsOriginalData!.irs_jovem);

    updatedData.benefit_years = this.irsEditedData!.youngBenefit.years;
    updatedData.finished_studies_date = this.irsEditedData!.youngBenefit.date;
    updatedData.phd = this.irsEditedData!.youngBenefit.phd;
    updatedData.start_year = this.irsEditedData!.youngBenefit.startYear;

    return updatedData;
  }

  mapRoomBenefitData(): IrsPutRoomBenefit {
    const updatedData: IrsPutRoomBenefit = {
      housing_credit: this.irsOriginalData!.housing_credit
    };

    updatedData.housing_credit = this.irsEditedData!.roomBenefit;

    return updatedData;
  }

  setWorkerInfo(value: WorkerPt) {
    if (this.irsEditedData) {
      this.irsEditedData.worker = value;
    }
  }

  setSpouseInfo(value: Spouse) {
    if (this.irsEditedData) {
      this.irsEditedData.spouse = value;
    }
  }

  setDependantInfo(value: Dependant, index?: number) {
    if (this.irsEditedData) {
      if (isNullOrUndefined(index)) {
        value.id = null;
        this.irsEditedData.dependants.push(value);
      } else {
        value.id = this.irsOriginalData!.dependents[index].id;
        this.irsEditedData.dependants[index] = { ...value };
      }
    }
  }

  setRoomBenefitInfo(value: boolean) {
    if (this.irsEditedData) {
      this.irsEditedData.roomBenefit = value;
    }
  }

  setYoungBenefit(value: YoungBenefit) {
    if (this.irsEditedData) {
      this.irsEditedData.youngBenefit = value;
    }
  }

  hasChanges(): boolean {
    if (this.irsFormattedData && this.irsEditedData) {
      return !isEqual(this.irsFormattedData, this.irsEditedData);
    }
    return false;
  }

  clearData() {
    this.irsOriginalData = null;
    this.irsEditedData = null;
    this.irsFormattedData = null;
    this.updateIrsView();
  }

  dismissChanges() {
    this.irsEditedData = cloneDeep(this.irsFormattedData);
    this.updateIrsView();
  }

  addWindowListener() {
    this.confirmExitService.setCloseWindowListener(this.setChangesAlert);
  }

  removeWindowListener() {
    this.confirmExitService.removeCloseWindowListener(this.setChangesAlert);
  }

  async confirmChangeDismiss(hasChanges: boolean, browserAlert?: (event: MSafeAny) => void, isLeavingIrs = true) {
    const canLeave = await this.confirmExitService.confirmChangeDismiss(hasChanges, browserAlert);
    if (canLeave) {
      this.removeWindowListener();

      if (isLeavingIrs) {
        this.dismissChanges();
      }
    } else {
      this.addWindowListener();
    }
    return canLeave;
  }

  showBrowserWarning(event: MSafeAny) {
    if (this.hasChanges()) {
      this.translate.stream(['EMPLOYEE_PORTAL.EXIT_WITH_PENDING_CHANGES.MESSAGE']).subscribe((translations) => {
        event.returnValue = translations['EMPLOYEE_PORTAL.EXIT_WITH_PENDING_CHANGES.MESSAGE'];
        return translations['EMPLOYEE_PORTAL.EXIT_WITH_PENDING_CHANGES.MESSAGE'];
      });
    }
  }

  setIrsData(irsDataResponse: IrsDataResponse) {
    if (!irsDataResponse) {
      return;
    }

    this.irsOriginalData = cloneDeep(irsDataResponse);
    this.irsFormattedData = new IrsModel(cloneDeep(irsDataResponse));
    this.irsEditedData = new IrsModel(cloneDeep(irsDataResponse));
    this.updateIrsViewSubject.next(this.irsEditedData);
  }

  mapIrsData() {
    return this.irsEditedData as IrsModel;
  }

  mapIrsWithholdingModel(
    data: Observable<IDataResponse<IrsWithholdingResponse>>,
    modifyData = false
  ): Observable<IrsWithholdingModel> {
    return data.pipe(
      map((d) => new IrsWithholdingModel(d.data)),
      tap((irsWithholding) => this.setIrsWithholding(irsWithholding)),
      catchError((error: HttpErrorResponse) => {
        const errorType =
          error && error.status === ErrorCodes.LOCKED ? ErrorMsgEnum.SERVICE_LOCKED : ErrorMsgEnum.SERVER_ERROR;
        if (!modifyData || errorType === ErrorMsgEnum.SERVICE_LOCKED) {
          this.onError.next(errorType);
        }
        this.logger.error(error);

        return throwError(() => error);
      })
    );
  }

  setIrsWithholding(irsWithholding: IrsWithholdingModel) {
    this.irsWithholdingSubject.next(cloneDeep(irsWithholding));
  }

  mapIrsVoluntaryWithholding(voluntaryWithholding: number): IrsWithholding {
    return { voluntary_retention: voluntaryWithholding } as IrsWithholding;
  }

  updateIrsView() {
    const irsEditedData = cloneDeep(this.getIrsEditedData());
    this.updateIrsViewSubject.next(irsEditedData as IrsModel);
  }

  private setChangesAlert = (event: Event) => {
    if (this.hasChanges()) {
      return this.confirmExitService.getAlertText(event);
    }

    return;
  };
}
