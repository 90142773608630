import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MOT_PERMISSIONS } from '@app/mot/mot-permissions.const';
import { PTRAB_PERMISSIONS } from '@app/ptrab/ptrab-permissions.const';
import { PtrabSessionManager } from '@app/ptrab/services/session/ptrab-session.manager.service';
import { Logger, NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { UserService } from '@app/services/user/user.service';
import { NewsTypes } from '@app/shared/enums/news/news-types.enum';
import { PAGES, PTRAB_PAGES } from '@app/shared/enums/pages/pages.enum';
import { getPtrabUrlFromMap } from '@app/shared/enums/pages/pages.urls';
import { Permissions } from '@app/shared/enums/permissions/permissions.enum';
import { RouteHelper } from '@app/shared/helpers/route.helper';
import { ProfileMenuLink, STATUS } from '@app/shared/models/profile-menu/profile-menu-link.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { User } from '@app/shared/models/user/user.model';
import { PopoverController } from '@ionic/angular';

import { PROFILE_MENU_GROUPS } from './profile-menu.constants';
import { ENV } from '../../../environments/environment';
import { InfoPopoverComponent } from '../info-popover/info-popover.component';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnDestroy {
  logger = new Logger('Profile page');
  profileInfo!: User;
  // eslint-disable-next-line
  infoPopover!: HTMLIonPopoverElement;

  private myProfileLink: ProfileMenuLink[] = PROFILE_MENU_GROUPS.PTRAB_ACCESS_LIST.slice(3);
  private ptrabLinks: ProfileMenuLink[] = PROFILE_MENU_GROUPS.PTRAB_ACCESS_LIST.slice(0, 3);
  private motLinks: ProfileMenuLink[] = PROFILE_MENU_GROUPS.MOT_LIST.slice();
  private logisticCalendar: ProfileMenuLink[] = PROFILE_MENU_GROUPS.LOGISTIC_ANUAL_CALENDAR;

  personalSections!: ProfileMenuLink[][];

  private userSubscription!: Subscription;

  constructor(
    private analyticsService: AnalyticsService,
    private popoverCtrl: PopoverController,
    private userService: UserService,
    private navService: NavigationService,
    private ptrabSessionManager: PtrabSessionManager
  ) {
    this.subscribeToUserChanges();
    this.setPersonalSections();
  }

  logout() {
    this.sendAnalyticsEvent(ActionsAnalytics.LOGOUT);
    this.close();
    const navEvent = new NavigationEvent(NavigationEvents.SetRoot, { path: PAGES.LOGOUT });
    this.navService.navigate(navEvent);
  }

  goToPage(item: ProfileMenuLink) {
    this.sendAnalyticsEvent(item.analyticsLabel as string);
    if (item.page) {
      this.close();

      if (item.isExternal) {
        this.openExternalPage(item);
        return;
      }

      if (RouteHelper.isPtrabPage(item.page)) {
        this.ptrabSessionManager.setPageToRedirectAfterLogin(item.page);
      }

      const navEvent = new NavigationEvent(NavigationEvents.Push, { path: item.page });
      this.navService.navigate(navEvent);
    }
  }

  openExternalPage(item: ProfileMenuLink) {
    window.open(item.page, '_blank');
  }

  async showPopover(event: Event) {
    this.infoPopover = await this.popoverCtrl.create({
      component: InfoPopoverComponent,
      componentProps: { event },
      event,
      backdropDismiss: true,
      showBackdrop: false,
      cssClass: 'info-popover'
    });

    this.infoPopover.present();
  }

  close() {
    this.popoverCtrl.dismiss();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  private subscribeToUserChanges() {
    this.userSubscription = this.userService.userInfoChanges$.subscribe((user: User) => {
      this.profileInfo = { ...user };
    });
  }

  private sendAnalyticsEvent(eventLabel: string) {
    this.analyticsService.sendEvent(CategoriesAnalytics.USER_MENU, {
      [ActionsAnalytics.CLICKACTION]: eventLabel
    });
  }

  updateLinks(links: ProfileMenuLink[], allowedPermissions: string[]): ProfileMenuLink[] {
    return links.filter((link) => {
      if (link.permission.length === 0) {
        return true;
      }

      return link.permission.some((permission) => allowedPermissions.includes(permission));
    });
  }

  trackByItems(item: MSafeAny) {
    return item;
  }

  private async setPersonalSections() {
    let userPermissions = await this.userService.getPermissions();

    if (ENV.fullAccessPtrab) {
      userPermissions = [...new Set([...userPermissions, ...PTRAB_PERMISSIONS, ...MOT_PERMISSIONS])];
    }

    this.myProfileLink = this.updateLinks(this.myProfileLink, userPermissions);
    this.ptrabLinks = this.updateLinks(this.ptrabLinks, userPermissions);
    this.motLinks = this.updateLinks(this.motLinks, userPermissions);
    this.logisticCalendar = this.updateLinks(this.logisticCalendar, userPermissions);

    const newsItem = this.profileInfo?.news?.find(
      (item) => item.type === NewsTypes.NEWS_PTRAB_FEATURE && !item.accepted
    );

    this.ptrabLinks
      .filter((link) => link.page === getPtrabUrlFromMap(PTRAB_PAGES.PAYSLIPS))
      .forEach((l) => (l.status = newsItem ? STATUS.NEW_OPTIONS : STATUS.DEFAULT));

    const documentsNewsItem = this.profileInfo?.news?.find(
      (item) => item.type === NewsTypes.NEWS_DOCUMENTS && !item.accepted
    );

    this.ptrabLinks
      .filter((link) => link.page === getPtrabUrlFromMap(PTRAB_PAGES.CERTIFICATES))
      .forEach((l) => (l.status = documentsNewsItem ? STATUS.PENDING_DOC : STATUS.DEFAULT));

    this.personalSections = [this.myProfileLink];

    if (userPermissions.find((p) => p === Permissions.ORGANIZA2_CALENDAR)) {
      this.personalSections.push(this.logisticCalendar);
    }

    if (this.motLinks.length > 0) {
      this.personalSections.push(this.motLinks);
    }

    if (this.ptrabLinks.length > 0) {
      this.personalSections.push(this.ptrabLinks);
    }
  }
}
