import { Observable } from 'rxjs';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';
import { DateDifference, RelativeDate } from '@app/shared/helpers/date-difference/date-difference.helper';
import { Buttons } from '@shared/models/buttons/buttons';
import {
  ConfirmationModalComponent,
  IConfirmationModalOptions
} from '@components/modals/confirmation-modal/confirmation-modal.component';
import { ModalManager } from '@app/services';
/* eslint-disable @typescript-eslint/naming-convention */

export class CommentHelper {
  static convertDateToRelative(commentCreationDate: string, translateService: TranslateService): Observable<MSafeAny> {
    const currentDate = new Date();
    const relativeDate: RelativeDate = DateDifference.timeDifference(commentCreationDate, currentDate);
    return translateService.get(`COMMENTS.${relativeDate.timeStamp}`, { timePast: relativeDate.timePast });
  }

  static confirmDeleteComment(modalManager: ModalManager) {
    const title = 'COMMENTS.URL_NOT_PERMITED_TITLE';
    const contents = ['COMMENTS.URL_NOT_PERMITED_DESCRIPTION'];

    const buttons: Buttons[] = [
      {
        text: 'COMMENTS.ACCEPT',
        type: 'primary',
        enabled: true,
        onClick: () => modalManager.dismissModal()
      }
    ];

    const modalOpts: IConfirmationModalOptions = {
      component: ConfirmationModalComponent,
      componentProps: { title, contents, buttons, hasFixedHeight: true },
      backdropDismiss: false,
      cssClass: 'media-content-modal confirmation-modal'
    };

    modalManager.openModal(modalOpts);
  }
}
