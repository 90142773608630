import { Component, Input, OnInit } from '@angular/core';

import { getLastDayOfMonth } from '@app/shared/utils/utils';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-ptrab-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss']
})
export class MonthPickerComponent implements OnInit {
  @Input() minYear!: number;
  @Input() startDate!: Date;
  @Input() endDate!: Date;
  @Input() limitDate!: Date;
  @Input() startDateYear!: number;
  @Input() endDateYear!: number;

  readonly monthNames: string[] = [
    'EMPLOYEE_PORTAL.MONTHS.JANUARY',
    'EMPLOYEE_PORTAL.MONTHS.FEBRUARY',
    'EMPLOYEE_PORTAL.MONTHS.MARCH',
    'EMPLOYEE_PORTAL.MONTHS.APRIL',
    'EMPLOYEE_PORTAL.MONTHS.MAY',
    'EMPLOYEE_PORTAL.MONTHS.JUNE',
    'EMPLOYEE_PORTAL.MONTHS.JULY',
    'EMPLOYEE_PORTAL.MONTHS.AUGUST',
    'EMPLOYEE_PORTAL.MONTHS.SEPTEMBER',
    'EMPLOYEE_PORTAL.MONTHS.OCTOBER',
    'EMPLOYEE_PORTAL.MONTHS.NOVEMBER',
    'EMPLOYEE_PORTAL.MONTHS.DECEMBER'
  ];
  maxYear!: number;
  selectedYear!: number;
  lastDayOfMonth!: Date;

  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit() {
    this.setYears();
  }

  goNextYear() {
    if (this.selectedYear < this.maxYear) {
      this.selectedYear = this.selectedYear + 1;
    }
  }

  goPreviousYear() {
    if (this.minYear < this.selectedYear) {
      this.selectedYear = this.selectedYear - 1;
    }
  }

  selectMonth(index: number) {
    const monthDay = this.endDate ? 1 : getLastDayOfMonth(this.selectedYear, index);
    const date = new Date(this.selectedYear, index, monthDay);
    this.popoverCtrl.dismiss(date);
  }

  setYears() {
    this.maxYear = this.limitDate.getFullYear();
    this.setYearsByParams();
  }

  setYearsByParams() {
    if (this.startDate) {
      this.minYear = this.startDate.getFullYear();
      this.selectedYear = this.endDateYear || this.limitDate.getFullYear();
    }

    if (this.endDate) {
      this.maxYear = this.endDate.getFullYear();
      this.selectedYear = this.startDateYear || this.maxYear;
    }
  }

  isDisabled(monthIndex: number): boolean {
    return this.futureMonths(monthIndex) || this.pastMonths(monthIndex) || this.invalidStartMonth(monthIndex);
  }

  invalidStartMonth(monthIndex: number): boolean {
    if (!this.endDate) {
      return false;
    }

    const isCurrentYear = this.selectedYear === this.endDate.getFullYear();
    const isAllowedMonth = this.endDate.getMonth() < monthIndex;
    return isCurrentYear && isAllowedMonth;
  }

  pastMonths(monthIndex: number): boolean {
    if (!this.startDate) {
      return false;
    }
    const isCurrentYear = this.selectedYear === this.startDate.getFullYear();
    const isPastMonth = this.startDate.getMonth() > monthIndex;

    return isCurrentYear && isPastMonth;
  }

  futureMonths(monthIndex: number): boolean {
    const monthIncoming = new Date();
    monthIncoming.setFullYear(this.selectedYear);
    monthIncoming.setMonth(monthIndex);

    const isCurrentYear = this.selectedYear === this.limitDate.getFullYear();
    const isFutureMonth = monthIncoming.getMonth() > this.limitDate.getMonth();

    return isCurrentYear && isFutureMonth;
  }

  trackByItems(item: string) {
    return item;
  }
}
