import { Permissions } from '@app/shared/enums/permissions/permissions.enum';
import { NavTab } from '@app/shared/interfaces/nav-tab/nav-tab.interface';

export enum PersonalDataTabs {
  PROFILE = 'PROFILE',
  LANGUAGES = 'LANGUAGES'
}

export const PERSONAL_DATA_NAV_TABS: NavTab[] = [
  {
    tabID: PersonalDataTabs.PROFILE,
    label: 'PROFILE.PERSONAL_DATA.PROFILE.TITLE',
    tabIcon: '',
    permissions: [Permissions.PERSONAL_DATA]
  },
  {
    tabID: PersonalDataTabs.LANGUAGES,
    label: 'PROFILE.PERSONAL_DATA.LANGUAGES.LANGUAGES',
    tabIcon: '',
    permissions: [Permissions.LANGUAGES, Permissions.STUDIES]
  }
];

export enum PersonalDataCvSections {
  LANGUAGES = 'LANGUAGES',
  STUDIES = 'STUDIES'
}
