import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-badge-info',
  templateUrl: './badge-info.component.html',
  styleUrls: ['./badge-info.component.scss']
})
export class BadgeInfoComponent {
  @Input() title!: string;

  constructor() {}
}
