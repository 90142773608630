<app-input-wrapper [label]="'EMPLOYEE_PORTAL.FROM'">
  <div class="wrapper-content">
    <div class="c-input" (click)="openDatePickerPopover($event, DateRanges.START)" data-e2e-id="from-selector">
      {{ startDateMonth | translate }} {{ startDateYear }}
    </div>
    <img
      (click)="openDatePickerPopover($event, DateRanges.START)"
      class="calendar-icon"
      src="assets/imgs/employee-portal/today.svg"
      alt="Calendar" />
  </div>
</app-input-wrapper>

<app-input-wrapper [label]="'EMPLOYEE_PORTAL.UNTIL'">
  <div class="wrapper-content">
    <div class="c-input" (click)="openDatePickerPopover($event, DateRanges.END)" data-e2e-id="until-selector">
      {{ endDateMonth | translate }} {{ endDateYear }}
    </div>
    <img
      (click)="openDatePickerPopover($event, DateRanges.END)"
      class="calendar-icon"
      src="assets/imgs/employee-portal/today.svg"
      alt="Calendar" />
  </div>
</app-input-wrapper>
