import { AfterViewInit, Directive, ElementRef, ViewChild } from '@angular/core';

import { Logger } from '@app/services/logger/logger.service';
import { domChanges } from '@app/shared/utils/utils';
import { IonRadioGroup } from '@ionic/angular';

@Directive()
export class RadiosDirective implements AfterViewInit {
  @ViewChild(IonRadioGroup, { read: ElementRef }) radioGroup!: ElementRef;
  protected readonly radiosLogger: Logger = new Logger('RadiosDirective');

  async ngAfterViewInit() {
    await domChanges(1200);
    this.set();
  }

  private async set() {
    try {
      const radioItems = this.getRadioItems();

      if (!radioItems) {
        return;
      }

      radioItems.forEach((radioItem: Node) => {
        const element = radioItem as HTMLElement;
        this.setRadioIconStyles(element);
        this.setRadioInnerStyles(element);
      });
    } catch (err) {
      this.radiosLogger.error(err);
    }
  }

  private getRadioItems(): NodeList {
    return this.radioGroup && this.radioGroup.nativeElement.querySelectorAll('input[type="radio"]');
  }

  private setRadioIconStyles(radioItem: HTMLElement) {
    const radioIcon: HTMLElement = radioItem?.shadowRoot?.querySelector('.radio-icon') as HTMLElement;
    radioIcon.style.width = '25px';
    radioIcon.style.height = '25px';
    radioIcon.style.borderWidth = '1px';
    radioIcon.style.borderColor = '#e9e9e9';
  }

  private setRadioInnerStyles(radioItem: HTMLElement) {
    const radioInner: HTMLElement = radioItem?.shadowRoot?.querySelector('.radio-inner') as HTMLElement;
    radioInner.style.width = '17px';
    radioInner.style.height = '17px';
  }
}
